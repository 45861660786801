import { Anchor, Box, Grommet } from "grommet";
import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import CustomGrommetTheme from "./grommet-theme";
import AppHeader from "./components/AppHeader";
import DetailScreen from "./screens/DetailScreen";
import OverviewScreen from "./screens/OverviewScreen";
import SearchScreen from "./screens/SearchScreen";
import { Screen } from "./models";
import { useRiskParams } from "./hooks";
import GoogleAnalytics from "./components/GoogleAnalytics";
import AppTopMenu from "./components/AppTopMenu";

const ScreenComponent = (props: any) => {
  const riskParams = useRiskParams();
  const screen = props.match.params.screen;
  return screen === Screen.Overview ? (
    <OverviewScreen {...riskParams} />
  ) : Object.values(Screen).includes(screen) ? (
    <DetailScreen {...riskParams} />
  ) : (
    <Redirect to="/" />
  );
};

const SkipLinkContainer = styled(Box)`
  &:not(:has(a:focus-visible)) {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

const SkipLink = () => {
  // Clicking the skip link scrolls to the #main-content element. Doing that,
  // we need to account for the height of the #app-header, which sticks to the
  // top of the window. The CSS attribute scroll-margin-top allows to do that.
  // However, since the height of the #app-header is dynamic, scroll-margin-top
  // is set to a css variable --scroll-offset. When the user clicks the skip link,
  // this variable is updated to match the current height of the #app-header.
  const setScrollOffset = (event: React.MouseEvent) => {
    const appHeader = document.querySelector("#app-header");
    const mainContent = document.querySelector("#main-content");
    if (appHeader && mainContent instanceof HTMLElement) {
      const appHeaderHeight = appHeader.getBoundingClientRect().height;
      mainContent.style.setProperty("--scroll-offset", `${appHeaderHeight}px`);
    }
  };
  return (
    <SkipLinkContainer
      background="highlight-200"
      pad={{
        vertical: "small",
        horizontal: "medium"
      }}
      align="start"
      id="skip-link-banner"
    >
      <Anchor href="#main-content" onClick={setScrollOffset} size="medium" color="highlight-800">
        Skip to main content
      </Anchor>
    </SkipLinkContainer>
  );
};

const Header = () => (
  <>
    <AppTopMenu />
    <AppHeader />
  </>
);

const App = () => (
  <Grommet plain={true} theme={CustomGrommetTheme} options={{ box: { cssGap: true } }}>
    <Router basename={process.env.PUBLIC_URL}>
      {process.env.NODE_ENV === "development" ? "" : <GoogleAnalytics />}
      <SkipLink />
      <Route
        component={Header}
        exact={true}
        path={[
          "/:screen/:stateId/:countyIds/:communityId/",
          "/:screen/:stateId/:countyIds/",
          "/:screen/:stateId/",
          "/search",
          "/"
        ]}
      />
      <Switch>
        <Route path={["/", "/search"]} exact={true} component={SearchScreen} />
        <Route
          path={[
            "/:screen/:stateId/:countyIds/:communityId/",
            "/:screen/:stateId/:countyIds/",
            "/:screen/:stateId/"
          ]}
          exact={true}
          component={ScreenComponent}
        />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  </Grommet>
);
export default App;
