import mapboxgl from "mapbox-gl";

import { GeographyLevel } from "../models";
import { OVERLAY_IDS, PLACE_LABEL_LAYERS } from "./mapLayers";

export const addPlacesLabelLayers = (map: mapboxgl.Map) => {
  Object.values(PLACE_LABEL_LAYERS).forEach(layer => {
    map.getSource(layer.mapboxTilesetId) ||
      map.addSource(layer.mapboxTilesetId, {
        type: "vector",
        url: `mapbox://${layer.mapboxTilesetId}`
      });
    map.getLayer(layer.layerId) ||
      map.addLayer({
        id: layer.layerId,
        source: layer.mapboxTilesetId,
        "source-layer": layer.sourceLayer,
        type: "symbol",
        ...layer.styles
      } as mapboxgl.SymbolLayer);
  });
};

export const addSelectedPlaceLabelLayer = (
  map: mapboxgl.Map,
  geographyLevel: GeographyLevel,
  geographyName: string,
  detailPlaceId: string
) => {
  const selectedPlaceLabelLayerConfig = PLACE_LABEL_LAYERS[geographyLevel];
  // In order to have all community labels in one layer but still allow to show/hide
  // different size communities at different zoom levels, the communities layer
  // shows/hides places by place size (scalerank) using the text-field attribute.
  // We want to show the selected place at all zoom levels, regardless of size. Hence,
  // this overwrites the text-field settings inherited from the labels layer.
  // We need to avoid mutating the label layer style, though, since that would affect
  // the regular communities label layer, so this uses the spread operator to make
  // new copies of the objects that need to change, rather than writing to the original.
  const selectedPlaceLabelStyles = { ...selectedPlaceLabelLayerConfig.styles };
  const selectedLayout = selectedPlaceLabelStyles.layout && {
    ...selectedPlaceLabelStyles.layout,
    "text-field":
      geographyLevel === "communities"
        ? ["to-string", ["get", "name"]]
        : selectedPlaceLabelStyles.layout["text-field"]
  };
  // The state layer doesn't have the ID property, so we have to filter by name for that one.
  // States and tribal areas have an "id" attribute, but we need to adjust our ID to account
  // for the lack of leading zeroes.
  // The county layer has the FIPS code as its object ID, but matching that requires a different
  // lookup, so it's simpler to just match by name, since we have it.
  const layerId = OVERLAY_IDS.selectedLabelLayerId;
  const selectedPlaceFilterExpr =
    geographyLevel === GeographyLevel.state || geographyLevel === GeographyLevel.county
      ? ["match", ["get", "name"], [geographyName], true, false]
      : ["match", ["get", "id"], [String(parseInt(detailPlaceId, 10))], true, false];
  map.getLayer(layerId) && map.removeLayer(layerId);
  map.addLayer({
    id: layerId,
    source: selectedPlaceLabelLayerConfig.mapboxTilesetId,
    "source-layer": selectedPlaceLabelLayerConfig.sourceLayer,
    type: "symbol",
    ...selectedPlaceLabelStyles,
    layout: selectedLayout,
    minzoom: 0,
    maxzoom: 13,
    filter: selectedPlaceFilterExpr
  } as mapboxgl.SymbolLayer);
};
