import mapboxgl from "mapbox-gl";
import { createRoot } from "react-dom/client";
import { defaultLineOptions } from "./mapStyles";
import {
  VulnerablePopulationTractDictionary,
  NormalizedTractId,
  VulnerablePopulationTractInfo
} from "../models";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { BOUNDARY_LAYERS, OVERLAY_IDS, boundaryLayerInsertBeforeSecondary } from "./mapLayers";
import { clearTractHighlighting } from "./mapListeners";
import VulnerablePopulationsPopUp from "./VulnerablePopulationsPopUp";

export interface FuncShowTractPopup {
  (tractId?: NormalizedTractId, coords?: mapboxgl.LngLatLike, instructions?: string): void;
}

export const initializeVulnerablePopulationPopup = (
  map: mapboxgl.Map,
  vulnPopTracts: VulnerablePopulationTractDictionary
): FuncShowTractPopup => {
  // remove the tract popup if open, which can occur following resizing
  document.querySelectorAll(".tract-popup").forEach(p => p.remove());

  const popup = new mapboxgl.Popup({
    className: "tract-popup",
    closeButton: false,
    // Note: this setting might be redundant, since there's a style in App.css setting
    // `pointer-events: none !important;`, so these popups will never get any clicks...
    closeOnClick: false
  }).setMaxWidth("350px");
  const popUpNode = document.createElement("div");
  const reactRoot = createRoot(popUpNode);

  const showTractPopup = (
    tractId?: NormalizedTractId,
    coords?: mapboxgl.LngLatLike,
    instructions?: string
  ) => {
    if (tractId && coords && tractId in vulnPopTracts) {
      VulnerablePopulationsPopUp(reactRoot, vulnPopTracts[tractId].name, instructions || "");
      popup.setLngLat(coords).setDOMContent(popUpNode).addTo(map);
    } else {
      popup.remove();
    }
  };

  return showTractPopup;
};

export const toggleCensusTractsLayer = (
  map: mapboxgl.Map,
  visible: boolean,
  tractInfo: VulnerablePopulationTractInfo,
  vulnerablePopulations: VulnerablePopulationsState,
  size: string,
  displayControlsAndLabels?: boolean
) => {
  const { mapboxTilesetId, sourceLayer } = BOUNDARY_LAYERS.census_tracts;

  // No in-place modification, these always get cleared and rebuilt
  Object.entries(OVERLAY_IDS).forEach(([layerName, layerId]) => {
    if (layerName.startsWith("tracts")) {
      map.getLayer(layerId) && map.removeLayer(layerId);
    }
  });

  // if nothing should be visible, bail right after layers are cleared
  if (!visible) return;

  const intersectingTracts = tractInfo.geos.tracts as VulnerablePopulationTractDictionary;
  const intersectingTractIds = Object.keys(intersectingTracts).map(id => parseInt(id, 10));
  const highlightedTractIds = displayControlsAndLabels
    ? vulnerablePopulations.selectedHighlightedTractIds
    : vulnerablePopulations.defaultHighlightedTractIds;
  const unHighlightedTractIds = intersectingTractIds.filter(
    id => !highlightedTractIds.includes(id)
  );

  const minzoom = 2;

  // On desktop, when the vulnerablePopulationsTractDetailPanel is closed, remove the
  // highlighting from the selected tract in the map
  map &&
    !vulnerablePopulations.selectedPanelTract &&
    size === "small" &&
    clearTractHighlighting(map);

  // On mobile, when the vulnerablePopulationsModal is closed, remove the highlighting from
  // the selected tract in the map
  map &&
    !vulnerablePopulations.selectedModalTract &&
    size === "small" &&
    clearTractHighlighting(map);

  if (displayControlsAndLabels) {
    // Add base texture to all intersecting tracts
    map.addLayer(
      {
        id: OVERLAY_IDS.tractsLayerBaseTexture,
        source: mapboxTilesetId,
        "source-layer": sourceLayer,
        type: "fill",
        minzoom,
        paint: {
          "fill-pattern": "tmpoly-circle-alt-light-200-black",
          "fill-opacity": 0.2
        },
        filter: ["in", ["id"], ["literal", intersectingTractIds]]
      },
      boundaryLayerInsertBeforeSecondary
    );
    // add fill layer to shade on mouseover and get accurate mouse location for tooltip addition
    map.addLayer(
      {
        id: OVERLAY_IDS.tractsLayerBaseFill,
        source: mapboxTilesetId,
        "source-layer": sourceLayer,
        type: "fill",
        minzoom,
        paint: {
          "fill-color": "#000",
          "fill-opacity": ["match", ["feature-state", "highlight"], "light", 0.3, "dark", 0.7, 0]
        },
        filter: ["in", ["id"], ["literal", intersectingTractIds]]
      },
      boundaryLayerInsertBeforeSecondary
    );
  }

  map.addLayer(
    {
      id: OVERLAY_IDS.tractsUnhighlightedLayerIdLine,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      ...defaultLineOptions,
      minzoom,
      paint: {
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // Syntax in pairs of two:
          // zoom level,
          // width in px,
          minzoom,
          0,
          7,
          displayControlsAndLabels ? 1 : 0.5,
          12,
          1.1
        ],
        "line-opacity": 0.4,
        "line-color": "hsl(0, 0%, 0%)"
      },
      filter: ["in", ["id"], ["literal", unHighlightedTractIds]]
    } as mapboxgl.LineLayer,
    boundaryLayerInsertBeforeSecondary
  );

  if (highlightedTractIds.length > 0) {
    map.addLayer(
      {
        id: OVERLAY_IDS.tractsHighlightedLayerIdOutline,
        source: mapboxTilesetId,
        "source-layer": sourceLayer,
        ...defaultLineOptions,
        minzoom,
        paint: {
          "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            // Syntax in pairs of two:
            // zoom level,
            // width in px,
            minzoom,
            2,
            8,
            displayControlsAndLabels ? 4 : 2
          ],
          "line-color": "hsl(0, 0%, 100%)"
        },
        filter: ["in", ["id"], ["literal", highlightedTractIds]]
      } as mapboxgl.LineLayer,
      boundaryLayerInsertBeforeSecondary
    );
    map.addLayer(
      {
        id: OVERLAY_IDS.tractsLayerIdFill,
        source: mapboxTilesetId,
        "source-layer": sourceLayer,
        type: "fill",
        minzoom,
        paint: {
          "fill-color": "hsla(184, 97%, 31%, 0.15)"
        },
        filter: ["in", ["id"], ["literal", highlightedTractIds]]
      },
      boundaryLayerInsertBeforeSecondary
    );
    map.addLayer(
      {
        id: OVERLAY_IDS.tractsHighlightedLayerIdLine,
        source: mapboxTilesetId,
        "source-layer": sourceLayer,
        ...defaultLineOptions,
        minzoom,
        paint: {
          "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            // Syntax in pairs of two:
            // zoom level,
            // width in px,
            minzoom,
            0,
            7,
            displayControlsAndLabels ? 1.1 : 0.75,
            12,
            1.1
          ],
          "line-color": "hsl(184, 97%, 31%)",
          "line-opacity": 1
        },
        filter: ["in", ["id"], ["literal", highlightedTractIds]]
      } as mapboxgl.LineLayer,
      boundaryLayerInsertBeforeSecondary
    );
  }
};
