import { Box, Text, Heading, ResponsiveContext } from "grommet";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { GeographyLevel, Screen, RiskClassLevel } from "../models";
import { riskLabelColors } from "../constants";
import GlossaryLink from "./GlossaryLink";
import { getCompareDescriptor, getGeographyTypeLabel, getOverviewRpsPctRank } from "../utils";

interface StateProps {
  readonly countyDescNames?: readonly string[];
  readonly detailPlaceId: string;
  readonly detailPlaceName: string;
  readonly geographyLevel: GeographyLevel;
  readonly stateDescName: string;
  readonly rpsRank?: number;
  readonly rpsRankClass?: RiskClassLevel;
}

interface QueryParamTypes {
  readonly screen?: Screen;
  readonly stateId?: string;
  readonly countyIds?: string;
}

const GeographyDescription = (props: StateProps) => {
  const {
    countyDescNames,
    detailPlaceId,
    detailPlaceName,
    geographyLevel,
    stateDescName,
    rpsRank,
    rpsRankClass
  } = props;
  const { screen, stateId, countyIds } = useParams<QueryParamTypes>();
  const countyIdsArray = countyIds ? countyIds.split("|") : [];
  const stateLink = `/${screen}/${stateId}/`;
  // Show county link(s) if there is at least one county to show
  // and it is not the currently selected geography.
  const showCounty = !!(countyIdsArray.length > 0 && countyIdsArray[0] !== detailPlaceId);
  const showState = !!(stateId && stateId !== detailPlaceId);

  const rpsPctRank = getOverviewRpsPctRank(rpsRank, rpsRankClass);
  const compareDescriptor = getCompareDescriptor(rpsRank, rpsRankClass);
  const geographyTypeLabel = getGeographyTypeLabel(geographyLevel);

  const description =
    screen === Screen.Overview ? (
      <Text size="inherit">
        {detailPlaceName} has a{" "}
        <GlossaryLink term="risk_rating" alignBottom={true}>
          <Text
            size="inherit"
            color={
              rpsRankClass
                ? riskLabelColors[rpsRankClass as keyof typeof riskLabelColors]
                : "heading"
            }
          >
            {rpsRankClass} risk
          </Text>
        </GlossaryLink>{" "}
        of wildfire—{compareDescriptor} than{" "}
        {rpsRankClass === RiskClassLevel.Low && rpsPctRank !== undefined && rpsPctRank >= 95
          ? "nearly all"
          : `${rpsPctRank}% of`}{" "}
        {geographyTypeLabel} in the US.
      </Text>
    ) : (
      detailPlaceName
    );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box gap={screen === Screen.Overview ? "large" : "xsmall"}>
          {size !== "small" && showState && (
            <Box
              as="nav"
              aria-label="Breadcrumb"
              direction="row"
              align="center"
              gap=".5rem"
              id="breadcrumb-nav"
            >
              <ol>
                <li>
                  <Link className="button-link small" to={stateLink}>{`${stateDescName}`}</Link>
                </li>
                {showCounty && (
                  <li>
                    {countyDescNames &&
                      countyDescNames.map((countyDescName, i) => {
                        const countyLink = `${stateLink}${countyIdsArray[i]}/`;
                        return (
                          <span key={i} style={{ display: "contents" }}>
                            <Link className="button-link small" to={countyLink}>
                              {`${countyDescName}`}
                            </Link>

                            {i !== countyDescNames.length - 1 && (
                              <Text size="small" color="var(--text-500)" weight="bold" aria-hidden>
                                /
                              </Text>
                            )}
                          </span>
                        );
                      })}
                  </li>
                )}
              </ol>
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              gap: ".75rem"
            }}
          >
            {screen === Screen.Overview ? (
              <Heading level={2} size={size === "small" ? "xlarge" : "xxlarge"}>
                {description}
              </Heading>
            ) : (
              <Text
                margin={size === "small" ? { top: "0", bottom: ".6rem", vertical: "0" } : "0"}
                size={size === "small" ? "2.2rem" : "3rem"}
                weight={800}
              >
                {description}
              </Text>
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default GeographyDescription;
