import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { RiskToHomesAction } from "../actions";
import {
  ActionTypes,
  riskToHomesFetchFailure,
  riskToHomesFetchSuccess
} from "../actions/riskToHomes";

import { fetchRiskToHomes } from "../api";
import { RiskToHomesResource } from "../models";
import { Resource } from "../resource";

export type RiskToHomesState = Resource<RiskToHomesResource>;

export const initialState: RiskToHomesState = {
  isPending: false
};

const RiskToHomesCollectionReducer: LoopReducer<RiskToHomesState, RiskToHomesAction> = (
  state: RiskToHomesState = initialState,
  action: RiskToHomesAction
): RiskToHomesState | Loop<RiskToHomesState, RiskToHomesAction> => {
  switch (action.type) {
    case ActionTypes.RISK_TO_HOMES_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchRiskToHomes, {
          args: [action.level, action.geoId, action.detailGeoId, action.detailGeo],
          successActionCreator: riskToHomesFetchSuccess,
          failActionCreator: riskToHomesFetchFailure
        })
      );
    case ActionTypes.RISK_TO_HOMES_FETCH_SUCCESS:
      return {
        resource: {
          riskToHomes: action.riskToHomes
        }
      };
    case ActionTypes.RISK_TO_HOMES_FETCH_FAILURE:
      return {
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default RiskToHomesCollectionReducer;
