import React, { useRef, useEffect, useState } from "react";
import { Grommet, ResponsiveContext, Text } from "grommet";
import CustomGrommetTheme from "../grommet-theme";
import { connect, Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import mapboxgl, { LngLatBoundsLike, LngLatLike } from "mapbox-gl";
import styled from "styled-components";
import { Feature, featureCollection } from "@turf/helpers";
import store from "../store";
import { State } from "../reducers";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { BOUNDARY_LAYERS } from "./mapLayers";
import {
  closeTractDetailPanel,
  highlightTract,
  selectTract,
  zoomAndCenterTract
} from "./mapListeners";
import { VulnerablePopulationTractDictionary } from "../models";
import pointOnFeature from "@turf/point-on-feature";
import VulnerablePopulationsPopUp from "./VulnerablePopulationsPopUp";

interface StateProps {
  readonly vulnerablePopulations: VulnerablePopulationsState;
}
interface Props extends StateProps {
  readonly map: mapboxgl.Map;
  readonly bounds: ReadonlyArray<number>;
  readonly placeId: string;
}

const { mapboxTilesetId, sourceLayer } = BOUNDARY_LAYERS.census_tracts;

const AccessibleTractsContainer = styled.details`
  font-size: 1.4rem;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-small) !important;
  padding: 0.75rem;
  color: var(--highlight-800);
  margin: 1rem 1rem 0 1rem;

  &:not(:has(:focus-visible)) {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  summary {
    padding: 0.25rem;
    font-weight: 700;
  }
`;

const Instructions = styled(Text)`
  display: block;
  font-weight: 500;
  margin-top: 0.25rem;
  color: var(--text-800);
`;

const Key = styled(Text)`
  border: 1px solid var(--warm-gray-400);
  border-radius: 2px;
  margin: 0 0.15ch;
  font-size: 0.9em;
  padding: 0 0.25ch;
`;

// TODO: Ideally showTractPopup would be provided as a function when this widget is initialized,
// like it is for the mouse interactivity. Defining these at the package level is not great.
// But it works, and doesn't conflict in practice with the popups triggered by mouse events
// (in theory it could, but as long as each gets hidden when it should, it won't be a problem),
// and the way `showTractPopup` is initialized in `Map.tsx` is not compatible with the way
// this widget is initialized (it needs to depend on VulnerablePopulations and this can't).
const popup = new mapboxgl.Popup({
  className: "tract-popup",
  closeButton: false,
  closeOnClick: false
}).setMaxWidth("350px");
const popUpNode = document.createElement("div");
const reactRoot = createRoot(popUpNode);

const showTractPopup = (
  map: mapboxgl.Map,
  tractName: string,
  coords: LngLatLike,
  instructions: string
) => {
  VulnerablePopulationsPopUp(reactRoot, tractName, instructions);
  popup.setLngLat(coords).setDOMContent(popUpNode).addTo(map);
};

const focusTract = (
  map: mapboxgl.Map,
  tractId: string,
  tractName: string,
  bounds: readonly number[],
  size: string,
  placeId: string
) => {
  // When a tract is focussed, close previously selected tract modal
  closeTractDetailPanel(map);
  highlightTract(map, tractId, "light");
  zoomAndCenterTract(map, bounds as LngLatBoundsLike, size);
  map.once("moveend", e =>
    // If the user tabs to another tract while the map is still flying to the
    // first tract, the moveend for the first flight fires as well. To detect
    // whether we should continue showing a popup or not, map.isMoving() can
    // tell us if the next animation is already in progress. However, the function
    // returns false immediately after moveend fires, hence we await a setTimeout
    // with 0 delay, after which the function returns accurate results.
    setTimeout(() => {
      if (map.isMoving()) return;
      // No popup on small viewport
      if (size === "small") return;
      const tractFeatures = map.querySourceFeatures(mapboxTilesetId, {
        sourceLayer: sourceLayer,
        filter: ["in", ["id"], ["literal", tractId]]
      });
      if (tractFeatures.length) {
        const point = pointOnFeature(featureCollection(tractFeatures as Feature[])).geometry
          .coordinates as LngLatLike;
        showTractPopup(map, tractName, point, "Press Enter to see details");
      }
    })
  );
};

interface AccessibleTractListProps {
  label: string;
  map: mapboxgl.Map;
  placeId: string;
  tracts: VulnerablePopulationTractDictionary;
  size: string;
}

const AccessibleTractList = ({ label, map, placeId, tracts, size }: AccessibleTractListProps) => (
  <ul title={label} className="sr-only" onBlur={() => popup.remove()}>
    {Object.keys(tracts).map(tractId => (
      <li key={tractId}>
        <button
          className="accessible-tract-button"
          onClick={() => {
            selectTract(map, tractId, placeId, size);
          }}
          onFocus={() =>
            focusTract(map, tractId, tracts[tractId].name, tracts[tractId].bounds, size, placeId)
          }
          aria-describedby="tract-button-instructions"
        >
          {tracts[tractId].name}
        </button>
      </li>
    ))}
  </ul>
);

const Widget = ({ vulnerablePopulations, map, bounds, placeId }: Props) => {
  const summaryRef = useRef<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [unhighlightedTracts, setUnhighlightedTracts] =
    useState<VulnerablePopulationTractDictionary | null>(null);

  const tracts =
    "resource" in vulnerablePopulations.tractInfo &&
    vulnerablePopulations.tractInfo.resource.tractInfo.geos.tracts;
  const highlightedTractIds = vulnerablePopulations.selectedHighlightedTractIds;
  const highlightedTracts = Object.keys(tracts)
    .filter(id => highlightedTractIds.includes(parseInt(id, 10)))
    .reduce((obj, key) => {
      obj[key as keyof typeof tracts] = tracts[key as keyof typeof tracts];
      return obj;
    }, {});

  useEffect(() => {
    if (isOpen) {
      // Calculating unhighlighted tracts is connected to isOpen so that this calculation
      // doesn't rerun every time any user updates the map filters but only when it's needed.
      setUnhighlightedTracts(
        Object.keys(tracts)
          .filter(id => !highlightedTractIds.includes(parseInt(id, 10)))
          .reduce((obj, key) => {
            obj[key as keyof typeof tracts] = tracts[key as keyof typeof tracts];
            return obj;
          }, {})
      );
    } else {
      setUnhighlightedTracts(null);
    }
  }, [isOpen, tracts, highlightedTractIds]);

  const toggleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isOpen) {
      // When the tract list is opened, close previously selected tract
      closeTractDetailPanel(map);
    }
    setIsOpen(!isOpen);
  };

  const keyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      summaryRef.current && summaryRef.current.focus();
      event.preventDefault();
    }
  };

  return (
    <Grommet theme={CustomGrommetTheme} background={"none"}>
      <ResponsiveContext.Consumer>
        {size => (
          <AccessibleTractsContainer open={isOpen} onKeyDown={e => keyPress(e)}>
            <summary onClick={e => toggleOpen(e)} ref={summaryRef}>
              {Object.keys(tracts).length} Census Tracts
              {isOpen && (
                <Instructions aria-hidden>
                  Press
                  <Key>TAB</Key> to navigate, <Key>ENTER</Key> to select, <Key>ESC</Key> to close.
                </Instructions>
              )}
            </summary>
            {isOpen && (
              <>
                {/* This element is hidden but provides instructions in tract buttons via aria-describedBy. */}
                <span id="tract-button-instructions" style={{ display: "none" }}>
                  Show tract detail table
                </span>
                <AccessibleTractList
                  label="Highlighted census tracts"
                  map={map}
                  placeId={placeId}
                  tracts={highlightedTracts}
                  size={size}
                />
                {unhighlightedTracts && (
                  <AccessibleTractList
                    label="Unhighlighted census tracts"
                    map={map}
                    placeId={placeId}
                    tracts={unhighlightedTracts}
                    size={size}
                  />
                )}
              </>
            )}
          </AccessibleTractsContainer>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  vulnerablePopulations: state.vulnerablePopulations
});

const WidgetComponent = connect(mapStateToProps)(Widget);

const AccessibleTractsWidget = (
  el: HTMLDivElement,
  bounds: ReadonlyArray<number>,
  placeId: string
): mapboxgl.IControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(
        <Provider store={store}>
          <WidgetComponent map={map} bounds={bounds} placeId={placeId} />
        </Provider>
      );
      return el;
    },
    onRemove: () => {
      root.render(undefined);
    }
  };
};

export default AccessibleTractsWidget;
