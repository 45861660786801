import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const HouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 19 21" a11yTitle="House" {...props}>
      <path
        stroke="none"
        d="M 15.5833 0.5 H 3.08331 C 1.67706 0.5 0.583313 1.63281 0.583313 3 V 18 C 0.583313 19.4062 1.67706 20.5 3.08331 20.5 H 15.5833 C 16.9505 20.5 18.0833 19.4062 18.0833 18 V 3 C 18.0833 1.63281 16.9505 0.5 15.5833 0.5 Z M 3.08331 4.875 C 3.08331 4.5625 3.35675 4.25 3.70831 4.25 H 4.95831 C 5.27081 4.25 5.58331 4.5625 5.58331 4.875 V 6.125 C 5.58331 6.47656 5.27081 6.75 4.95831 6.75 H 3.70831 C 3.35675 6.75 3.08331 6.47656 3.08331 6.125 V 4.875 Z M 5.58331 16.125 C 5.58331 16.4766 5.27081 16.75 4.95831 16.75 H 3.70831 C 3.35675 16.75 3.08331 16.4766 3.08331 16.125 V 14.875 C 3.08331 14.5625 3.35675 14.25 3.70831 14.25 H 4.95831 C 5.27081 14.25 5.58331 14.5625 5.58331 14.875 V 16.125 Z M 6.9505 9.40625 L 4.763 11.5938 C 4.64581 11.7109 4.48956 11.75 4.33331 11.75 C 4.138 11.75 3.98175 11.7109 3.86456 11.5938 L 2.92706 10.6562 C 2.69269 10.4219 2.69269 9.99219 2.92706 9.75781 C 3.16144 9.52344 3.59113 9.52344 3.8255 9.75781 L 4.33331 10.2656 L 6.05206 8.50781 C 6.28644 8.27344 6.71613 8.27344 6.9505 8.50781 C 7.18488 8.74219 7.18488 9.17188 6.9505 9.40625 Z M 14.9583 16.125 H 8.70831 C 8.35675 16.125 8.08331 15.8516 8.08331 15.5 C 8.08331 15.1875 8.35675 14.875 8.70831 14.875 H 14.9583 C 15.2708 14.875 15.5833 15.1875 15.5833 15.5 C 15.5833 15.8516 15.2708 16.125 14.9583 16.125 Z M 14.9583 11.125 H 8.70831 C 8.35675 11.125 8.08331 10.8516 8.08331 10.5 C 8.08331 10.1875 8.35675 9.875 8.70831 9.875 H 14.9583 C 15.2708 9.875 15.5833 10.1875 15.5833 10.5 C 15.5833 10.8516 15.2708 11.125 14.9583 11.125 Z M 14.9583 6.125 H 8.70831 C 8.35675 6.125 8.08331 5.85156 8.08331 5.5 C 8.08331 5.1875 8.35675 4.875 8.70831 4.875 H 14.9583 C 15.2708 4.875 15.5833 5.1875 15.5833 5.5 C 15.5833 5.85156 15.2708 6.125 14.9583 6.125 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default HouseIcon;
