import React from "react";
import { Anchor, Box, Footer, Grid, ResponsiveContext, Text } from "grommet";
import styled from "styled-components";
import CombinedLogos from "../icons/CombinedLogos";
import OpenGlossaryButton from "./OpenGlossaryButton";

const FooterLogos = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const TopRowFooterLink = styled(Text)`
  color: white;
  font-weight: 700;
  font-size: 1.5rem;
`;

const BottomRowFooterLink = styled(Text)`
  color: var(--text-500);
  font-weight: 700;
`;

const AppFooter = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Footer background="black" height={"15.5rem"} pad="medium">
          <Box className="set-width-content-container" direction="row">
            <Anchor href="/" aria-label="wildfirerisk.org homepage">
              <FooterLogos>
                <CombinedLogos size="280%" aria-hidden />
              </FooterLogos>
            </Anchor>
            <Box>
              <Grid gap="small" align="center">
                <Grid columns={["23%", "31%", "auto", "auto"]} gap="1.75rem">
                  {size === "small" ? (
                    ""
                  ) : (
                    <>
                      <Box flex={true} align="start" justify="center">
                        <Anchor className="header-footer-nav-links" href="/reduce-risk">
                          <Box direction="row" align="center">
                            <TopRowFooterLink>Take Action</TopRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="start" justify="center">
                        <Anchor className="header-footer-nav-links" href="/download">
                          <Box direction="row" align="center">
                            <TopRowFooterLink>Download Data</TopRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                    </>
                  )}
                  {size === "small" ? (
                    ""
                  ) : (
                    <Box flex={true} align="start" justify="center" style={{ fontWeight: 700 }}>
                      <TopRowFooterLink>
                        <OpenGlossaryButton unstyled={true} />
                      </TopRowFooterLink>
                    </Box>
                  )}
                </Grid>
                <Grid columns={["auto", "auto", "auto", "auto"]} gap="1.75rem">
                  {size === "small" ? (
                    ""
                  ) : (
                    <>
                      <Box flex={true} align="center" justify="center">
                        <Anchor className="header-footer-nav-links" href="/understand-risk">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>Understand Risk</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="center" justify="center">
                        <Anchor className="header-footer-nav-links" href="/reduce-risk">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>Reduce Risk</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="center" justify="center">
                        <Anchor className="header-footer-nav-links" href="/about">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>About</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="center" justify="center">
                        <Anchor className="header-footer-nav-links" href="/contact">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>Contact</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Footer>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default AppFooter;
