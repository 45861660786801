import React from "react";
import { Paragraph } from "grommet";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { GeographyVulnerablePopulationData } from "../models";
import GlossaryLink from "./GlossaryLink";

interface StateProps {
  readonly geography: string;
  readonly detailGeographyId: string;
  readonly vulnerablePopulations: VulnerablePopulationsState;
}

const VulnerablePopulationsComparisonText = (props: StateProps) => {
  const { geography, detailGeographyId, vulnerablePopulations } = props;
  const tractData =
    "resource" in vulnerablePopulations.tractInfo &&
    vulnerablePopulations.tractInfo.resource.tractInfo.geos[detailGeographyId]
      ? vulnerablePopulations.tractInfo.resource.tractInfo.geos[detailGeographyId]
      : undefined;
  const data = tractData as GeographyVulnerablePopulationData;

  return (
    <>
      {geography && data && (
        <Paragraph
          size="xxlarge"
          color="var(--text-900) !important"
          style={{ fontWeight: 700 }}
          id="risk-description"
        >
          {/* The population of {geography} is{" "}
          {data.population && data.population.value
            ? data.population.value.toLocaleString()
            : "not available"}
          .  */}
          Potentially{" "}
          <GlossaryLink term="vulnerable_populations">vulnerable populations</GlossaryLink> may
          experience difficulty preparing for and responding to wildfire.
        </Paragraph>
      )}
    </>
  );
};

export default VulnerablePopulationsComparisonText;
