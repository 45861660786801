import { Geography } from "../models";

export enum ActionTypes {
  GEOGRAPHY_FETCH = "GEOGRAPHY_FETCH",
  GEOGRAPHY_FETCH_SUCCESS = "GEOGRAPHY_FETCH_SUCCESS",
  GEOGRAPHY_FETCH_FAILURE = "GEOGRAPHY_FETCH_FAILURE"
}

// There are separate actions for making the API request and handling both its
// success and failure scenarios
export type GeographyAction =
  | { readonly type: ActionTypes.GEOGRAPHY_FETCH; readonly geoId: string }
  | {
      readonly type: ActionTypes.GEOGRAPHY_FETCH_SUCCESS;
      readonly geography: Geography;
    }
  | { readonly type: ActionTypes.GEOGRAPHY_FETCH_FAILURE; readonly errorMessage: string };

export function geographyFetch(geoId: string): GeographyAction {
  return {
    type: ActionTypes.GEOGRAPHY_FETCH,
    geoId
  };
}

export function geographyFetchSuccess(geography: Geography): GeographyAction {
  return {
    type: ActionTypes.GEOGRAPHY_FETCH_SUCCESS,
    geography
  };
}

export function geographyFetchFailure(errorMessage: string): GeographyAction {
  return {
    type: ActionTypes.GEOGRAPHY_FETCH_FAILURE,
    errorMessage
  };
}
