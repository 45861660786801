import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const CloseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="CloseIcon" viewBox="0 0 12 10" a11yTitle="Close" stroke="none" {...props}>
      <path
        d="M10.7736 1.28026L7.02653 5.02733L10.7424 8.74318C11.0546 9.02421 11.0546 9.49259 10.7424 9.77362C10.4613 10.0859 9.99296 10.0859 9.71193 9.77362L5.96486 6.05777L2.24901 9.77362C1.96798 10.0859 1.4996 10.0859 1.21857 9.77362C0.906311 9.49259 0.906311 9.02421 1.21857 8.71195L4.93441 4.9961L1.21857 1.28026C0.906311 0.999225 0.906311 0.530841 1.21857 0.218585C1.4996 -0.0624451 1.96798 -0.0624451 2.28024 0.218585L5.99608 3.96566L9.71193 0.249811C9.99296 -0.0624451 10.4613 -0.0624451 10.7736 0.249811C11.0546 0.530841 11.0546 0.999225 10.7736 1.28026Z"
        stroke="none"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default CloseIcon;
