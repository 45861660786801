import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const CollapsedMenuIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="CollapsedMenuIcon" viewBox="0 0 13 14" a11yTitle="Expand Menu" {...props}>
      <path
        stroke="none"
        d="M5.86523 0.277344C6.06836 0.175781 6.27148 0.125 6.5 0.125C6.70312 0.125 6.90625 0.175781 7.10938 0.277344L12.4414 2.53711C12.7715 2.68945 13 3.01953 13 3.375C13 3.75586 12.7715 4.08594 12.4414 4.23828L7.10938 6.49805C6.90625 6.59961 6.70312 6.625 6.5 6.625C6.27148 6.625 6.06836 6.59961 5.86523 6.49805L0.533203 4.23828C0.203125 4.08594 0 3.75586 0 3.375C0 3.01953 0.203125 2.68945 0.533203 2.53711L5.86523 0.277344ZM6.5 1.34375C6.42383 1.34375 6.37305 1.36914 6.34766 1.39453L1.67578 3.375L6.34766 5.38086C6.37305 5.40625 6.42383 5.40625 6.5 5.40625C6.55078 5.40625 6.60156 5.40625 6.62695 5.38086L11.2988 3.375L6.62695 1.39453C6.60156 1.36914 6.55078 1.34375 6.5 1.34375ZM6.34766 8.63086C6.37305 8.65625 6.42383 8.65625 6.5 8.65625C6.55078 8.65625 6.60156 8.65625 6.62695 8.63086L11.4766 6.57422C11.3496 6.39648 11.3242 6.16797 11.4258 5.96484C11.5781 5.66016 11.9336 5.5332 12.2383 5.68555L12.4668 5.8125C12.7969 5.96484 13 6.26953 13 6.625C13 7.00586 12.7715 7.33594 12.416 7.48828L7.10938 9.74805C6.90625 9.84961 6.70312 9.875 6.5 9.875C6.27148 9.875 6.06836 9.84961 5.86523 9.74805L0.558594 7.48828C0.203125 7.33594 0 7.00586 0 6.59961C0 6.29492 0.126953 6.01562 0.380859 5.83789L0.660156 5.63477C0.914062 5.45703 1.29492 5.50781 1.49805 5.78711C1.67578 6.01562 1.65039 6.3457 1.44727 6.54883L6.34766 8.63086ZM1.49805 9.03711C1.67578 9.26562 1.65039 9.5957 1.44727 9.79883L6.34766 11.8809C6.37305 11.9062 6.42383 11.9062 6.5 11.9062C6.55078 11.9062 6.60156 11.9062 6.62695 11.8809L11.4766 9.82422C11.3496 9.64648 11.3242 9.41797 11.4258 9.21484C11.5781 8.91016 11.9336 8.7832 12.2383 8.93555L12.4668 9.0625C12.7969 9.21484 13 9.51953 13 9.875C13 10.2559 12.7715 10.5859 12.416 10.7383L7.10938 12.998C6.90625 13.0996 6.70312 13.125 6.5 13.125C6.27148 13.125 6.06836 13.0996 5.86523 12.998L0.558594 10.7383C0.203125 10.5859 0 10.2559 0 9.84961C0 9.54492 0.126953 9.26562 0.380859 9.08789L0.660156 8.88477C0.914062 8.70703 1.29492 8.75781 1.49805 9.03711Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default CollapsedMenuIcon;
