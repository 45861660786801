import { Box, Button, Heading, Image, Layer, Paragraph, ResponsiveContext, Text } from "grommet";
import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import GeographySearchInput from "../components/GeographySearch";
import AppFooter from "../components/AppFooter";
import ReactPlayer from "react-player";
import PlayIcon from "../icons/PlayIcon";
import CloseIcon from "../icons/CloseIcon";

const PlayButton = styled(Button)`
  --icon-fill: black;
  position: relative;
  img {
    filter: brightness(0.8);
  }
  &:hover {
    --icon-fill: var(--highlight-700);
  }
  &:hover img {
    opacity: 0.9;
  }
`;

const SearchScreen = () => {
  const [show, setShow] = React.useState(false);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box as="main" id="main-content" tabIndex={-1} background={"warm-gray-100"}>
            <Helmet>
              <meta
                name="description"
                content="View interactive charts and maps about wildfire risk for your community, tribal area, county, or state. Discover resources to reduce risk."
              />
              <title>Explore your risk - Wildfire Risk to Communities</title>
            </Helmet>
            <Box
              className="search-screen"
              height={{ min: "51rem" }}
              style={{
                background: "url(" + process.env.PUBLIC_URL + "/HE_Wildfires_07_22.jpg)",
                backgroundPosition: "center 80%",
                backgroundSize: "cover"
              }}
              align="center"
              justify="center"
            >
              <Box
                pad="medium"
                gap="medium"
                style={{
                  maxWidth: size === "small" ? "64.5rem" : "66.5rem"
                }}
              >
                <Heading level={2} color="white !important">
                  Explore your wildfire risk
                </Heading>
                <Paragraph
                  color="var(--warm-gray-400) !important"
                  size="xlarge"
                  style={{ marginBottom: "1rem" }}
                >
                  Type the name of your community, tribal area, county, or state to view interactive
                  charts and maps about wildfire risk and discover resources to reduce risk.
                </Paragraph>
                <GeographySearchInput />
              </Box>
            </Box>

            <Box
              alignSelf="center"
              gap="large"
              justify="stretch"
              margin={{
                top: "large",
                bottom: "xlarge"
              }}
              pad={{
                horizontal: "medium"
              }}
              width={{ max: size !== "small" ? "88rem" : undefined }}
            >
              <Box
                justify="between"
                className="content-box"
                direction={size === "small" ? "column" : "row"}
                gap="medium"
              >
                <Box
                  pad={{
                    horizontal: size === "small" ? "0" : "medium"
                  }}
                  width={{ min: size !== "small" ? "35rem" : undefined }}
                >
                  <Heading level={3} style={{ borderBottom: "none" }}>
                    How to use Wildfire Risk to Communities
                  </Heading>
                  <Paragraph size="xlarge">
                    Watch this one-minute video to learn more about how to use Wildfire Risk to
                    Communities.
                  </Paragraph>
                </Box>
                <PlayButton onClick={() => setShow(true)} aria-describedby="play-description">
                  <Text className="sr-only" id="play-description">
                    Open video player
                  </Text>
                  <Image
                    src={process.env.PUBLIC_URL + "/WRCdemo.jpg"}
                    fill={true}
                    fit="cover"
                    style={{
                      marginBottom: "-2px"
                    }}
                    alt=""
                  />
                  <Box
                    as="span"
                    style={{ position: "absolute", width: "100%", height: "100%" }}
                    align="center"
                    justify="center"
                  >
                    <PlayIcon size="96px" fill="var(--icon-fill)" />
                  </Box>
                </PlayButton>
              </Box>

              {show && (
                <ResponsiveContext.Consumer>
                  {size => (
                    <Layer
                      onEsc={() => setShow(false)}
                      onClickOutside={() => setShow(false)}
                      background="rgba(0,0,0,.7)"
                      full={true}
                      position="center"
                      animate={false}
                      style={{
                        justifyContent: "center"
                      }}
                    >
                      <Box
                        round="var(--border-radius)"
                        background="white"
                        pad="medium"
                        margin="medium"
                        gap="medium"
                        elevation="large"
                      >
                        <Box direction="row" justify="between" height={{ min: "44px" }}>
                          <Heading
                            level="4"
                            style={{
                              fontSize: "2rem"
                            }}
                          >
                            How to use Wildfire Risk to Communities
                          </Heading>
                          <Button
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              setShow(false);
                              event.stopPropagation();
                            }}
                            icon={<CloseIcon size="11px" fill="var(--text-800)" aria-hidden />}
                            a11yTitle="Close video player"
                            secondary
                            size="small"
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              justifyContent: "center"
                            }}
                          />
                        </Box>
                        <ReactPlayer
                          controls={true}
                          width="100%"
                          height="auto !important"
                          url="https://vimeo.com/642862066"
                          style={{
                            aspectRatio: "16/9"
                          }}
                        />
                      </Box>
                    </Layer>
                  )}
                </ResponsiveContext.Consumer>
              )}
            </Box>
          </Box>
          <AppFooter />
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default SearchScreen;
