import React from "react";
import { Box, Text } from "grommet";

import { RRZFullHouseIcon } from "../icons/RRZHouseIcons";
import { RRZHouseIconCount, RRZStructureScale } from "../constants";
import { roundToSigFigs } from "../utils";

interface Props {
  structureScale: RRZStructureScale;
  totalStructures: number;
}

const RRZChartLegend = ({ structureScale, totalStructures }: Props) => {
  const percentage = 100 / RRZHouseIconCount[structureScale];
  const perShare = (totalStructures * percentage) / 100;

  return (
    <Box>
      <Text size="14px" weight={400} color="#555">
        One{" "}
        <span style={{ margin: "0rem 0.3rem", verticalAlign: "middle" }}>
          <RRZFullHouseIcon color="#757575" size="15px" />
        </span>{" "}
        represents {percentage}% of buildings, or approximately{" "}
        {roundToSigFigs(perShare, 2).toLocaleString()} structures
      </Text>
    </Box>
  );
};

export default RRZChartLegend;
