import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { DetailGeographyAction } from "../actions";
import { ActionTypes, geographyFetchFailure, geographyFetchSuccess } from "../actions/geography";

import { fetchGeography } from "../api";
import { GeographyResource } from "../models";
import { Resource } from "../resource";

export type GeographyState = Resource<GeographyResource>;

export const initialState: GeographyState = {
  isPending: false
};

// In Redux, your reducer listens for actions which may optionally have a
// payload. Each type of action is handled separately within the reducer to
// return a new state object based on some logic. `redux-loop` handles effects
// within the reducer, so now instead of just returning a new state object (eg.
// `GeographyState`) we may also return a `Loop` which wraps the new state plus some
// effect (very often a `Cmd.run`).
const geographyReducer: LoopReducer<GeographyState, DetailGeographyAction> = (
  state: GeographyState = initialState,
  action: DetailGeographyAction
): GeographyState | Loop<GeographyState, DetailGeographyAction> => {
  switch (action.type) {
    case ActionTypes.GEOGRAPHY_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchGeography, {
          args: [action.geoId],
          successActionCreator: geographyFetchSuccess,
          failActionCreator: geographyFetchFailure
        })
      );
    case ActionTypes.GEOGRAPHY_FETCH_SUCCESS:
      return {
        resource: {
          geography: action.geography
        }
      };
    case ActionTypes.GEOGRAPHY_FETCH_FAILURE:
      return {
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default geographyReducer;
