import React, { useMemo, useState } from "react";
import axios from "axios";
import { Table, TableHeader, TableBody, TableCell, TableRow, Text, Button } from "grommet";
import styled from "styled-components";
import {
  VulnerablePopulationTableRow,
  ValueMoEPctPctMoE,
  GeographyVulnerablePopulationData
} from "../models";
import GlossaryLink from "./GlossaryLink";
import { vulnerablePopulationsGlossaryTermLabels } from "../constants";
import DownloadIcon from "../icons/DownloadIcon";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid var(--warm-gray-300);
  &:nth-child(2n + 1) {
    background-color: var(--warm-gray-100);
  }
  vertical-align: baseline;
`;

const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
  padding-right: 0px;
  color: var(--text-800);
`;

function formatOneDecimal(x: number): string {
  return x.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 1 });
}

interface Props {
  readonly tableData: GeographyVulnerablePopulationData;
  readonly detailPlaceId: string | undefined;
  readonly mode: "page" | "panel" | "modal" | "narrow";
  readonly caption?: string | undefined;
}

function metricDataRow(
  label: JSX.Element,
  data: ValueMoEPctPctMoE | undefined,
  subCategory: boolean
): VulnerablePopulationTableRow {
  return {
    label,
    value: data ? data.value : undefined,
    moe: data ? data.moe : undefined,
    pct: data ? data.pct : undefined,
    pct_moe: data ? data.pct_moe : undefined,
    subCategory: subCategory
  };
}

const VulnerablePopulationsTable = ({ tableData, detailPlaceId, mode, caption }: Props) => {
  const [isReportDownloading, set_isReportDownloading] = useState(false);

  const panel = mode === "panel";
  const modal = mode === "modal";
  // We basically have 3.5 modes, since the page table and the modal share some options (no
  // margin-of-error columns) but not others (no border). Making 'modal' a special case of
  // 'narrow' lets the logic below stay relatively simple.
  const narrow = mode === "narrow" || modal;

  const vulnPopTableData = useMemo(() => {
    return tableData
      ? Object.entries(vulnerablePopulationsGlossaryTermLabels).map(([termName, termProps]) => {
          return metricDataRow(
            <GlossaryLink term={termName}>{termProps.long}</GlossaryLink>,
            tableData[termName as keyof typeof tableData],
            termProps.subCategory
          );
        })
      : undefined;
  }, [tableData]);

  const fetchReport = () => {
    if (!detailPlaceId) return null;

    const downloadUrl = process.env.REACT_APP_REPORT_DOWNLOAD_URL;

    let heStyleID = detailPlaceId.length === 2 ? detailPlaceId + "000" : detailPlaceId; // add trailing zeros to state
    heStyleID = heStyleID.replace(/^0+/, ""); //remove leading zeros

    const reportProps = {
      report_template_name: detailPlaceId.length > 5 ? "wildfire" : "wildfire-extended", // extended template is only available for counties and states
      report_sub_heading: "",
      report_format: "pdf",
      request_source: "wrc",
      position_keys: [
        {
          position_key: 1,
          geo_codes: heStyleID,
          override_name: "",
          is_aggregate: false,
          is_region: false,
          is_benchmark: false
        },
        {
          position_key: 2,
          geo_codes: heStyleID,
          override_name: "",
          is_aggregate: true,
          is_region: true,
          is_benchmark: false
        },
        {
          position_key: 3,
          geo_codes: "0",
          override_name: "United States",
          is_aggregate: true,
          is_region: false,
          is_benchmark: true
        }
      ],
      base64_graphics: [],
      user_referrer: document.location.href
    };

    const requestData = [
      {
        name: "json_input",
        value: JSON.stringify(reportProps)
      },
      {
        name: "auth_token",
        value: "58f0d42d-0ed7-493d-afe4-f042d5c3aac3"
      }
    ];
    set_isReportDownloading(true);
    axios({
      url: downloadUrl,
      method: "post",
      responseType: "blob",
      data: JSON.stringify(requestData)
    })
      .then(response => {
        set_isReportDownloading(false);
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "wildfire_risk.pdf");
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(e => {
        set_isReportDownloading(false);
        throw e;
      });
  };

  // hide the download button for tracts
  const hideDownloadButton = !detailPlaceId || detailPlaceId.length > 10;

  return (
    <>
      <Table
        style={{ border: modal ? "none" : "1px solid var(--warm-gray-300)" }}
        caption={caption}
      >
        <TableHeader>
          <TableRow style={{ verticalAlign: "baseline" }}>
            <TableCell
              scope="col"
              style={{
                textAlign: "left"
              }}
            >
              <Text size={panel ? "small" : "medium"} weight={700} color="black">
                Indicator
              </Text>
            </TableCell>
            <TableCell
              scope="col"
              style={{
                textAlign: "right"
              }}
              pad="0"
            >
              <Text size={panel ? "small" : "medium"} weight={700} color="black">
                Number
              </Text>
            </TableCell>
            {!narrow && (
              <TableCell scope="col">
                <Text
                  as="abbr"
                  title="Margin of error, Number column"
                  color="text-700"
                  size={panel ? "1.2rem" : "small"}
                  style={{ textDecoration: "none" }}
                >
                  MOE
                </Text>
              </TableCell>
            )}
            <TableCell
              scope="col"
              style={{
                textAlign: "right"
              }}
              pad={{
                horizontal: narrow ? "small" : "0"
              }}
            >
              <Text size={panel ? "small" : "medium"} weight={700} color="black">
                Percent
              </Text>
            </TableCell>
            {!narrow && (
              <TableCell scope="col">
                <Text
                  as="abbr"
                  title="Margin of error, Percent column"
                  color="text-700"
                  size={panel ? "1.2rem" : "small"}
                  style={{ textDecoration: "none" }}
                >
                  MOE
                </Text>
              </TableCell>
            )}
          </TableRow>
        </TableHeader>
        <TableBody>
          {vulnPopTableData &&
            vulnPopTableData.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell
                  style={{
                    paddingTop: "2px",
                    paddingBottom: "2px"
                  }}
                >
                  <Text
                    size={panel ? "small" : "medium"}
                    weight={"normal"}
                    margin={row.subCategory ? { left: "small" } : "0"}
                  >
                    {row.label}
                  </Text>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "right",
                    paddingTop: "6px",
                    paddingBottom: "6px"
                  }}
                >
                  <Text size={panel ? "small" : "medium"}>
                    {row.value !== undefined ? row.value.toLocaleString("en-US") : ""}{" "}
                  </Text>
                </StyledTableCell>
                {!narrow && (
                  <StyledTableCell
                    style={{
                      textAlign: "left",
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}
                  >
                    <Text color="#737373" size={panel ? "1.2rem" : "small"}>
                      {row.moe !== undefined
                        ? `±${Math.round(row.moe).toLocaleString("en-US")}`
                        : ""}
                    </Text>
                  </StyledTableCell>
                )}
                <StyledTableCell
                  style={{
                    textAlign: "right",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingLeft: "2px",
                    paddingRight: narrow ? "10px" : "0px"
                  }}
                >
                  <Text size={panel ? "small" : "medium"}>
                    {row.pct !== undefined ? formatOneDecimal(row.pct * 100) : ""}%{" "}
                  </Text>
                </StyledTableCell>
                {!narrow && (
                  <StyledTableCell
                    style={{
                      textAlign: "left",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingRight: "2px"
                    }}
                  >
                    <Text color="#737373" size={panel ? "12px" : "small"}>
                      {row.pct_moe !== undefined ? `±${formatOneDecimal(row.pct_moe * 100)}%` : ""}
                    </Text>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {!hideDownloadButton && (
        <Button
          primary
          size="medium"
          onClick={() => {
            logGoogleAnalyticsEvent("vulnerable populations", "download report", detailPlaceId);
            fetchReport();
          }}
          messages={{
            busy: "Preparing report",
            success: "Report ready"
          }}
          busy={isReportDownloading}
          label="Download detailed report"
          icon={<DownloadIcon size="14px" color="var(--highlight-700)" aria-hidden />}
          style={{
            justifyContent: isReportDownloading ? "center" : undefined
          }}
        />
      )}
    </>
  );
};

export default VulnerablePopulationsTable;
