import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const HazardousFuelsIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 19 21" a11yTitle="Hazardous Fuels" {...props}>
      <path
        stroke="none"
        d="M 17.0495 18 H 10.9167 V 19.25 C 10.9167 19.9531 10.3307 20.5 9.66666 20.5 C 8.96353 20.5 8.41666 19.9531 8.41666 19.2891 V 18.0391 H 2.20572 C 1.07291 18.0391 0.486969 16.6719 1.22916 15.8125 L 3.92447 12.7266 H 3.33853 C 2.32291 12.7266 1.81509 11.5547 2.44009 10.7734 L 4.78384 8.03906 H 4.39322 C 3.53384 8.03906 3.10416 7.14062 3.61197 6.55469 L 8.88541 0.851562 C 9.31509 0.382812 9.97916 0.382812 10.4088 0.851562 L 15.6823 6.55469 C 16.1901 7.14062 15.7604 8.03906 14.901 8.03906 H 14.5104 L 16.8542 10.7734 C 17.5182 11.5547 16.9713 12.7266 15.9557 12.7266 H 15.3698 L 18.0651 15.8125 C 18.8073 16.6719 18.2213 18 17.0495 18 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default HazardousFuelsIcon;
