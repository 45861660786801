import React, { useState } from "react";
import {
  AnnounceContext,
  Box,
  Grid,
  Heading,
  Paragraph,
  ResponsiveContext,
  Select,
  Text
} from "grommet";
import CaretIcon from "../icons/CaretIcon";
import ActionCard from "./ActionCard";
import { ActionDetails } from "../models";
import { actions } from "../constants";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const ROLES = {
  "All roles": {
    name: "Select a role",
    actions: []
  },
  ElectedOfficials: {
    name: "Elected Officials",
    actions: [
      actions.LandUsePlanning,
      actions.EvacuationAndReadiness,
      actions.EquitableRiskReduction,
      actions.Response,
      actions.RecoveryAndRebuilding
    ]
  },
  PlannersAndDevelopers: {
    name: "Planners & Developers",
    actions: [
      actions.IgnitionResistantHomes,
      actions.LandUsePlanning,
      actions.EvacuationAndReadiness,
      actions.EquitableRiskReduction,
      actions.RecoveryAndRebuilding
    ]
  },
  EmergencyServices: {
    name: "Emergency Services",
    actions: [
      actions.LandUsePlanning,
      actions.EvacuationAndReadiness,
      actions.EquitableRiskReduction,
      actions.SmokeReady,
      actions.Response
    ]
  },
  Homeowners: {
    name: "Homeowners",
    actions: [
      actions.IgnitionResistantHomes,
      actions.EvacuationAndReadiness,
      actions.SmokeReady,
      actions.PreventIgnitions,
      actions.RecoveryAndRebuilding
    ]
  },
  LandManagers: {
    name: "Land Managers",
    actions: [
      actions.LandUsePlanning,
      actions.EquitableRiskReduction,
      actions.PreventIgnitions,
      actions.Response,
      actions.HazardousFuelsManagement
    ]
  },
  FireCollaboratives: {
    name: "Fire Collaboratives",
    actions: [
      actions.IgnitionResistantHomes,
      actions.EvacuationAndReadiness,
      actions.SmokeReady,
      actions.Response,
      actions.HazardousFuelsManagement
    ]
  },
  HealthAndSocialServices: {
    name: "Health & Social Services",
    actions: [
      actions.EvacuationAndReadiness,
      actions.EquitableRiskReduction,
      actions.SmokeReady,
      actions.Response,
      actions.RecoveryAndRebuilding
    ]
  }
};

type Role = keyof typeof ROLES;

interface DropdownProps {
  readonly activeRole: string;
  setActiveRole: Function;
}

const RoleDropdown = ({ activeRole, setActiveRole }: DropdownProps) => (
  <Box direction="row" align="center" gap="small" margin={{ top: "large", bottom: "small" }}>
    <Text color="text-900" size="medium">
      Highlight relevant actions for
    </Text>
    <Box width="20rem">
      <Select
        options={Object.entries(ROLES)
          .slice(1)
          .map(([key, role]) => ({
            value: key,
            label: role.name
          }))}
        labelKey="label"
        value={activeRole}
        valueKey="value"
        valueLabel={(option: Role) => (
          <Text
            size="medium"
            style={{
              display: "flex"
            }}
            weight={option === "All roles" ? "normal" : "bold"}
            color={option === "All roles" ? "highlight-800" : "black"}
          >
            {ROLES[option].name}
          </Text>
        )}
        onChange={({ option }) => {
          logGoogleAnalyticsEvent("overview", "pick your role selection", option.label);
          setActiveRole(option.value);
        }}
        icon={<CaretIcon size="10px" />}
        a11yTitle="Select a role"
      />
    </Box>
  </Box>
);

const ActionCards = () => {
  const [activeRole, setActiveRole] = useState("All roles");

  const highlightedActions: ReadonlyArray<ActionDetails> =
    ROLES[activeRole as keyof typeof ROLES].actions;

  const cards = Object.entries(actions).map(([key, action]) => (
    <ActionCard
      key={key}
      highlight={highlightedActions.includes(action)}
      action={action}
      analyticsCategory="overview"
    />
  ));

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          className="content-box"
          style={{ gridColumn: size === "large" ? "span 2" : "" }}
          pad={{ horizontal: "medium", bottom: "medium" }}
        >
          <Heading level={3}>Identify your most relevant actions</Heading>
          <Paragraph size="xlarge">
            Everyone has a part to play in reducing wildfire risk. Select a role to highlight tools,
            tips, and programs that can help.
          </Paragraph>
          <RoleDropdown activeRole={activeRole} setActiveRole={setActiveRole} />
          <AnnounceContext.Consumer>
            {announce =>
              highlightedActions.length > 0 &&
              announce(
                `${highlightedActions.length} of ${cards.length} actions highlighted`,
                "polite",
                2000
              )
            }
          </AnnounceContext.Consumer>
          <Grid
            as="ul"
            columns={
              size === "large"
                ? ["auto", "auto", "auto"]
                : size === "medium"
                ? ["auto", "auto"]
                : ["auto"]
            }
            gap={size === "small" ? "small" : "medium"}
            style={{
              gridAutoRows: "1fr"
            }}
            a11yTitle="Actions to reduce risk"
          >
            {cards}
          </Grid>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default ActionCards;
