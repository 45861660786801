import React, { useState, createRef, RefObject, MouseEvent } from "react";
import { Drop, Box, ResponsiveContext, Text, Button } from "grommet";
import ReactHtmlParser from "react-html-parser";
import { Node } from "htmlparser2";
import { connect } from "react-redux";
import { setGlossaryTerm } from "../actions/glossaryControl";
import QuestionIcon from "../icons/QuestionIcon";
import { GlossaryState } from "../reducers/glossary";
import { State } from "../reducers";
import store from "../store";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";
import styled from "styled-components";

interface StateProps {
  readonly glossaryResource: GlossaryState;
}

interface Props {
  readonly term: string;
  readonly children: React.ReactNode;
  readonly alignBottom?: boolean;
}

const GlossaryButton = styled(Button)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding: 0 !important;
  border-radius: var(--border-radius);
  font-weight: inherit !important;
  height: auto !important;

  &:hover {
    background: var(--warm-gray-200);
    box-shadow: 0 0 0 0.1em var(--warm-gray-200);
  }
`;

const GlossaryTerm = styled.span`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: max(1px, 0.03em);
  text-decoration-color: var(--warm-gray-600);
  text-underline-offset: 0.125em;
  line-height: inherit;
  display: inline;

  &:hover {
    cursor: help;
  }
`;

// Convert links in short definition to plain text, since related-glossary-word behavior
// isn't currently defined within the tooltip.
const stripLinks: any = (node: Node) => {
  if (node.children && node.type === "tag" && node.name === "a") {
    return node.children[0].data;
  }
};

const GlossaryLink = ({ glossaryResource, term, children, alignBottom }: StateProps & Props) => {
  const [boxRef] = useState(
    createRef() as RefObject<HTMLAnchorElement> & RefObject<HTMLButtonElement>
  );
  const [openTip, setOpenTip] = useState(false);

  const closeTip = () => {
    boxRef?.current?.focus();
    setOpenTip(false);
  };

  const setTerm = (ev: MouseEvent) => {
    ev.stopPropagation();
    // Close the tip before setting the glossary term to focus the Glossary link
    // itself rather than the button in the tooltip. Why? The GlossaryComponent
    // stores the currently focussed element before opening, in order to return
    // focus to that element after closing.
    closeTip();
    store.dispatch(setGlossaryTerm(term));
  };

  const shortDefinition =
    "resource" in glossaryResource
      ? glossaryResource.resource.glossary.terms[term]?.shortDefinition
      : undefined;

  const termLabel = React.isValidElement(children) ? children.props.children : children?.toString();

  // Currently, ARIA descriptions need to be added with the aria-describedby prop, which
  // needs to point to a HTML element's ID. (Once aria-description will be supported, we
  // will be able to just use a string). Because terms can occur multiple times on a page
  // and IDs need to unique, a random string is appended to the element's ID.
  const ariaDescriptionId = `${term}-instructions-${Math.random().toString().slice(2, 8)}`;

  return shortDefinition ? (
    <GlossaryButton
      ref={boxRef}
      onClick={() => {
        logGoogleAnalyticsEvent("general", "view glossary term", term);
        setOpenTip(!openTip);
      }}
      className="glossary-link"
      aria-label={termLabel}
      aria-describedby={ariaDescriptionId}
    >
      <span id={ariaDescriptionId} style={{ display: "none" }}>
        Show definition
      </span>
      <GlossaryTerm>
        {children}
        {openTip && (
          <Drop
            // We normally show the tip above the cursor, but alignBottom overrides that to
            // show it below instead, for links that are too near the top of the page.
            align={alignBottom ? { top: "bottom" } : { bottom: "top" }}
            target={boxRef.current as HTMLButtonElement}
            elevation="medium"
            onClickOutside={closeTip}
            onEsc={closeTip}
            style={{ marginBottom: ".5rem" }}
            restrictFocus={true}
            a11yTitle={`Definition of ${termLabel}`}
            margin="small"
          >
            <Box gap="medium" pad="small" margin="none">
              <Box overflow="auto" width="medium">
                <Text>{ReactHtmlParser(shortDefinition, { transform: stripLinks })}</Text>
              </Box>
              <ResponsiveContext.Consumer>
                {size =>
                  size !== "small" && (
                    <Button
                      primary
                      size="small"
                      label="Read more in the Glossary"
                      icon={<QuestionIcon color="var(--highlight-700)" size="small" aria-hidden />}
                      reverse={true}
                      onClick={setTerm}
                      justify="between"
                      className="button-space-between"
                    />
                  )
                }
              </ResponsiveContext.Consumer>
            </Box>
          </Drop>
        )}
      </GlossaryTerm>
    </GlossaryButton>
  ) : (
    <span>{children}</span>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  glossaryResource: state.glossary
});

export default connect(mapStateToProps)(GlossaryLink);
