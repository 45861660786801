import { GeographyLevel, RRZCollection } from "../models";

export enum ActionTypes {
  RRZ_FETCH = "RRZ_FETCH",
  RRZ_FETCH_SUCCESS = "RRZ_FETCH_SUCCESS",
  RRZ_FETCH_FAILURE = "RRZ_FETCH_FAILURE"
}

// There are separate actions for making the API request and handling both its
// success and failure scenarios
export type RRZCollectionAction =
  | {
      readonly type: ActionTypes.RRZ_FETCH;
      readonly level: GeographyLevel;
      readonly geoId?: string;
    }
  | {
      readonly type: ActionTypes.RRZ_FETCH_SUCCESS;
      readonly rrz: RRZCollection;
    }
  | { readonly type: ActionTypes.RRZ_FETCH_FAILURE; readonly errorMessage: string };

export function rrzFetch(level: GeographyLevel, geoId?: string): RRZCollectionAction {
  return {
    level,
    geoId,
    type: ActionTypes.RRZ_FETCH
  };
}

export function rrzFetchSuccess(rrz: RRZCollection): RRZCollectionAction {
  return {
    type: ActionTypes.RRZ_FETCH_SUCCESS,
    rrz
  };
}

export function rrzFetchFailure(errorMessage: string): RRZCollectionAction {
  return {
    type: ActionTypes.RRZ_FETCH_FAILURE,
    errorMessage
  };
}
