import React, { useMemo } from "react";
import GlossaryLink from "./GlossaryLink";
import { GeographyLevel, Screen } from "../models";
import { getDetailScreenGeoTypeLabel } from "../utils";

interface Props {
  readonly screen: Screen;
  readonly comparisonGeographyName: string;
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly percentileRank: number;
  readonly quantityLabel: JSX.Element;
}

export const PercentileComparisonText = ({
  comparisonGeographyName,
  geography,
  geographyLevel,
  percentileRank,
  quantityLabel,
  screen
}: Props) => {
  const geographyTypeLabel: string | JSX.Element = useMemo(() => {
    const { term, label, extraText } = getDetailScreenGeoTypeLabel(
      geographyLevel,
      comparisonGeographyName
    );
    if (!term) return label;
    return (
      <>
        <GlossaryLink term={term}>{label}</GlossaryLink>
        {extraText}
      </>
    );
  }, [comparisonGeographyName, geographyLevel]);

  return (
    <>
      {percentileRank > 5 ? (
        <>
          {screen === Screen.RiskToHomes ? (
            <>
              <GlossaryLink term="homes">Homes</GlossaryLink> in {geography} have
            </>
          ) : (
            `${geography} has`
          )}
          , on average, greater {quantityLabel} than {percentileRank}% of {geographyTypeLabel}
          {comparisonGeographyName ? ` in ${comparisonGeographyName}` : ""}
        </>
      ) : (
        <>
          {screen === Screen.RiskToHomes ? (
            <>
              <GlossaryLink term="homes">Homes</GlossaryLink> in nearly
            </>
          ) : (
            "Nearly"
          )}{" "}
          all other {comparisonGeographyName} {geographyTypeLabel}, on average, have greater{" "}
          {quantityLabel} than {screen === Screen.RiskToHomes && "in"} {geography}
        </>
      )}
      .
    </>
  );
};
