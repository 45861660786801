import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const DownloadIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="DownloadIcon"
      viewBox="0 0 14 19"
      a11yTitle="Download"
      color="#0095CB"
      {...props}
    >
      <path
        d="M13.6666 4.75H9.16663V0.25L13.6666 4.75ZM9.16663 5.875H13.6666V16.5625C13.6666 17.5117 12.8932 18.25 11.9791 18.25H1.85413C0.904907 18.25 0.166626 17.5117 0.166626 16.5625V1.9375C0.166626 1.02344 0.904907 0.25 1.85413 0.25H8.04163V4.75C8.04163 5.38281 8.53381 5.875 9.16663 5.875ZM9.13147 10.6211L7.76038 12.0273V8.40625C7.76038 7.94922 7.37366 7.5625 6.91663 7.5625C6.42444 7.5625 6.07288 7.94922 6.07288 8.40625V12.0273L4.66663 10.6211C4.526 10.4805 4.31506 10.375 4.10413 10.375C3.85803 10.375 3.64709 10.4805 3.50647 10.6211C3.15491 10.9727 3.15491 11.5 3.50647 11.8164L6.31897 14.6289C6.63538 14.9805 7.16272 14.9805 7.47913 14.6289L10.2916 11.8164C10.6432 11.5 10.6432 10.9727 10.2916 10.6211C9.97522 10.3047 9.44788 10.3047 9.13147 10.6211Z"
        fill="#0095CB"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default DownloadIcon;
