import axios from "axios";
import { JsonDecoder } from "ts.data.json";

import {
  rrzCollectionDecoder,
  geographyDecoder,
  geographyListDecoder,
  glossaryDecoder,
  riskToHomesCollectionDecoder,
  vulnerablePopulationTractInfoDecoder,
  wildfireLikelihoodCollectionDecoder,
  headerNavLinkListDecoder
} from "./decoders";
import {
  RRZCollection,
  Geography,
  GeographyLevel,
  GeographyList,
  Glossary,
  RiskToHomesCollection,
  VulnerablePopulationTractInfo,
  WildfireLikelihoodCollection,
  HeaderNavLink
} from "./models";

// Load the glossary data from JSON files
import * as glossaryJson from "./glossary.json";

const glossaryData = (glossaryJson as any).default;

const API_URL = process.env.REACT_APP_API_URL || "";
const NAV_LINK_API_URL = process.env.REACT_APP_NAV_LINK_API_URL || "";
const RRZ_FILENAME = "exposure_type.json";
const RISK_TO_HOMES_FILENAME = "risk_to_homes.json";
const WILDFIRE_LIKELIHOOD_FILENAME = "wildfire_likelihood.json";

async function decodeResponse<T>(decoder: JsonDecoder.Decoder<T>, responseData: any): Promise<T> {
  return decoder.decodeToPromise(responseData);
}

export async function fetchGeography(geoId: string): Promise<Geography> {
  return new Promise((resolve, reject) => {
    const url = `${API_URL}/place/${geoId}`;

    axios
      .get(url)
      .then(response => decodeResponse(geographyDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(`Error retrieving ${url}: ${error}`));
  });
}

export async function fetchGeographyList(search: string): Promise<GeographyList> {
  return new Promise((resolve, reject) => {
    const url = `${API_URL}/places/${search}`;

    axios
      .get(url)
      .then(response =>
        decodeResponse(geographyListDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(`Error retrieving ${url}: ${error}`));
  });
}

export async function fetchGlossary(): Promise<Glossary> {
  return new Promise((resolve, reject) => {
    decodeResponse(glossaryDecoder, glossaryData).then(resolve).catch(reject);
  });
}

// Generic function to do fetching and decoding once a URL and type have been determined.
function fetchAndDecode<T>(url: string, decoder: JsonDecoder.Decoder<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => decodeResponse(decoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(`Error retrieving ${url}: ${error}`));
  });
}

export async function fetchRRZ(level: GeographyLevel, geoId: string): Promise<RRZCollection> {
  const url = `${API_URL}/wildfireData/${geoId}/${level}/${RRZ_FILENAME}`;
  return fetchAndDecode(url, rrzCollectionDecoder);
}

export async function fetchVulnerablePopulations(
  geoId: string
): Promise<VulnerablePopulationTractInfo> {
  const url = `${API_URL}/vulnerablePopulations/${geoId}`;
  return fetchAndDecode(url, vulnerablePopulationTractInfoDecoder);
}

export async function fetchRiskToHomes(
  level: GeographyLevel,
  geoId: string,
  detailGeoId: string,
  detailGeo?: Geography
): Promise<RiskToHomesCollection> {
  const geoIdParam = geoId === "" ? "00" : geoId;
  const compareUrl = `${API_URL}/wildfireData/${geoIdParam}/${level}/${RISK_TO_HOMES_FILENAME}`;
  const compareFetch: Promise<RiskToHomesCollection> = fetchAndDecode(
    compareUrl,
    riskToHomesCollectionDecoder
  );
  // Comparing communities at the national level requires special handling, because the communities
  // are sampled, so it's not guaranteed that the detail view community will be in the data
  // returned. We need to make an additional request at the county level in order to fill in the
  // detail view community's data. The nation has no geoId, so that's why we compare to empty
  // string.
  if (
    level === GeographyLevel.community &&
    geoId === "" &&
    detailGeo &&
    detailGeo.counties &&
    detailGeo.counties.length > 0
  ) {
    // Fetch the county data
    const countyUrl = `${API_URL}/wildfireData/${detailGeo.counties[0].id}/${level}/${RISK_TO_HOMES_FILENAME}`;
    const countyFetch: Promise<RiskToHomesCollection> = fetchAndDecode(
      countyUrl,
      riskToHomesCollectionDecoder
    );
    // Insert the community we're interested in along with the rest.
    return Promise.all([compareFetch, countyFetch]).then(([compareData, countyData]) => {
      return { [detailGeoId]: countyData[detailGeoId], ...compareData };
    });
  } else {
    // If we're not in the specific case of comparing communities at the national level, the detail
    // geography is guaranteed to be within the comparison set, so we can just return the comparison
    // data.
    return compareFetch;
  }
}

export async function fetchWildfireLikelihood(
  level: GeographyLevel,
  geoId: string,
  detailGeoId: string,
  detailGeo?: Geography
): Promise<WildfireLikelihoodCollection> {
  const geoIdParam = geoId === "" ? "00" : geoId;
  const compareUrl = `${API_URL}/wildfireData/${geoIdParam}/${level}/${WILDFIRE_LIKELIHOOD_FILENAME}`;
  const compareFetch: Promise<WildfireLikelihoodCollection> = fetchAndDecode(
    compareUrl,
    wildfireLikelihoodCollectionDecoder
  );
  // See RiskToHomes for an explanation of why there is special handling for the national /
  // community comparison case.
  if (
    level === GeographyLevel.community &&
    geoId === "" &&
    detailGeo &&
    detailGeo.counties &&
    detailGeo.counties.length > 0
  ) {
    // Fetch the county data
    const countyUrl = `${API_URL}/wildfireData/${detailGeo.counties[0].id}/${level}/${WILDFIRE_LIKELIHOOD_FILENAME}`;
    const countyFetch: Promise<WildfireLikelihoodCollection> = fetchAndDecode(
      countyUrl,
      wildfireLikelihoodCollectionDecoder
    );
    // Insert the community we're interested in along with the rest.
    return Promise.all([countyFetch, compareFetch]).then(([countyData, compareData]) => {
      return { [detailGeoId]: countyData[detailGeoId], ...compareData };
    });
  } else {
    return compareFetch;
  }
}

export async function fetchNavLinks(): Promise<ReadonlyArray<HeaderNavLink>> {
  return new Promise((resolve, reject) => {
    axios
      .get(NAV_LINK_API_URL)
      .then(response =>
        decodeResponse(headerNavLinkListDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(`Error retrieving ${NAV_LINK_API_URL}: ${error}`));
  });
}
