import { Loop, LoopReducer } from "redux-loop";
import { Action } from "../actions";
import { ActionTypes } from "../actions/comparisonLevel";
import { ComparisonLevel } from "../models";

export type ComparisonLevelState = ComparisonLevel;

export const initialState: ComparisonLevel = ComparisonLevel.national;

const comparisonLevelReducer: LoopReducer<ComparisonLevelState, Action> = (
  state: ComparisonLevelState = initialState,
  action: Action
): ComparisonLevelState | Loop<ComparisonLevelState, Action> => {
  switch (action.type) {
    case ActionTypes.COMPARISON_LEVEL_SET:
      return action.level;
    default:
      return state;
  }
};

export default comparisonLevelReducer;
