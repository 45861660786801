import React, { useEffect, useMemo, useState } from "react";
import { Box, Paragraph } from "grommet";

import { Geography, RRZ, RRZDataSet } from "../models";
import { RRZState } from "../reducers/riskReductionZone";
import GlossaryLink from "./GlossaryLink";

interface ComponentProps {
  readonly geography: string;
  readonly detailGeographyId: string;
  readonly rrz: RRZState;
}

// this is similar to RRZClass enum
// but should only be used in this component
// to identify source
export enum RRZSource {
  Minimal = "Minimal exposure",
  Indirect = "Indirect exposure",
  Direct = "Direct exposure",
  Mix = "Mix"
}

const getPrimarySource = (rrzData: RRZDataSet) => {
  let primarySource = null;
  // Identify which of the three percentages is the largest
  switch (
    Math.max(
      rrzData.buildings_fraction_de || 0,
      rrzData.buildings_fraction_ie || 0,
      rrzData.buildings_fraction_me || 0
    )
  ) {
    case 0:
      break; // values not loaded yet
    case rrzData.buildings_fraction_de:
      primarySource = RRZSource.Direct;
      break;
    case rrzData.buildings_fraction_ie:
      primarySource = RRZSource.Indirect;
      break;
    case rrzData.buildings_fraction_me:
      if (rrzData.buildings_fraction_me && rrzData.buildings_fraction_me >= 0.5) {
        primarySource = RRZSource.Minimal;
      } else {
        primarySource = RRZSource.Mix;
      }
      break;
    default:
      break; // shouldn't ever get here
  }
  return primarySource;
};

export const getRiskReductionZoneDescription = (
  rrzData: RRZDataSet,
  detailGeography: Geography
) => {
  const primarySource = getPrimarySource(rrzData);
  return `Homes and other buildings in ${detailGeography.name_short} are predominantly ${
    primarySource === RRZSource.Mix ? "exposed to wildfire in a combination of " : "in the "
  } ${
    primarySource === RRZSource.Direct
      ? "Direct Exposure Zone"
      : primarySource === RRZSource.Indirect
      ? "Indirect Exposure Zone"
      : primarySource === RRZSource.Minimal
      ? "Minimal Exposure Zone"
      : primarySource === RRZSource.Mix
      ? "Direct and Indirect Exposure Zones"
      : ""
  }. Learn more and discover resources to reduce risk.`;
};

const RRZComparisonText = (props: ComponentProps) => {
  const { geography, detailGeographyId, rrz } = props;
  const [primarySource, setPrimarySource] = useState<null | RRZSource>(null);

  const chartData: RRZDataSet = useMemo(() => {
    const noData: RRZDataSet = {
      fraction_de: 0,
      fraction_ie: 0,
      fraction_me: 0
    };
    const exp: RRZ =
      "resource" in rrz && rrz.resource.rrz[detailGeographyId]
        ? rrz.resource.rrz[detailGeographyId]
        : { d: noData };
    return exp.d;
  }, [rrz, detailGeographyId]);

  useEffect(() => {
    setPrimarySource(getPrimarySource(chartData));
  }, [chartData]);

  return (
    <>
      {geography && (
        <Box skeleton={primarySource === null}>
          <Paragraph
            size="xxlarge"
            color="var(--text-900) !important"
            style={{ fontWeight: 700 }}
            id="risk-description"
          >
            <GlossaryLink term="homes">Homes</GlossaryLink> and other{" "}
            <GlossaryLink term="buildings">buildings</GlossaryLink> in {geography} are predominantly
            {primarySource === RRZSource.Mix
              ? " exposed to wildfire in a combination of "
              : " in the "}
            {primarySource === RRZSource.Direct ? (
              <GlossaryLink term="direct_exposure_zone">Direct Exposure Zone</GlossaryLink>
            ) : primarySource === RRZSource.Indirect ? (
              <GlossaryLink term="indirect_exposure_zone">Indirect Exposure Zone</GlossaryLink>
            ) : primarySource === RRZSource.Minimal ? (
              <GlossaryLink term="minimal_exposure_zone">Minimal Exposure Zone</GlossaryLink>
            ) : primarySource === RRZSource.Mix ? (
              <>
                <GlossaryLink term="direct_exposure_zone">Direct</GlossaryLink> and{" "}
                <GlossaryLink term="indirect_exposure_zone">Indirect Exposure</GlossaryLink> Zones
              </>
            ) : null}
            .
          </Paragraph>
        </Box>
      )}
    </>
  );
};

export default RRZComparisonText;
