import React, { useMemo } from "react";
import { Box, Text, Heading } from "grommet";
import styled from "styled-components";
import { RRZEmptyHouseIcon, RRZHalfHouseIcon, RRZFullHouseIcon } from "../icons/RRZHouseIcons";
import { RRZHouseIconCount, RRZStructureScale } from "../constants";
import { roundToSigFigs } from "../utils";

enum IconType {
  Full = "full",
  Half = "half",
  Empty = "empty"
}

interface BaseProps {
  readonly label: string;
  readonly themeColor: string;
  readonly strokeColor: string;
  readonly size: string;
}

interface IconProps extends BaseProps {
  readonly idx: number;
  readonly iconType: IconType;
}

interface StateProps extends BaseProps {
  readonly percentage: number;
  readonly structureCount: number;
  readonly structureScale: RRZStructureScale;
  readonly bgColor: string;
}

const ChartItem = styled(Box)`
  border-radius: var(--border-radius);
  &:nth-child(2) {
    margin: 0 auto;
  }
`;

const IconContainer = styled(Box)`
  row-gap: 1rem;
  column-gap: 1rem;
`;

const Icon = ({ label, idx, themeColor, strokeColor, size, iconType }: IconProps) => (
  <Box key={`${label}-cell-${idx}`} justify="center" align="start">
    {iconType === IconType.Full ? (
      <RRZFullHouseIcon color={themeColor} size={size} stroke={strokeColor} />
    ) : iconType === IconType.Half ? (
      <RRZHalfHouseIcon color={themeColor} size={size} stroke={strokeColor} />
    ) : (
      <RRZEmptyHouseIcon color={strokeColor} size={size} stroke={strokeColor} />
    )}
  </Box>
);

const getHouseCounts = (
  pct: number,
  totalIconCount: number,
  structureCount: number
): [number, number, number] => {
  const pctPerIcon = 100 / totalIconCount;
  const fullIconCount = Math.round(pct / pctPerIcon);
  let halfIconCount = Math.ceil(pct / pctPerIcon) - fullIconCount;
  if (fullIconCount === 0 && structureCount > 0) {
    halfIconCount = 1;
  }
  return [fullIconCount, halfIconCount, totalIconCount - fullIconCount - halfIconCount];
};

const createHouseIcons = (
  label: string,
  fullHouses: number,
  halfHouses: number,
  emptyHouses: number,
  themeColor: string,
  strokeColor: string,
  structureScale: RRZStructureScale
): JSX.Element[] => {
  let idx = 0;
  const size = structureScale === RRZStructureScale.Large ? "houseSmall" : "houseMedium";
  const fullHouseIcons = Array.from(Array(fullHouses).keys()).map(_ => {
    idx++;
    return (
      <Icon
        label={label}
        idx={idx}
        key={idx}
        themeColor={themeColor}
        strokeColor={strokeColor}
        size={size}
        iconType={IconType.Full}
      />
    );
  });
  const halfHouseIcons = Array.from(Array(halfHouses).keys()).map(_ => {
    idx++;
    return (
      <Icon
        label={label}
        idx={idx}
        key={idx}
        themeColor={themeColor}
        strokeColor={strokeColor}
        size={size}
        iconType={IconType.Half}
      />
    );
  });
  const emptyHouseIcons = Array.from(Array(emptyHouses).keys()).map(_ => {
    idx++;
    return (
      <Icon
        label={label}
        idx={idx}
        key={idx}
        themeColor={themeColor}
        strokeColor={strokeColor}
        size={size}
        iconType={IconType.Empty}
      />
    );
  });
  return [...fullHouseIcons, ...halfHouseIcons, ...emptyHouseIcons];
};

const RRZChartItem = ({
  label,
  percentage,
  structureCount,
  themeColor,
  strokeColor,
  bgColor,
  structureScale,
  size
}: StateProps) => {
  const houseIconBoxes = useMemo(() => {
    const totalIconCount = RRZHouseIconCount[structureScale];
    const [fullHouses, halfHouses, emptyHouses] = getHouseCounts(
      percentage,
      totalIconCount,
      structureCount
    );
    return createHouseIcons(
      label,
      fullHouses,
      halfHouses,
      emptyHouses,
      themeColor,
      strokeColor,
      structureScale
    );
  }, [structureScale, percentage, themeColor, strokeColor, label, structureCount]);

  const structureCountForDisplay = useMemo(
    () => roundToSigFigs(structureCount, 5).toLocaleString(),
    [structureCount]
  );

  return (
    <ChartItem
      role="listitem"
      pad="small"
      direction="column"
      align="start"
      gap="medium"
      alignSelf="stretch"
      background={bgColor}
      border={[
        {
          color: themeColor,
          size: "0.8rem",
          style: "solid",
          side: "top"
        }
      ]}
      aria-label={`${label}: ${percentage}% of buildings, ${structureCountForDisplay} structures`}
    >
      <Heading level={4} size="1.6rem">
        {label}
      </Heading>
      <Box
        direction="row"
        align="start"
        alignContent="start"
        alignSelf="stretch"
        gap="1.5rem"
        justify="stretch"
        margin={{
          bottom: size === "small" ? "xsmall" : undefined
        }}
      >
        <Box
          direction="column"
          align="start"
          alignSelf="stretch"
          gap="0.1rem"
          flex={{ grow: 1, shrink: 0 }}
          a11yTitle={`${percentage <= 0 ? "< 1" : percentage}% of buildings`}
        >
          <Text size="2.4rem" color="text-900">
            {percentage <= 0 ? "< 1" : percentage}%
          </Text>
          <Text size="small" color="rgba(0, 0, 0, 0.60)">
            of buildings
          </Text>
        </Box>
        {size === "small" && (
          <Box
            direction="column"
            align="start"
            alignSelf="stretch"
            flex={{ grow: 1, shrink: 0 }}
            height="100%"
            a11yTitle={`${structureCountForDisplay} buildings`}
          >
            <Text size="1.8rem" color="text-900" weight={700} margin={{ vertical: "0.4rem" }}>
              {structureCountForDisplay}
            </Text>
            <Text color="rgba(0, 0, 0, 0.60)" size="1.4rem">
              buildings
            </Text>
          </Box>
        )}
      </Box>
      <IconContainer
        wrap={true}
        direction="row"
        justify="start"
        align="start"
        alignContent="start"
        role="img"
        a11yTitle="Visualization: Percentage of buildings in this zone"
      >
        {houseIconBoxes}
      </IconContainer>
      {size !== "small" && (
        <Box
          direction="row"
          align="end"
          alignContent="start"
          gap="xsmall"
          alignSelf="stretch"
          a11yTitle={`${structureCountForDisplay} buildings`}
          wrap={true}
        >
          <Text
            size="1.6rem"
            color="text-900"
            weight={700}
            style={{ display: "inline-block", lineHeight: "1.6rem" }}
          >
            {structureCountForDisplay}
          </Text>
          <Text
            color="rgba(0, 0, 0, 0.60)"
            size="small"
            style={{ display: "inline-block", lineHeight: "1.6rem" }}
          >
            buildings
          </Text>
        </Box>
      )}
    </ChartItem>
  );
};

export default RRZChartItem;
