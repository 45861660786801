import {
  Anchor,
  Box,
  Grid,
  Header,
  Heading,
  Image,
  Paragraph,
  ResponsiveContext,
  Text
} from "grommet";
import React, { useMemo } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

import GeographyDescription from "../components/GeographyDescription";
import Map from "../components/Map";
import { Screen, RiskCardGroup } from "../models";
import { RiskParams } from "../hooks";
import ActionCards from "../components/ActionCards";
import AppFooter from "../components/AppFooter";
import RiskIcon from "../components/RiskIcon";
import AngleRightIcon from "../icons/AngleRightIcon";
import { logGoogleAnalyticsEvent } from "../components/GoogleAnalytics";
import GlossaryComponent from "../components/GlossaryComponent";
import { getCompareDescriptor, getGeographyTypeLabel, getOverviewRpsPctRank } from "../utils";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import PageMeta from "../components/PageMeta";

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid var(--warm-gray-300);
`;

const RiskCard = styled(Box)`
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  &:has(h4 a:focus-visible) {
    outline: 2px solid var(--highlight-700);
    outline-offset: 2px !important;
  }
  @media (hover: hover) {
    &:has(a.card-link:hover) {
      background: var(--warm-gray-200);
    }
    &:has(a.card-link:hover) .map-container {
      -webkit-filter: brightness(0.85);
      filter: brightness(0.85);
    }
    &:has(a.card-link:hover):active {
      opacity: 0.6;
    }
  }
`;

const RiskCardLink = styled(Link)`
  font-size: 2.8rem;
  font-weight: 800;
  color: black;
  text-decoration: none;
  &:focus-visible {
    outline: none !important;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:active {
    opacity: 1 !important;
  }
`;

type OverviewRiskParams = Pick<
  RiskParams,
  | "geography"
  | "detailGeography"
  | "detailGeographyId"
  | "countyGeographies"
  | "stateGeography"
  | "geographyLevel"
  | "mapStyleStateAbbrev"
  | "vulnerablePopulations"
>;

const OverviewScreen = ({
  history,
  geography,
  detailGeographyId,
  detailGeography,
  countyGeographies,
  stateGeography,
  geographyLevel,
  mapStyleStateAbbrev,
  vulnerablePopulations
}: OverviewRiskParams & RouteComponentProps<"history">) => {
  const riskCardGroups: ReadonlyArray<RiskCardGroup> = [
    {
      groupLabel: "Understand your risk",
      riskCards: [
        {
          cardLabel: "Risk to Homes",
          screen: Screen.RiskToHomes,
          text: "Where are homes at risk of wildfire?",
          riskLevel: detailGeography?.risk_to_homes_class
        },
        {
          cardLabel: "Wildfire Likelihood",
          screen: Screen.WildfireLikelihood,
          text: "How likely is a wildfire in this area?",
          riskLevel: detailGeography?.wildfire_likelihood_class
        }
      ]
    },
    {
      groupLabel: "Reduce your risk",
      riskCards: [
        {
          cardLabel: "Risk Reduction Zones",
          screen: Screen.RiskReductionZones,
          text: "Which actions are most effective to reduce risk?",
          riskLevel: detailGeography?.exposure_type_class
        },
        {
          cardLabel: "Vulnerable Populations",
          screen: Screen.VulnerablePopulations,
          text: "Who is most at risk and how do you reach them?",
          riskLevel: detailGeography?.vulnerable_populations_class
        }
      ]
    }
  ];

  const RiskCards = riskCardGroups.map((group, index) => {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box as="section" pad={{ top: "medium", bottom: "medium" }} gap="large" key={index}>
            <Heading
              level={3}
              size={size === "small" ? "2.1rem" : undefined}
              weight={400}
              color="var(--text-700) !important"
              style={{ padding: size === "small" ? "0 1rem" : undefined }}
            >
              {group.groupLabel}
            </Heading>
            <Box gap={size !== "small" ? "large" : "4rem"} role="list">
              {group.riskCards.map((card, index) => (
                <RiskCard background="white" role="listitem" key={index}>
                  <Box
                    pad={{ horizontal: "medium", vertical: "1.5rem" }}
                    direction="row"
                    gap="medium"
                    align="center"
                    justify="between"
                  >
                    <Box gap=".2rem">
                      <Heading level={4} size="xlarge">
                        <RiskCardLink
                          className="card-link"
                          to={history.location.pathname.replace(Screen.Overview, card.screen)}
                          onClick={() => {
                            logGoogleAnalyticsEvent("overview", "risk card click", card.cardLabel);
                          }}
                        >
                          {card.cardLabel}
                        </RiskCardLink>
                      </Heading>
                      <Text color="text-700" size="1.8rem">
                        {card.text}
                      </Text>
                    </Box>
                    <RiskIcon riskLevel={card.riskLevel} size={size === "small" ? 50 : undefined} />
                  </Box>

                  {detailGeography && (
                    <Box
                      height={"23rem"}
                      className="map-container"
                      aria-hidden
                      style={{
                        pointerEvents: "none"
                      }}
                    >
                      <Map
                        geographyLevel={geographyLevel}
                        detailPlaceId={detailGeographyId}
                        geography={detailGeography}
                        mapStyleStateAbbrev={"US"}
                        stateGeography={stateGeography}
                        screen={card.screen}
                        showPopulatedAreaMask={false}
                        mapContainerId={`map-container-${card.screen}`}
                        displayControlsAndLabels={false}
                        vulnerablePopulations={
                          card.screen === Screen.VulnerablePopulations
                            ? vulnerablePopulations
                            : undefined
                        }
                      />
                    </Box>
                  )}
                </RiskCard>
              ))}
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  });

  const ConnectedText = () => {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            background="warm-gray-900"
            alignSelf="end"
            pad="2rem"
            gap="small"
            style={
              size === "small"
                ? {}
                : {
                    borderRadius: "var(--border-radius)",
                    width: "50%"
                  }
            }
          >
            <Heading level={3} style={{ color: "white" }}>
              Learn how it's all connected
            </Heading>
            <Paragraph
              size="xlarge"
              style={{
                color: "var(--warm-gray-400)",
                marginBottom: size === "small" ? "2rem" : "4rem"
              }}
            >
              For a community to be fire-adapted, we must integrate risk-reduction strategies into
              the design, layout, and development of homes and neighborhoods.
            </Paragraph>
            <Anchor href="/reduce-risk" className="button-link large">
              Learn how to reduce risk
              <AngleRightIcon size="14px" color="var(--highlight-700)" aria-hidden />
            </Anchor>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  const pageDescription = useMemo(() => {
    if (!detailGeography) return "";
    const rpsRank = detailGeography.ranks?.rps_within_nation;
    const rpsRankClass = detailGeography.risk_to_homes_class;
    const rpsPctRank = getOverviewRpsPctRank(rpsRank, rpsRankClass);
    const compareDescriptor = getCompareDescriptor(rpsRank, rpsRankClass);
    const geographyTypeLabel = getGeographyTypeLabel(geographyLevel);
    return (
      `${detailGeography.name_short} has a ${rpsRankClass} ` +
      `risk of wildfire—${compareDescriptor} than ${rpsPctRank}% ` +
      `of ${geographyTypeLabel} in the US. Learn more and discover ` +
      "resources to reduce risk."
    );
  }, [detailGeography, geographyLevel]);

  const pageTitle = useMemo(() => {
    if (!detailGeography) return "";
    return `Wildfire Risk in ${detailGeography.name}`;
  }, [detailGeography]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <PageMeta title={pageTitle} description={pageDescription} />
          <Box as="main" id="main-content" tabIndex={-1} height={{ min: "100vh" }}>
            {"resource" in geography ? (
              <>
                <StyledHeader
                  direction="column"
                  gap="none"
                  pad={{
                    horizontal: "2rem",
                    vertical: size === "small" ? "medium" : "small"
                  }}
                >
                  <Box
                    className="restrict-growth-content-container"
                    width="100%"
                    pad={{
                      vertical: "xsmall"
                    }}
                    gap={size !== "small" ? "4rem" : "2rem"}
                  >
                    <GeographyDescription
                      countyDescNames={
                        countyGeographies ? countyGeographies.map(c => c.name_short) : []
                      }
                      stateDescName={stateGeography ? stateGeography.name : ""}
                      geographyLevel={geographyLevel}
                      detailPlaceId={detailGeographyId}
                      detailPlaceName={detailGeography ? detailGeography.name_short : ""}
                      rpsRank={detailGeography?.ranks?.rps_within_nation}
                      rpsRankClass={detailGeography?.risk_to_homes_class}
                    />
                    <Box direction="row" align="center" gap="xsmall">
                      <ArrowDownIcon aria-hidden size="10px" />
                      <Text size="medium" color="text-700">
                        Choose from the topics below to learn more
                      </Text>
                    </Box>
                  </Box>
                </StyledHeader>

                <Grid
                  alignSelf="center"
                  columns={size === "large" ? ["1fr", "1fr"] : ["auto"]}
                  gap={size !== "small" ? "large" : "4rem"}
                  justify="stretch"
                  justifyContent="stretch"
                  margin={{
                    top: "large",
                    bottom: "xlarge"
                  }}
                  pad={{
                    horizontal: "medium"
                  }}
                  rows="auto"
                  width={{ max: "1140px" }}
                >
                  {RiskCards}
                  <ActionCards />
                </Grid>

                {size === "small" ? (
                  <Box
                    style={{
                      overflowY: "hidden",
                      position: "relative",
                      flexShrink: 0
                    }}
                  >
                    <ConnectedText />
                    <Box
                      height="220px"
                      style={{
                        background: "url(" + process.env.PUBLIC_URL + "/Town.jpg)",
                        backgroundPosition: "center 25%",
                        backgroundSize: "cover"
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    height={{ max: "600px" }}
                    style={{
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "relative",
                      flexShrink: 0,
                      justifyContent: "center"
                    }}
                  >
                    <Image
                      src={process.env.PUBLIC_URL + "/Town.jpg"}
                      alt="Image of a town experiencing a wildfire"
                    />
                    <Box
                      style={{
                        position: "absolute",
                        alignSelf: "center",
                        maxWidth: "1100px"
                      }}
                      margin={size === "small" ? "" : "medium"}
                    >
                      <ConnectedText />
                    </Box>
                  </Box>
                )}
              </>
            ) : "errorMessage" in geography ? (
              geography.errorMessage
            ) : null}
          </Box>
          <AppFooter />
          <GlossaryComponent />
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default withRouter(OverviewScreen);
