import React from "react";
import { Box, Grid, Grommet, Heading, Text } from "grommet";
import CustomGrommetTheme from "../grommet-theme";
import { createRoot } from "react-dom/client";
import styled from "styled-components";
import { ReactIControl } from "./Map";
import { RampLegend, ClassLegend } from "../models";
import MapClassLegend from "./MapClassLegend";

const Swatch = styled(Box)`
  position: relative;
  &:first-child::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const SwatchLabel = styled(Text)`
  line-height: 1;
`;

// Not all legends will be present at all times, so they're all optional
export interface MapLegendComponentProps {
  readonly rampLegend?: RampLegend;
  readonly classLegend?: ClassLegend;
  readonly isVulPopNarrowScreen?: boolean;
  readonly noPad?: boolean;
  readonly noLabel?: boolean;
  readonly compact?: boolean;
}

export const MapLegendComponent = (props: MapLegendComponentProps) => {
  const { rampLegend, classLegend, noPad, noLabel, compact } = props;

  return (
    <Grommet theme={CustomGrommetTheme} options={{ box: { cssGap: true } }}>
      {rampLegend && (
        <Box pad={noPad ? "0" : "small"} gap="xsmall">
          {!noLabel && <Heading level={4}>{rampLegend.legendLabel}</Heading>}
          <Grid
            columns={{ count: rampLegend.colors.length, size: "auto" }}
            fill={true}
            gap="1px"
            height="1rem"
            style={{
              borderRadius: "var(--border-radius)",
              marginTop: ".25rem",
              overflow: "hidden"
            }}
          >
            {rampLegend.colors.map((color, idx) => (
              <Swatch key={idx} background={color} />
            ))}
          </Grid>
          <Grid rows={["fill"]} columns={["auto", "auto", "auto"]} gap=".5rem">
            <Box direction="row">
              <SwatchLabel size="small">{rampLegend.lowLabel}</SwatchLabel>
            </Box>
            <Box direction="row" justify="center">
              <SwatchLabel size="small">{rampLegend.midLabel}</SwatchLabel>
            </Box>
            <Box direction="row" justify="end">
              <SwatchLabel size="small">{rampLegend.highLabel}</SwatchLabel>
            </Box>
          </Grid>
        </Box>
      )}
      {classLegend && <MapClassLegend classes={classLegend.classes} compact={compact || false} />}
    </Grommet>
  );
};

const MapLegend = (el: HTMLDivElement, props: MapLegendComponentProps): ReactIControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(<MapLegendComponent {...props} />);
      return el;
    },
    onRemove: () => {
      root.render(undefined);
    },
    // This is not part of the IControl interface; this is here so we can re-render when the props
    // change.
    render: (props: MapLegendComponentProps) => {
      el.style.marginTop = "10px";
      root.render(<MapLegendComponent {...props} />);
      return el;
    }
  };
};

export default MapLegend;
