import { Geography, GeographyLevel, WildfireLikelihoodCollection } from "../models";

export enum ActionTypes {
  WILDFIRE_LIKELIHOOD_FETCH = "WILDFIRE_LIKELIHOOD_FETCH",
  WILDFIRE_LIKELIHOOD_FETCH_SUCCESS = "WILDFIRE_LIKELIHOOD_FETCH_SUCCESS",
  WILDFIRE_LIKELIHOOD_FETCH_FAILURE = "WILDFIRE_LIKELIHOOD_FETCH_FAILURE"
}

// There are separate actions for making the API request and handling both its
// success and failure scenarios
export type WildfireLikelihoodCollectionAction =
  | {
      readonly type: ActionTypes.WILDFIRE_LIKELIHOOD_FETCH;
      readonly level: GeographyLevel;
      readonly geoId: string;
      readonly detailGeoId: string;
      readonly detailGeo?: Geography;
    }
  | {
      readonly type: ActionTypes.WILDFIRE_LIKELIHOOD_FETCH_SUCCESS;
      readonly wildfireLikelihood: WildfireLikelihoodCollection;
    }
  | { readonly type: ActionTypes.WILDFIRE_LIKELIHOOD_FETCH_FAILURE; readonly errorMessage: string };

export function wildfireLikelihoodFetch(
  level: GeographyLevel,
  geoId: string,
  detailGeoId: string,
  detailGeo?: Geography
): WildfireLikelihoodCollectionAction {
  return {
    level,
    geoId,
    detailGeoId,
    detailGeo,
    type: ActionTypes.WILDFIRE_LIKELIHOOD_FETCH
  };
}

export function wildfireLikelihoodFetchSuccess(
  wildfireLikelihood: WildfireLikelihoodCollection
): WildfireLikelihoodCollectionAction {
  return {
    type: ActionTypes.WILDFIRE_LIKELIHOOD_FETCH_SUCCESS,
    wildfireLikelihood
  };
}

export function wildfireLikelihoodFetchFailure(
  errorMessage: string
): WildfireLikelihoodCollectionAction {
  return {
    type: ActionTypes.WILDFIRE_LIKELIHOOD_FETCH_FAILURE,
    errorMessage
  };
}
