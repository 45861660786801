import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const PlusIconSmall = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 11 11" a11yTitle="Plus" {...props}>
      <path
        d="M6.0625 1.1875V4.9375H9.8125H10.375V6.0625H9.8125H6.0625V9.8125V10.375H4.9375V9.8125V6.0625H1.1875H0.625V4.9375H1.1875H4.9375V1.1875V0.625H6.0625V1.1875Z"
        fill={props.color}
        stroke="none"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default PlusIconSmall;
