import { Box, Grommet, Heading, Text, Select } from "grommet";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BaseMapLayer } from "../models";
import { setSelectedBaseMapLayer } from "../actions/vulnerablePopulations";
import CaretIcon from "../icons/CaretIcon";
import { ReactIControl } from "./Map";
import CustomGrommetTheme from "../grommet-theme";
import { MapLegendComponent, MapLegendComponentProps } from "./MapLegend";

import store, { useAppSelector } from "../store";
import mapboxgl from "mapbox-gl";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const PLACEHOLDER = "Select a map";

interface Props {
  readonly width?: string;
}

export const MapSelectorComponent = ({
  width,
  ...legendProps
}: Props & MapLegendComponentProps) => {
  const selectedBaseMapLayer = useAppSelector(
    state => state.vulnerablePopulations.selectedBaseMapLayer
  );
  const setSelectedBaseMap = (selectedBaseMapLayer: BaseMapLayer) => {
    store.dispatch(setSelectedBaseMapLayer(selectedBaseMapLayer));
  };

  const selectorLegendProps = {
    ...legendProps,
    noPad: true,
    noLabel: true,
    compact: true
  };

  return (
    <Grommet theme={CustomGrommetTheme} options={{ box: { cssGap: true } }}>
      <Box pad="small" width={width || "20rem"} gap="small">
        <Heading level={4}>Show wildfire risk data</Heading>
        <Select
          options={Object.values(BaseMapLayer)}
          value={selectedBaseMapLayer}
          valueLabel={
            selectedBaseMapLayer === BaseMapLayer.none ? (
              <Text size="small" color="text-700">
                {PLACEHOLDER}
              </Text>
            ) : (
              <Text size="small" weight="bold" color="black">
                {selectedBaseMapLayer}
              </Text>
            )
          }
          placeholder={PLACEHOLDER}
          onChange={option => {
            logGoogleAnalyticsEvent("vulnerable populations", "basemap selection", option.value);
            setSelectedBaseMap(option.value);
          }}
          icon={<CaretIcon size="10px" />}
        />
        {selectedBaseMapLayer !== BaseMapLayer.none && (
          <MapLegendComponent {...selectorLegendProps} />
        )}
      </Box>
    </Grommet>
  );
};

interface ComponentProps {
  readonly isVulPopNarrowScreen?: boolean;
}

const VulnerablePopulationsMapSelector = (
  el: HTMLDivElement,
  props?: ComponentProps
): ReactIControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      // Reset display in case it was hidden earlier by onRemove
      el.style.display = "";
      root.render(
        <Provider store={store}>
          <MapSelectorComponent {...props} />
        </Provider>
      );
      return el;
    },
    onRemove: () => {
      // Hide the element completely so it doesn't affect the spacing of other controls
      el.style.display = "none";
      root.render(undefined);
    },
    // This is not part of the IControl interface; this is here so we can re-render when the props
    // change.
    render: (props: ComponentProps) => {
      root.render(
        <Provider store={store}>
          <MapSelectorComponent {...props} />
        </Provider>
      );
      return el;
    }
  };
};

export default VulnerablePopulationsMapSelector;
