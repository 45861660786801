import React from "react";
import { Anchor, Box, Paragraph, ResponsiveContext, Heading } from "grommet";
import BookIcon from "../icons/BookIcon";
import AngleRightIcon from "../icons/AngleRightIcon";
import { Screen } from "../models";
import GlossaryLink from "./GlossaryLink";

const ContentParagraph = ({ children }: { readonly children: React.ReactNode }) => (
  <Paragraph size="xlarge">{children}</Paragraph>
);

const Content = ({ screen }: { readonly screen: Screen }) =>
  screen === Screen.WildfireLikelihood ? (
    <>
      <ContentParagraph>
        <GlossaryLink term="wildfire_likelihood">Wildfire likelihood</GlossaryLink> is the
        probability of a wildfire occurring based on fire behavior modeling across thousands of
        simulations of possible fire seasons.
      </ContentParagraph>
      <ContentParagraph>
        In each simulation, factors contributing to the probability of a fire occurring—including
        weather, topography, and ignitions—are varied based on patterns derived from observations in
        recent decades.
      </ContentParagraph>
      <ContentParagraph>
        Wildfire likelihood is not predictive and does not reflect any currently forecasted weather
        or fire danger conditions.
      </ContentParagraph>
      <ContentParagraph>
        For <GlossaryLink term="communities">communities</GlossaryLink>,{" "}
        <GlossaryLink term="tribal_areas">tribal areas</GlossaryLink>, and counties, Wildfire
        Likelihood is summarized and ranked for the{" "}
        <GlossaryLink term="risk_calculation_area">risk calculation area</GlossaryLink>. This
        includes a 2.4 km buffer around{" "}
        <GlossaryLink term="populated_areas">populated areas</GlossaryLink> to incorporate the risk
        of embers.
      </ContentParagraph>
    </>
  ) : screen === Screen.RiskReductionZones ? (
    <>
      <ContentParagraph>
        <GlossaryLink term="risk_reduction_zones">Risk Reduction Zones</GlossaryLink> are based on
        the interplay between{" "}
        <GlossaryLink term="wildfire_likelihood">wildfire likelihood</GlossaryLink>, flammable
        vegetation, and <GlossaryLink term="populated_areas">populated areas</GlossaryLink>.
        Wildfires can start in any zone and pose a risk to homes and communities.
      </ContentParagraph>
      <ContentParagraph>
        We calculate the number of <GlossaryLink term="buildings">buildings</GlossaryLink> in each
        Risk Reduction Zone based on building footprints within the political boundary of the
        selected location.
      </ContentParagraph>
    </>
  ) : screen === Screen.RiskToHomes ? (
    <>
      <ContentParagraph>
        The <GlossaryLink term="risk_to_homes">Risk to Homes</GlossaryLink> data integrate{" "}
        <GlossaryLink term="wildfire_likelihood">wildfire likelihood</GlossaryLink> and{" "}
        <GlossaryLink term="wildfire_intensity">wildfire intensity</GlossaryLink> from simulation
        modeling. Together, wildfire likelihood and intensity represent hazard. To translate this
        into terms specific to the effect of fire on homes, Wildfire Risk to Communities uses a
        generalized concept of <GlossaryLink term="susceptibility">susceptibility</GlossaryLink> for
        all homes. In other words, Wildfire Risk to Communities assumes all homes that encounter
        wildfire will be damaged, and the degree of damage is directly related to wildfire
        intensity.
      </ContentParagraph>
      <ContentParagraph>
        Wildfire Risk to Communities does not account for homes that may have been mitigated.
      </ContentParagraph>
      <ContentParagraph>
        For <GlossaryLink term="communities">communities</GlossaryLink>,{" "}
        <GlossaryLink term="tribal_areas">tribal areas</GlossaryLink>, and counties, Risk to Homes
        is summarized and ranked for the{" "}
        <GlossaryLink term="risk_calculation_area">risk calculation area</GlossaryLink>. This
        includes a 2.4 km buffer around{" "}
        <GlossaryLink term="populated_areas">populated areas</GlossaryLink> to incorporate the risk
        of embers.
      </ContentParagraph>
    </>
  ) : screen === Screen.VulnerablePopulations ? (
    <>
      <ContentParagraph>
        Data about potentially vulnerable populations are shown at the neighborhood level so that
        leaders can direct resources within a community. Census tracts are highlighted that have
        values equal to or greater than the community median.
      </ContentParagraph>
      <ContentParagraph>
        The two variables that are most significant for selected location are shown automatically
        and other variables can be toggled on and off.
      </ContentParagraph>
      <ContentParagraph>
        Data are from the U.S. Census Bureau’s American Community Survey.
      </ContentParagraph>
    </>
  ) : null;

const HowWeCalculate = ({ screen }: { readonly screen: Screen }) => {
  const heading =
    screen === Screen.WildfireLikelihood
      ? "wildfire likelihood"
      : screen === Screen.RiskReductionZones
      ? "risk reduction zones"
      : screen === Screen.RiskToHomes
      ? "risk to homes"
      : screen === Screen.VulnerablePopulations
      ? "vulnerable populations"
      : "";

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction="column"
          justify="between"
          pad={{ horizontal: "medium", bottom: "medium" }}
          className="content-box"
        >
          <Heading level={3}>{"How we calculate " + heading}</Heading>
          <Box fill={true} style={{ display: "flex", gap: "1rem" }}>
            <Content screen={screen} />
          </Box>
          <Anchor
            href="/about/methods/"
            style={{
              textDecoration: "none",
              marginTop: "3rem"
            }}
            target="_blank"
            rel="noreferrer"
            className="button-link large"
          >
            <span>
              <BookIcon color="#0095CB" size="small" aria-hidden />
              Learn about our methodology
            </span>
            <AngleRightIcon size="14px" color="var(--highlight-700)" aria-hidden />
          </Anchor>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default HowWeCalculate;
