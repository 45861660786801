import { ComparisonLevel } from "../models";

export enum ActionTypes {
  COMPARISON_LEVEL_SET = "COMPARISON_LEVEL_SET"
}

export type ComparisonLevelAction = {
  readonly type: ActionTypes.COMPARISON_LEVEL_SET;
  readonly level: ComparisonLevel;
};

export function setComparisonLevel(level: ComparisonLevel): ComparisonLevelAction {
  return {
    type: ActionTypes.COMPARISON_LEVEL_SET,
    level
  };
}
