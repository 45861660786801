import { Paragraph } from "grommet";
import React, { useMemo } from "react";
import { ComparisonLevel, Geography, GeographyLevel, Ranks, Screen } from "../models";
import { PercentileComparisonText } from "./PercentileComparisonText";
import { getCompareGeoName, getDetailScreenGeoTypeLabel } from "../utils";

interface ComponentProps {
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly ranks: Ranks | undefined;
}

const getRpsPctRank = (ranks: Ranks, level: ComparisonLevel) => {
  let pct = 0;
  if (level === ComparisonLevel.county && ranks.rps_within_county !== undefined) {
    pct = ranks.rps_within_county;
  } else if (level === ComparisonLevel.state && ranks.rps_within_state !== undefined) {
    pct = ranks.rps_within_state;
  } else {
    pct = ranks.rps_within_nation;
  }
  return Math.round(pct * 100);
};

export const getRiskToHomesDescription = (
  detailGeography: Geography,
  compareLevel: ComparisonLevel,
  geoLevel: GeographyLevel,
  compareCounty: string,
  compareState: string,
  ranks: Ranks | undefined
) => {
  const riskClass = detailGeography.risk_to_homes_class;
  const location = detailGeography.name_short;
  const rpsPctRank = !ranks ? undefined : getRpsPctRank(ranks, compareLevel);
  const geoName = getCompareGeoName(compareLevel, compareCounty, compareState);
  const { label, extraText } = getDetailScreenGeoTypeLabel(geoLevel, geoName);
  if (rpsPctRank === undefined) return "";
  if (rpsPctRank > 5) {
    return (
      `${
        riskClass ? riskClass.charAt(0).toUpperCase() + riskClass.slice(1) : ""
      } risk: Homes in ${location} have, ` +
      `on average, greater risk than ${rpsPctRank}% of ` +
      `${label + extraText} ${geoName ? "in " + geoName : ""}. ` +
      "Learn more and discover resources to reduce risk."
    );
  }
  return (
    `${
      riskClass ? riskClass.charAt(0).toUpperCase() + riskClass.slice(1) : ""
    } risk: Homes in nearly all other ${geoName} ${label + extraText}, ` +
    `on average, have greater risk than in ${location}. ` +
    "Learn more and discover resources to reduce risk."
  );
};

const RiskToHomesComparisonText = (props: ComponentProps) => {
  const {
    geography,
    geographyLevel,
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    ranks
  } = props;

  const comparisonText = useMemo(() => {
    const rpsPctRank = !ranks ? undefined : getRpsPctRank(ranks, comparisonLevel);
    const comparisonGeographyName = getCompareGeoName(
      comparisonLevel,
      comparisonCountyName,
      comparisonStateName
    );

    return rpsPctRank === undefined ? (
      // Avoids possibly flashing the wrong text while rpsPctRank is being recalculated
      <></>
    ) : (
      <PercentileComparisonText
        screen={Screen.RiskToHomes}
        comparisonGeographyName={comparisonGeographyName}
        geography={geography}
        geographyLevel={geographyLevel}
        percentileRank={rpsPctRank}
        quantityLabel={<>risk</>}
      />
    );
  }, [
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    geographyLevel,
    geography,
    ranks
  ]);

  return (
    <>
      {geography && (
        <Paragraph
          size="xxlarge"
          color="var(--text-900) !important"
          style={{ fontWeight: 700 }}
          id="risk-description"
        >
          {comparisonText}
        </Paragraph>
      )}
    </>
  );
};

export default RiskToHomesComparisonText;
