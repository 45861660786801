import React from "react";
import { Box, Heading, Paragraph } from "grommet";
import { RasterClass } from "../models";
import styled from "styled-components";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 24rem;

  &.compact {
    width: 18rem;
    padding: 0.6rem 1rem;
  }
`;

const Item = styled(Box)`
  border-top: 0.1rem solid var(--warm-gray-300);
  display: flex;
  flex-direction: row;
  padding: 1rem;

  &:first-child {
    border-top: none;
  }

  &.compact {
    border-top: none;
    padding: 0.5rem 0;
  }
`;

const Swatch = styled(Box)<{ bgColor: string }>`
  background-color: ${props => props.bgColor};
  border-radius: var(--border-radius);
  flex: 0 0 0.8rem;

  &.compact {
    flex: 0 0 1.4rem;
    margin: 2px 0;
  }
`;

const TextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  gap: 0.5rem;
`;

const MapClassLegend = ({
  classes,
  compact
}: {
  classes: ReadonlyArray<RasterClass>;
  compact: boolean;
}) => (
  <Container className={compact ? "compact" : ""}>
    {classes.map(({ label, color, definition }, idx) => (
      <Item key={idx} className={compact ? "compact" : ""}>
        <Swatch bgColor={color} className={compact ? "compact" : ""} />
        <TextBox>
          <Heading level={4}>{label}</Heading>
          {!compact && <Paragraph size="small">{definition}</Paragraph>}
        </TextBox>
      </Item>
    ))}
  </Container>
);

export default MapClassLegend;
