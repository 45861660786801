import { Glossary } from "../models";

export enum GlossaryActionTypes {
  GLOSSARY_FETCH = "GLOSSARY_FETCH",
  GLOSSARY_FETCH_SUCCESS = "GLOSSARY_FETCH_SUCCESS",
  GLOSSARY_FETCH_FAILURE = "GLOSSARY_FETCH_FAILURE"
}

export type GlossaryObjectAction =
  | { readonly type: GlossaryActionTypes.GLOSSARY_FETCH }
  | { readonly type: GlossaryActionTypes.GLOSSARY_FETCH_SUCCESS; readonly glossary: Glossary }
  | { readonly type: GlossaryActionTypes.GLOSSARY_FETCH_FAILURE; readonly errorMessage: string };

export function glossaryFetch(): GlossaryObjectAction {
  return {
    type: GlossaryActionTypes.GLOSSARY_FETCH
  };
}

export function glossaryFetchSuccess(glossary: Glossary): GlossaryObjectAction {
  return {
    type: GlossaryActionTypes.GLOSSARY_FETCH_SUCCESS,
    glossary
  };
}

export function glossaryFetchFailure(errorMessage: string): GlossaryObjectAction {
  return {
    type: GlossaryActionTypes.GLOSSARY_FETCH_FAILURE,
    errorMessage
  };
}
