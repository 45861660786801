import React, { useEffect, useMemo, useRef } from "react";
import { Box, Button, Grommet, Heading } from "grommet";
import { createRoot } from "react-dom/client";
import { connect, Provider } from "react-redux";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";

import CustomGrommetTheme from "../grommet-theme";
import CloseIcon from "../icons/CloseIcon";
import { State } from "../reducers";
import store from "../store";
import { setSelectedPanelTract } from "../actions/vulnerablePopulations";
import { VulnerablePopulationNameAndData, VulnerablePopulationTractDictionary } from "../models";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { clearTractHighlighting } from "./mapListeners";
import VulnerablePopulationsTable from "./VulnerablePopulationsTable";

const CloseButton = styled(Button)`
  background: var(--warm-gray-200) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  justify-content: center !important;
  display: inline-flex !important;
  flex-shrink: 0;
  border-radius: var(--border-radius) !important;
  &:hover {
    background: var(--warm-gray-300) !important;
  }
`;

interface StateProps {
  readonly vulnerablePopulations: VulnerablePopulationsState;
}

interface Props extends StateProps {
  readonly map: mapboxgl.Map;
}

const Panel = ({ vulnerablePopulations, map }: Props) => {
  const { selectedPanelTract } = vulnerablePopulations;
  const ref = useRef<HTMLDivElement>(null);

  // Store prior focus to return it when panel is closed
  const previousFocus = useMemo(() => {
    return document.activeElement;
  }, []);

  useEffect(() => {
    // Focus modal when it's first mounted
    ref.current && ref.current.focus();
  }, [ref]);

  const closeTractPanel = () => {
    clearTractHighlighting(map);
    store.dispatch(setSelectedPanelTract(undefined));
    previousFocus instanceof HTMLElement && previousFocus.focus();
  };

  const geos =
    "resource" in vulnerablePopulations.tractInfo &&
    vulnerablePopulations.tractInfo.resource.tractInfo.geos;
  const intersectingTracts =
    geos && geos.tracts && (geos.tracts as VulnerablePopulationTractDictionary);
  const geosData =
    geos && selectedPanelTract && geos[selectedPanelTract] ? geos[selectedPanelTract] : undefined;
  const modalTract =
    geos &&
    geos.tracts &&
    (geos.tracts[selectedPanelTract as keyof typeof geosData] as VulnerablePopulationNameAndData);
  const headerTract = intersectingTracts[selectedPanelTract as keyof typeof intersectingTracts];

  return (
    <Grommet
      theme={CustomGrommetTheme}
      style={{ borderRadius: "2px" }}
      options={{ box: { cssGap: true } }}
    >
      <Box width="37rem" margin="1rem" gap="small" role="group" ref={ref} tabIndex={-1}>
        <Box direction="row" justify="between" gap="medium">
          <Heading level={4} style={{ fontSize: "1.5rem" }} alignSelf="center">
            {headerTract && headerTract.name}
          </Heading>
          <CloseButton
            onClick={closeTractPanel}
            color="text-700"
            icon={<CloseIcon size="11px" fill="var(--text-800)" aria-hidden />}
            a11yTitle="Close detail table"
            size="small"
          />
        </Box>
        {modalTract && (
          <VulnerablePopulationsTable
            tableData={modalTract.data}
            detailPlaceId={selectedPanelTract}
            mode="panel"
          />
        )}
      </Box>
    </Grommet>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  vulnerablePopulations: state.vulnerablePopulations
});

const PanelComponent = connect(mapStateToProps)(Panel);

const VulnerablePopulationsTractDetailPanel = (el: HTMLDivElement): mapboxgl.IControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(
        <Provider store={store}>
          <PanelComponent map={map} />
        </Provider>
      );
      return el;
    },
    onRemove: () => {
      root.render(undefined);
    }
  };
};

export default VulnerablePopulationsTractDetailPanel;
