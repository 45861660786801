import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { GlossaryAction } from "../actions";
import {
  GlossaryActionTypes,
  glossaryFetchFailure,
  glossaryFetchSuccess
} from "../actions/glossary";

import { fetchGlossary } from "../api";
import { GlossaryResource } from "../models";
import { Resource } from "../resource";

export type GlossaryState = Resource<GlossaryResource>;

export const initialState: GlossaryState = {
  isPending: false
};

const glossaryReducer: LoopReducer<GlossaryState, GlossaryAction> = (
  state: GlossaryState = initialState,
  action: GlossaryAction
): GlossaryState | Loop<GlossaryState, GlossaryAction> => {
  switch (action.type) {
    case GlossaryActionTypes.GLOSSARY_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchGlossary, {
          successActionCreator: glossaryFetchSuccess,
          failActionCreator: glossaryFetchFailure
        })
      );
    case GlossaryActionTypes.GLOSSARY_FETCH_SUCCESS:
      return {
        resource: {
          glossary: action.glossary
        }
      };
    case GlossaryActionTypes.GLOSSARY_FETCH_FAILURE:
      return {
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default glossaryReducer;
