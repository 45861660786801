import React, { useMemo } from "react";
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";

import { RRZ, RRZDataSet } from "../models";
import { RRZState } from "../reducers/riskReductionZone";
import RRZChart from "./RiskReductionZoneChart";
import { RRZStructureScale } from "../constants";
import GlossaryLink from "./GlossaryLink";

interface ComponentProps {
  readonly geography: string;
  readonly detailPlaceId: string;
  readonly rrz: RRZState;
}

const RRZComponent = (props: ComponentProps) => {
  const { geography, detailPlaceId, rrz } = props;

  const chartData: RRZDataSet = useMemo(() => {
    const noData: RRZDataSet = {
      fraction_de: 0,
      fraction_ie: 0,
      fraction_me: 0,
      fraction_wtz: 0,
      total_buildings: 0,
      buildings_fraction_me: 0,
      buildings_fraction_ie: 0,
      buildings_fraction_de: 0
    };

    const exp: RRZ =
      "resource" in rrz && rrz.resource.rrz[detailPlaceId]
        ? rrz.resource.rrz[detailPlaceId]
        : { d: noData };
    return exp.d;
  }, [rrz, detailPlaceId]);

  const descriptionText = (
    <>
      <Paragraph color="text" size="xlarge">
        <GlossaryLink term="risk_reduction_zones">Risk Reduction Zones</GlossaryLink> are the areas
        where mitigation activities will be most effective at protecting{" "}
        <GlossaryLink term="homes">homes</GlossaryLink> and other{" "}
        <GlossaryLink term="buildings">buildings</GlossaryLink> from wildfires.
      </Paragraph>
      <Paragraph color="text" size="xlarge">
        Homes with minimal exposure are unlikely to be subjected to wildfire. Homes with indirect
        exposure may be ignited by embers or home-to-home ignition. Homes with direct exposure may
        be ignited by adjacent vegetation, flying embers, or nearby structures.
      </Paragraph>
      <Paragraph color="text" size="xlarge">
        Effective wildfire risk-reduction activities will vary depending on the zone.
      </Paragraph>
    </>
  );

  const structureScale = useMemo(() => {
    if (!chartData.total_buildings) return null;
    if (chartData.total_buildings <= 1000) return RRZStructureScale.Small;
    else if (chartData.total_buildings <= 100000) return RRZStructureScale.Medium;
    return RRZStructureScale.Large;
  }, [chartData.total_buildings]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box pad={{ horizontal: "medium", bottom: "medium" }} className="content-box">
            <Heading level={3}>About Risk Reduction Zones</Heading>
            {geography && descriptionText}
          </Box>
          <RRZChart rrz={chartData} size={size} structureScale={structureScale} />
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default RRZComponent;
