import { Box, BoxProps, Button, Drop, Heading, Paragraph, ResponsiveContext, Text } from "grommet";
import { Node } from "htmlparser2";
import React, { useEffect, useMemo } from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { connect } from "react-redux";
import styled from "styled-components";

import { GlossaryEntry } from "../models";
import { State } from "../reducers";
import { GlossaryState } from "../reducers/glossary";
import CloseIcon from "../icons/CloseIcon";
import store from "../store";
import { setGlossaryTerm, toggleGlossary } from "../actions/glossaryControl";
import AngleRightIcon from "../icons/AngleRightIcon";

const DropStyled = styled(Drop)`
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  width: 45rem !important;
  max-height: none !important;
  border-radius: 0;
`;

// When this is set as a style={} prop directly on the component
// it overwrites the (desirable) hover state. This way it doesn't.
const GlossaryButton = styled(Button)`
  background: none;
  padding: 0.2rem 0.5rem;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  &:hover {
    color: var(--text-800) !important;
  }
`;

const GlossaryTermButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  outline-offset: 0 !important;
  color: var(--text-900);
  &:hover {
    background: var(--warm-gray-200);
  }
`;

interface StateProps {
  readonly glossaryResource: GlossaryState;
  readonly selectedTerm: string;
  readonly openDrop: boolean;
}

interface Props {}

const GlossaryComponent = ({
  glossaryResource,
  selectedTerm,
  openDrop
}: StateProps & Props & BoxProps) => {
  // Use `closeGlossary` and `toggleGlossaryOpen` instead of `setOpenDrop` directly
  // to open or close the glossary, in order to clear the initial state when it closes.

  const returnFocusTarget = useMemo(() => {
    return document.activeElement as HTMLElement;
  }, []);

  const closeGlossary = () => {
    onTermSelected("");
    store.dispatch(toggleGlossary(false));
    returnFocusTarget?.focus();
  };

  const openGlossary = () => {
    store.dispatch(toggleGlossary(true));
  };

  // If there's a term selected but the glossary isn't open (e.g. the term is coming from a
  // page tooltip), open it.
  useEffect(() => {
    selectedTerm && (openDrop || openGlossary());
  }, [selectedTerm, openDrop]);

  // Search and open/close are handled with component state, but selected term is in app state
  // to facilitate setting it from elsewhere in the app.
  const setSelectedTerm = (term: string) => {
    store.dispatch(setGlossaryTerm(term));
  };

  const glossary = "resource" in glossaryResource ? glossaryResource.resource.glossary : undefined;
  const currentTerm = selectedTerm && glossary ? glossary.terms[selectedTerm] : undefined;
  const title = glossary ? glossary.title : "Glossary";

  const termSet: Set<string> = useMemo(() => {
    const terms = glossary
      ? Object.entries(glossary.terms).map(([key, ge]: readonly [string, GlossaryEntry]) => {
          return key;
        })
      : undefined;
    return new Set(terms);
  }, [glossary]);

  const onTermSelected = (term: string) => {
    // check if passed term exists in the glossary before attempting to display it
    termSet.has(term) ? setSelectedTerm(term) : setSelectedTerm("");
  };

  // Helper for react-html-parser tranformer to add properties to links
  const transformLinks: any = (node: Node, idx: number) => {
    // Open real (external) links in a new tab
    // tslint:disable-next-line no-if-statement
    if (node.attribs) {
      // tslint:disable-next-line no-object-mutation
      node.attribs.target = "_blank";
      // tslint:disable-next-line no-object-mutation
      node.attribs.rel = "noopener noreferrer";
    }
    convertNodeToElement(node, idx, htmlParserTransform);
  };

  // Custom tranformation method for react-html-parser to handle linking within the glossary
  const htmlParserTransform: any = (node: Node, idx: number) => {
    const isLink = node.type === "tag" && node.name === "a";
    const dataLink = node.attribs ? node.attribs["data-link"] : "";

    // Handle links within the glossary by changing the currently selected term
    return isLink && dataLink ? (
      <a
        key={idx}
        href={"!#"}
        onClick={event => {
          event.preventDefault();
          onTermSelected(dataLink);
        }}
      >
        {node.children && (
          <span>{node.children.map(n => convertNodeToElement(n, idx, htmlParserTransform))}</span>
        )}
      </a>
    ) : isLink && !dataLink ? (
      transformLinks(node, idx)
    ) : (
      convertNodeToElement(node, idx, htmlParserTransform)
    );
  };

  const termsList = () => {
    const terms = glossary ? glossary.terms : undefined;

    return (
      <Box gap="medium">
        <Paragraph>{glossary ? glossary.description : ""}</Paragraph>
        <ul style={{ fontSize: "1.5rem" }} aria-label="Terms">
          {terms &&
            Object.entries(terms)
              .sort(([_1, term1], [_2, term2]) => term1.label.localeCompare(term2.label))
              .map(([key, term]: readonly [string, GlossaryEntry]) => {
                return (
                  <li key={key}>
                    <GlossaryTermButton
                      key={key}
                      label={
                        <>
                          {term.label}
                          <AngleRightIcon size="12px" color="var(--warm-gray-500)" aria-hidden />
                        </>
                      }
                      onClick={() => {
                        onTermSelected(key);
                      }}
                    />
                  </li>
                );
              })}
        </ul>
      </Box>
    );
  };

  return (
    <ResponsiveContext.Consumer>
      {size =>
        openDrop && (
          <DropStyled
            target={returnFocusTarget as HTMLElement}
            onClickOutside={closeGlossary}
            onEsc={closeGlossary}
            background="white"
            pad="medium"
            gap="medium"
            id="glossary-box"
            elevation="large"
            restrictFocus={true}
            a11yTitle={selectedTerm ? `Glossary. Term: ${currentTerm?.label}` : "Glossary"}
          >
            <Box
              direction="row"
              justify="between"
              align="center"
              style={{
                flexShrink: 0
              }}
            >
              {selectedTerm && (
                <GlossaryButton
                  onClick={() => onTermSelected("")}
                  icon={
                    <AngleRightIcon
                      size="12px"
                      color="var(--warm-gray-500)"
                      style={{ transform: "rotate(180deg)" }}
                      aria-hidden
                    />
                  }
                  label={
                    <Text size="medium" weight="bold" color="black">
                      Glossary
                    </Text>
                  }
                  secondary
                  size="small"
                  gap="xsmall"
                  a11yTitle="Show all terms"
                />
              )}
              {!selectedTerm && (
                <Heading
                  level="4"
                  style={{
                    fontSize: "2rem"
                  }}
                >
                  {title}
                </Heading>
              )}
              <Button
                onClick={closeGlossary}
                icon={<CloseIcon size="11px" fill="var(--text-800)" aria-hidden />}
                a11yTitle="Close glossary"
                secondary
                size="small"
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  justifyContent: "center"
                }}
              />
            </Box>
            {currentTerm && (
              <Box
                gap="small"
                style={{
                  fontSize: "1.5rem"
                }}
              >
                <Heading level="3">{currentTerm.label}</Heading>
                <Paragraph>
                  {ReactHtmlParser(currentTerm.shortDefinition, {
                    transform: htmlParserTransform
                  })}
                </Paragraph>
                {ReactHtmlParser(currentTerm.definition, { transform: htmlParserTransform })}
              </Box>
            )}
            {!selectedTerm.length && termsList()}
          </DropStyled>
        )
      }
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  glossaryResource: state.glossary,
  selectedTerm: state.glossaryControl.term,
  openDrop: state.glossaryControl.open
});

export default connect(mapStateToProps)(GlossaryComponent);
