import React from "react";
import { RiskClassLevel } from "../models";
import { riskFillColors, riskLabelColors } from "../constants";

interface Props {
  readonly riskLevel: RiskClassLevel | undefined;
  readonly size?: number;
}

const RiskIcon = ({ riskLevel, size }: Props) => {
  if (!riskLevel) {
    return <></>;
  }

  const width = size || 60;

  const grayFill = "var(--warm-gray-300)";
  const riskFill = riskFillColors[riskLevel];
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 60 60"
      style={{
        flexShrink: 0
      }}
    >
      <title>{`Risk level: ${riskLevel}`}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M30.9834 0V3.00182C45.1 3.51579 56.4513 14.8671 56.9652 28.9836H59.967C59.4504 13.21 46.757 0.516614 30.9834 0Z"
          fill={riskFill}
        />
        <path
          d="M59.967 31H56.9652C56.4513 45.1166 45.1 56.4679 30.9834 56.9818V59.9836C46.757 59.467 59.4504 46.7736 59.967 31Z"
          fill={riskLevel === RiskClassLevel.Low ? grayFill : riskFill}
        />
        <path
          d="M28.9836 59.9836V56.9818C14.8671 56.4679 3.51579 45.1166 3.00182 31H0C0.516614 46.7736 13.21 59.467 28.9836 59.9836Z"
          fill={
            riskLevel === RiskClassLevel.Low || riskLevel === RiskClassLevel.Medium
              ? grayFill
              : riskFill
          }
        />
        <path
          d="M0 29H3.00182C3.51579 14.8834 14.8671 3.53215 28.9836 3.01818V0.0163574C13.21 0.532972 0.516614 13.2264 0 29Z"
          fill={riskLevel === RiskClassLevel.VeryHigh ? riskFill : grayFill}
        />
      </g>
      <text
        x="52%"
        y="52%"
        fontSize="1.5rem"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight={900}
        fill={riskLabelColors[riskLevel]}
        aria-hidden
      >
        {
          {
            [RiskClassLevel.Low]: "Low",
            [RiskClassLevel.Medium]: "Med",
            [RiskClassLevel.High]: "High",
            [RiskClassLevel.VeryHigh]: (
              <>
                <tspan x="52%" y="50%" dy="-.55em" dominantBaseline="middle">
                  Very
                </tspan>
                <tspan x="52%" y="50%" dy=".55em" dominantBaseline="middle">
                  High
                </tspan>
              </>
            )
          }[riskLevel]
        }
      </text>
    </svg>
  );
};

export default RiskIcon;
