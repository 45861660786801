import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const BookIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="BookIcon"
      color="#fff"
      width="20px"
      viewBox="0 0 16 19"
      a11yTitle="Book"
      {...props}
    >
      <path
        stroke="none"
        d="M15.75 12.0625V1.9375C15.75 1.02344 14.9766 0.25 14.0625 0.25H3.375C1.47656 0.25 0 1.76172 0 3.625V14.875C0 16.7734 1.47656 18.25 3.375 18.25H14.625C15.2227 18.25 15.75 17.7578 15.75 17.1602C15.75 16.7734 15.5039 16.4219 15.1875 16.2109V13.3633C15.5039 13.0117 15.75 12.5898 15.75 12.0625ZM5.02734 4.75H11.7773C12.0938 4.75 12.375 5.03125 12.375 5.3125C12.375 5.62891 12.0938 5.875 11.8125 5.875H5.02734C4.74609 5.875 4.5 5.62891 4.5 5.3125C4.5 5.03125 4.74609 4.75 5.02734 4.75ZM5.02734 7H11.7773C12.0938 7 12.375 7.28125 12.375 7.5625C12.375 7.87891 12.0938 8.125 11.8125 8.125H5.02734C4.74609 8.125 4.5 7.87891 4.5 7.5625C4.5 7.28125 4.74609 7 5.02734 7ZM13.5 16H3.375C2.74219 16 2.25 15.5078 2.25 14.875C2.25 14.2773 2.74219 13.75 3.375 13.75H13.5V16Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default BookIcon;
