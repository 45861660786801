import { combineReducers } from "redux-loop";

import comparisonLevelReducer, {
  initialState as initialComparisonLevelState,
  ComparisonLevelState
} from "./reducers/comparisonLevel";

import rrzReducer, {
  initialState as initialRRZState,
  RRZState
} from "./reducers/riskReductionZone";

import geographyReducer, {
  GeographyState,
  initialState as initialGeographyState
} from "./reducers/geography";

import geographyListReducer, {
  GeographyListState,
  initialState as initialGeographyListState
} from "./reducers/geographyList";

import glossaryReducer, {
  GlossaryState,
  initialState as initialGlossaryState
} from "./reducers/glossary";

import glossaryControlReducer, {
  GlossaryControlState,
  initialState as initialGlossaryControlState
} from "./reducers/glossaryControl";

import populatedAreaMaskReducer, {
  PopulatedAreaMaskState,
  initialState as initialPopulatedAreaMaskState
} from "./reducers/populatedAreaMask";

import riskToHomesReducer, {
  initialState as initialRiskToHomesState,
  RiskToHomesState
} from "./reducers/riskToHomes";

import vulnerablePopulationsReducer, {
  initialState as initialVulnerablePopulationsState,
  VulnerablePopulationsState
} from "./reducers/vulnerablePopulations";

import wildfireLikelihoodReducer, {
  initialState as initialWildfireLikelihoodState,
  WildfireLikelihoodState
} from "./reducers/wildfireLikelihood";

export interface State {
  readonly comparisonLevel: ComparisonLevelState;
  readonly riskReductionZone: RRZState;
  readonly geography: GeographyState;
  readonly geographyList: GeographyListState;
  readonly glossary: GlossaryState;
  readonly glossaryControl: GlossaryControlState;
  readonly populatedAreaMask: PopulatedAreaMaskState;
  readonly riskToHomes: RiskToHomesState;
  readonly vulnerablePopulations: VulnerablePopulationsState;
  readonly wildfireLikelihood: WildfireLikelihoodState;
}

export const initialState: State = {
  comparisonLevel: initialComparisonLevelState,
  riskReductionZone: initialRRZState,
  geography: initialGeographyState,
  geographyList: initialGeographyListState,
  glossary: initialGlossaryState,
  glossaryControl: initialGlossaryControlState,
  populatedAreaMask: initialPopulatedAreaMaskState,
  riskToHomes: initialRiskToHomesState,
  vulnerablePopulations: initialVulnerablePopulationsState,
  wildfireLikelihood: initialWildfireLikelihoodState
};

// Having separate reducers is a nice way to encapsulate you logic related to
// specific aspects of your app. Combining reducers doesn't make a whole lot of
// sense when you have only have one, but it should once you have multiple
// reducers.
export default combineReducers<State>({
  comparisonLevel: comparisonLevelReducer,
  riskReductionZone: rrzReducer,
  geography: geographyReducer,
  geographyList: geographyListReducer,
  glossary: glossaryReducer,
  glossaryControl: glossaryControlReducer,
  populatedAreaMask: populatedAreaMaskReducer,
  riskToHomes: riskToHomesReducer,
  vulnerablePopulations: vulnerablePopulationsReducer,
  wildfireLikelihood: wildfireLikelihoodReducer
} as any); // HACK! See: https://github.com/reduxjs/redux/issues/2709#issuecomment-357328709
