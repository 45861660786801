import React from "react";
import { Anchor, Box, Grid, Paragraph, ResponsiveContext, Heading } from "grommet";
import { Screen, RRZClass, ActionDetails } from "../models";
import AngleRightIcon from "../icons/AngleRightIcon";
import ActionCard from "./ActionCard";
import { actions, rrzColors } from "../constants";
import styled from "styled-components";

interface Props {
  readonly screen: Screen;
}

interface ActionsListProps {
  readonly singleColumn?: boolean;
  readonly actions: Array<ActionDetails>;
  readonly level?: 4 | 5;
  readonly analyticsCategory: string;
}

const ActionList = ({ singleColumn, actions, level, analyticsCategory }: ActionsListProps) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Grid
        as="ul"
        columns={
          singleColumn
            ? ["auto"]
            : size === "large"
            ? ["auto", "auto", "auto"]
            : size === "medium"
            ? ["auto", "auto"]
            : ["auto"]
        }
        gap={singleColumn || size === "small" ? "small" : "medium"}
        style={{
          gridAutoRows: "1fr"
        }}
        margin={singleColumn ? {} : { top: "medium", bottom: "small" }}
        a11yTitle="Actions to reduce risk"
      >
        {Object.entries(actions).map(([key, action]) => (
          <ActionCard
            key={key}
            action={action}
            level={level}
            analyticsCategory={analyticsCategory}
          />
        ))}
      </Grid>
    )}
  </ResponsiveContext.Consumer>
);

const RRZClassBar = styled.div<{ barColor: string }>`
  height: 0.8rem;
  width: 100%;
  background: ${props => props.barColor};
  border-radius: var(--border-radius);
`;

const RRZColumnHeading = styled.h4`
  margin: 0;
  font-size: 2.4rem;
  font-weight: 700;
  color: white;
`;

interface ActionsColumnProps {
  columnId: Number;
  description: String;
  rrzClass: RRZClass;
  size: String;
  actions: Array<ActionDetails>;
}

const ActionsColumn = ({ columnId, description, rrzClass, size, actions }: ActionsColumnProps) => (
  <Box as="li" aria-labelledby={`zone-${columnId}`} gap="small">
    <RRZClassBar barColor={rrzColors[rrzClass]} />
    <RRZColumnHeading id={`zone-${columnId}`}>{rrzClass}</RRZColumnHeading>
    <Paragraph
      size="large"
      color="white !important"
      style={{ minHeight: size === "large" ? "10rem" : "0" }}
    >
      {description}
    </Paragraph>
    <ActionList
      singleColumn={size === "large"}
      level={5}
      actions={actions}
      analyticsCategory={"risk reduction zones"}
    />
  </Box>
);

const TakeAction = (props: Props) => {
  const { screen } = props;
  const text =
    screen === Screen.WildfireLikelihood
      ? "Wildfire likelihood can be difficult to modify, but communities can work to reduce their likelihood and be better prepared for wildfires."
      : screen === Screen.RiskReductionZones
      ? "Your most effective actions to reduce risk vary by risk reduction zone."
      : screen === Screen.RiskToHomes
      ? "Communities can reduce their wildfire risk to homes by taking action before a wildfire occurs."
      : screen === Screen.VulnerablePopulations
      ? "These actions can help communities address the unique needs of socially and economically vulnerable neighborhoods."
      : "";
  const content =
    screen === Screen.WildfireLikelihood ? (
      <ActionList
        actions={[
          actions.EvacuationAndReadiness,
          actions.SmokeReady,
          actions.PreventIgnitions,
          actions.Response,
          actions.HazardousFuelsManagement
        ]}
        analyticsCategory="wildfire likelihood"
      />
    ) : screen === Screen.RiskToHomes ? (
      <ActionList
        actions={[
          actions.IgnitionResistantHomes,
          actions.LandUsePlanning,
          actions.EquitableRiskReduction
        ]}
        analyticsCategory="risk to homes"
      />
    ) : screen === Screen.VulnerablePopulations ? (
      <ActionList
        actions={[
          actions.EvacuationAndReadiness,
          actions.EquitableRiskReduction,
          actions.SmokeReady,
          actions.RecoveryAndRebuilding
        ]}
        analyticsCategory="vulnerable populations"
      />
    ) : screen === Screen.RiskReductionZones ? (
      <ResponsiveContext.Consumer>
        {size => (
          <Grid
            as="ul"
            columns={size === "large" ? ["auto", "auto", "auto"] : ["auto"]}
            gap={size === "large" ? "large" : "4rem"}
            margin={{ top: "medium" }}
          >
            <ActionsColumn
              columnId={1}
              description="Reduce places for embers to land and ignite."
              rrzClass={RRZClass.Indirect}
              size={size}
              actions={[actions.IgnitionResistantHomes, actions.LandUsePlanning]}
            />
            <ActionsColumn
              columnId={2}
              description="Reduce ways for vegetation to carry fire to homes, and reduce places for embers to
              land and ignite."
              rrzClass={RRZClass.Direct}
              size={size}
              actions={[
                actions.IgnitionResistantHomes,
                actions.LandUsePlanning,
                actions.HazardousFuelsManagement,
                actions.Response
              ]}
            />
            <ActionsColumn
              columnId={3}
              description="Reduce continuity of vegetative fuels to slow fire spread."
              rrzClass={RRZClass.Transmission}
              size={size}
              actions={[actions.HazardousFuelsManagement, actions.Response]}
            />
          </Grid>
        )}
      </ResponsiveContext.Consumer>
    ) : (
      ""
    );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad={{ horizontal: "medium", bottom: "medium" }}
          className="content-box dark"
          style={{
            gridColumn: size === "large" ? "span 2" : "",
            background: `
              linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25%, rgba(0, 0, 0, 0.5) 100%), 
              linear-gradient(0deg, rgba(57, 49, 45, 0.50) 0%, rgba(57, 49, 45, 0.50) 100%),
              url(${
                process.env.PUBLIC_URL + "/TakeAction-9-22.jpg"
              }) var(--warm-gray-900) 100% / cover no-repeat
            `,
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        >
          <Heading level={3}>Take action</Heading>
          <Paragraph size="xlarge" color="white !important">
            {text}
          </Paragraph>
          {content}
          {screen !== Screen.RiskReductionZones && (
            <Anchor href="/reduce-risk" className="button-link large dark" target="_blank">
              Find more ways to reduce risk
              <AngleRightIcon size="14px" color="white" aria-hidden />
            </Anchor>
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default TakeAction;
