import React, { useEffect, useRef, useState } from "react";
import { Text, Box, Image, Anchor, Button, ResponsiveContext } from "grommet";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { HeaderNavLink } from "../models";
import { fetchNavLinks } from "../api";
import MenuIcon from "../icons/MenuIcon";

interface MenuNavProps {
  href: string;
  label: string;
  pad: { vertical: string; horizontal: string };
  color?: string;
  marginBottom?: string;
}

const HIGHLIGHT_COLOR = "#06c";

const WINDOW_SIZE_BREAKPOINT = 1024;

type WindowNarrowness = "NARROW" | "WIDE";

const MenuButton = styled(Button)<{ showMenu: boolean }>`
  color: ${props => (props.showMenu ? HIGHLIGHT_COLOR : "black")};
  &:hover {
    color: ${HIGHLIGHT_COLOR};
  }
`;

const MenuLabel = styled(Text)`
  text-rendering: auto;
  flex-shrink: 0;
  &:hover {
    color: ${HIGHLIGHT_COLOR};
  }
`;

const MenuNav = ({ href, label, pad, color = "black", marginBottom }: MenuNavProps) => (
  <Anchor href={href}>
    <Box direction="row" align="center" pad={pad} margin={{ bottom: marginBottom }}>
      <MenuLabel
        size="1.5rem"
        weight={400}
        color={color}
        style={{ fontFamily: "var(--font-family-menu)" }}
      >
        {label}
      </MenuLabel>
    </Box>
  </Anchor>
);

const AppTopMenu = () => {
  const [navLinks, setNavLinks] = useState<ReadonlyArray<HeaderNavLink>>([]);
  const [homeLink, setHomeLink] = useState<string>("/");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<WindowNarrowness>(
    window.innerWidth >= WINDOW_SIZE_BREAKPOINT ? "WIDE" : "NARROW"
  );
  const [isMenuHovered, setIsMenuHovered] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  // Fetch the list of Nav links and labels from API.
  // Filter to only top-level menu items and sort by display order.
  useEffect(() => {
    const fetch = async () => {
      const allLinks = await fetchNavLinks();
      const linksToDisplay = allLinks
        .filter(link => link.menu_item_parent === "0")
        .sort((link, nextLink) => link.menu_order - nextLink.menu_order);
      const home = linksToDisplay.find(link => link.title.toUpperCase() === "HOME");
      if (home) setHomeLink(home.url);
      setNavLinks(linksToDisplay);
    };
    fetch();
  }, []);

  // https://wildfirerisk.org/ has different nav bar menu styles
  // on different screen sizes. The breakpoint is not the same
  // as this app. We need to listen to window size change.
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= WINDOW_SIZE_BREAKPOINT) {
        setWindowWidth("WIDE");
      } else {
        setWindowWidth("NARROW");
      }
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Scroll the website nav out of view on Overview and Detail pages,
  // and scroll up after page changes.
  useEffect(() => {
    setShowMenu(false);
    setTimeout(() => {
      if (ref.current && pathname !== "/" && pathname !== "/explore") {
        window.scrollTo({
          top: ref.current.getBoundingClientRect().height,
          left: 0
        });
      }
    });
  }, [pathname, ref]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box ref={ref}>
          <Box
            direction="row"
            height={"8rem"}
            width={"100vw"}
            background="#fff"
            pad={{
              vertical: "1rem",
              horizontal: windowWidth === "WIDE" ? "4rem" : "2rem"
            }}
            justify="between"
            align="center"
            gap="small"
          >
            <Anchor href={homeLink}>
              <Box
                as="h1"
                height={{ max: "4rem" }}
                width={{ max: "30rem" }}
                margin={{ top: "1.3rem", left: "-1px" }}
              >
                <Image
                  fit="contain"
                  fill="horizontal"
                  alt="Wildfire Risk to Communities"
                  src={process.env.PUBLIC_URL + "/wrc-logo-w-agency.png"}
                />
              </Box>
            </Anchor>
            {windowWidth === "WIDE" ? (
              <Box as="nav" direction="row" margin={{ top: "-1px" }} gap=".45rem">
                {navLinks.map(link => (
                  <MenuNav
                    href={link.url}
                    label={link.title}
                    key={link.ID}
                    color={link.title === "Explore Risk" ? HIGHLIGHT_COLOR : undefined}
                    pad={{ vertical: "2rem", horizontal: "1rem" }}
                  />
                ))}
              </Box>
            ) : (
              <Box direction="row" alignSelf="center">
                <MenuButton
                  onClick={() => setShowMenu(!showMenu)}
                  showMenu={showMenu}
                  color="black"
                  plain={true}
                  onMouseOver={() => setIsMenuHovered(true)}
                  onMouseOut={() => setIsMenuHovered(false)}
                >
                  <Box justify="center" align="center" direction="row" gap="0.5rem">
                    <MenuIcon
                      size="14px"
                      color={isMenuHovered || showMenu ? HIGHLIGHT_COLOR : "black"}
                      style={{ marginTop: "6px", marginRight: "1px" }}
                    />
                    <Text
                      size="1.5rem"
                      weight={400}
                      style={{
                        textTransform: "uppercase",
                        letterSpacing: "0.07em",
                        fontFamily: "var(--font-family-menu)",
                        textRendering: "auto"
                      }}
                    >
                      <span className="sr-only">{showMenu ? "Close" : "Open"}</span> Menu
                    </Text>
                  </Box>
                </MenuButton>
              </Box>
            )}
          </Box>
          {windowWidth === "NARROW" && showMenu && (
            <Box as="nav" direction="column" background="white">
              {navLinks.map(link => (
                <MenuNav
                  href={link.url}
                  label={link.title}
                  key={link.ID}
                  color={link.title === "Explore Risk" ? HIGHLIGHT_COLOR : undefined}
                  pad={{ vertical: "1.3rem", horizontal: size !== "small" ? "medium" : "small" }}
                  marginBottom="1px"
                />
              ))}
            </Box>
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default AppTopMenu;
