import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const PreventIgnitionsIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="PreventIgnitionsIcon"
      viewBox="0 0 26 27"
      a11yTitle="Prevent Ignitions"
      {...props}
    >
      <path
        stroke="none"
        d="M 18.51,4.14
        C 19.03,4.47 19.52,4.79 20.02,5.08
          20.38,5.30 20.52,5.58 20.53,6.00
          20.54,6.91 20.63,7.83 20.65,8.74
          20.66,9.14 20.84,9.22 21.19,9.26
          22.50,9.40 23.80,9.59 25.10,9.76
          25.27,9.79 25.47,9.80 25.58,9.90
          25.73,10.03 25.85,10.24 25.87,10.43
          25.89,10.52 25.68,10.71 25.54,10.75
          24.89,10.93 24.23,11.08 23.51,11.25
          23.59,11.45 23.66,11.63 23.73,11.81
          24.20,13.01 23.80,14.31 22.69,14.91
          22.25,15.15 22.28,15.41 22.33,15.81
          22.52,17.17 22.72,18.54 22.80,19.91
          22.89,21.33 22.26,22.52 21.30,23.53
          20.84,24.03 20.32,24.46 19.73,24.82
          19.78,24.47 19.83,24.13 19.88,23.78
          18.98,24.75 18.66,26.24 16.96,26.30
          16.94,26.01 16.93,25.73 16.91,25.31
          16.29,26.09 15.80,26.89 14.67,26.59
          14.78,26.29 14.89,25.97 15.01,25.66
          14.98,25.64 14.95,25.62 14.93,25.61
          14.60,25.90 14.28,26.20 13.92,26.53
          13.60,26.24 13.31,25.99 13.03,25.73
          12.99,25.74 12.96,25.76 12.92,25.77
          13.01,26.03 13.10,26.29 13.19,26.58
          11.97,26.85 11.16,26.24 10.49,25.29
          10.46,25.34 10.43,25.39 10.40,25.44
          10.47,25.67 10.55,25.90 10.62,26.13
          10.08,26.36 9.21,25.99 8.74,25.29
          8.34,24.70 7.97,24.09 7.59,23.49
          7.55,23.51 7.50,23.53 7.46,23.54
          7.53,23.90 7.59,24.27 7.67,24.67
          7.55,24.64 7.45,24.64 7.39,24.59
          6.24,23.65 5.16,22.60 4.89,21.11
          4.70,20.03 4.82,18.89 4.89,17.78
          4.93,17.02 5.14,16.27 5.24,15.52
          5.26,15.37 5.20,15.14 5.09,15.05
          3.63,13.88 3.43,13.25 4.02,11.38
          3.38,11.19 2.73,11.03 2.11,10.80
          1.91,10.72 1.66,10.47 1.65,10.29
          1.64,10.15 1.94,9.87 2.13,9.84
          3.46,9.62 4.80,9.43 6.14,9.28
          6.60,9.23 6.79,9.09 6.81,8.61
          6.84,7.66 6.90,6.72 7.00,5.77
          7.03,5.55 7.20,5.29 7.38,5.16
          8.84,4.07 10.32,3.03 11.78,1.95
          12.15,1.67 12.50,1.52 12.97,1.57
          13.47,1.62 13.98,1.62 14.49,1.58
          14.99,1.54 15.39,1.69 15.78,2.00
          16.66,2.72 17.57,3.41 18.51,4.14M 11.62,6.85
        C 11.66,6.95 11.74,7.04 11.74,7.13
          11.71,7.54 11.64,7.94 11.63,8.35
          11.62,8.48 11.73,8.62 11.78,8.76
          11.87,8.64 12.02,8.52 12.03,8.40
          12.09,7.75 12.12,7.10 12.14,6.46
          12.14,6.35 12.00,6.18 11.90,6.16
          11.77,6.14 11.58,6.20 11.48,6.29
          11.39,6.38 11.39,6.56 11.31,6.75
          11.30,6.82 11.29,6.88 11.25,7.02
          11.18,7.20 11.11,7.38 11.04,7.56
          10.87,7.23 10.83,6.91 10.81,6.59
          10.78,6.08 10.51,5.97 10.05,6.12
          9.99,6.98 9.81,7.82 9.99,8.65
          10.06,8.66 10.13,8.67 10.20,8.67
          10.24,8.05 10.29,7.43 10.34,6.81
          10.39,6.82 10.45,6.82 10.51,6.83
          10.55,7.15 10.57,7.48 10.62,7.81
          10.67,8.12 10.74,8.43 10.81,8.75
          10.89,8.75 10.97,8.74 11.05,8.74
          11.21,8.14 11.36,7.54 11.54,6.93
          11.54,6.93 11.53,6.90 11.62,6.85M 9.95,15.12
        C 10.27,15.12 10.60,15.12 10.91,15.12
          10.67,15.76 10.36,16.30 10.27,16.88
          10.14,17.81 10.13,18.76 10.11,19.71
          10.09,20.43 10.49,20.91 11.18,21.05
          11.43,21.11 11.69,21.06 11.94,21.06
          11.90,21.80 12.10,22.18 12.88,22.34
          13.46,22.47 14.11,22.47 14.70,22.35
          15.54,22.18 15.72,21.87 15.70,21.02
          15.84,21.05 15.96,21.08 16.08,21.09
          16.71,21.16 17.39,20.60 17.40,19.99
          17.41,18.35 17.65,16.68 16.51,15.17
          16.91,15.15 17.23,15.14 17.72,15.11
          16.88,14.87 16.51,14.43 16.51,13.68
          15.86,13.98 16.10,14.49 16.15,14.97
          14.58,14.17 13.05,14.19 11.46,14.90
          11.56,14.36 11.68,13.91 11.09,13.70
          11.01,14.56 10.77,14.82 9.95,15.12M 10.88,10.63
        C 13.78,10.88 16.66,10.90 19.50,10.20
          20.42,9.98 20.43,9.96 20.16,9.13
          15.90,10.00 11.68,10.04 7.45,9.05
          7.26,9.89 7.27,9.93 8.14,10.11
          9.02,10.29 9.90,10.45 10.88,10.63M 12.86,6.32
        C 12.32,6.91 12.43,7.61 12.59,8.28
          12.65,8.51 13.04,8.83 13.27,8.81
          13.52,8.80 13.92,8.50 13.97,8.26
          14.09,7.79 14.08,7.26 13.99,6.78
          13.88,6.22 13.51,6.08 12.86,6.32M 15.07,7.62
        C 15.21,7.86 15.35,8.10 15.51,8.33
          15.62,8.49 15.76,8.63 15.89,8.78
          15.95,8.74 16.01,8.69 16.08,8.65
          15.89,8.23 15.62,7.83 15.56,7.39
          15.50,6.98 15.66,6.55 15.73,6.12
          15.69,6.10 15.65,6.07 15.62,6.05
          15.39,6.41 15.16,6.78 14.94,7.15
          14.83,6.86 14.85,6.62 14.81,6.39
          14.80,6.30 14.66,6.24 14.58,6.16
          14.52,6.24 14.40,6.32 14.40,6.39
          14.42,7.10 14.46,7.81 14.51,8.51
          14.52,8.61 14.69,8.70 14.79,8.78
          14.85,8.67 14.95,8.56 14.97,8.44
          15.00,8.19 14.99,7.93 15.07,7.62M 19.79,14.35
        C 19.62,14.10 19.38,13.89 19.29,13.62
          19.12,13.11 18.83,12.92 18.27,13.08
          18.69,13.85 18.64,14.28 17.93,14.95
          18.50,15.10 18.99,15.22 19.48,15.34
          19.50,15.29 19.52,15.24 19.54,15.19
          19.43,15.04 19.33,14.89 19.17,14.65
          19.47,14.65 19.67,14.68 19.86,14.65
          20.12,14.59 20.13,14.48 19.79,14.35M 8.94,15.22
        C 9.13,15.13 9.33,15.04 9.58,14.93
          8.98,14.31 8.87,13.69 9.41,13.00
          8.95,12.96 8.50,13.02 8.41,13.41
          8.29,13.95 8.08,14.33 7.56,14.61
          7.83,14.63 8.10,14.66 8.44,14.69
          8.29,14.90 8.19,15.05 8.03,15.29
          8.38,15.27 8.61,15.25 8.94,15.22M 17.23,8.64
        C 17.45,8.58 17.68,8.52 17.90,8.45
          17.89,8.39 17.88,8.32 17.86,8.26
          17.74,8.24 17.62,8.21 17.50,8.20
          17.27,8.18 16.97,8.38 16.88,7.96
          16.79,7.52 17.06,7.51 17.36,7.47
          17.44,7.46 17.50,7.33 17.56,7.25
          17.48,7.20 17.38,7.08 17.32,7.10
          16.69,7.27 16.79,6.88 16.81,6.47
          17.03,6.45 17.24,6.44 17.43,6.39
          17.52,6.36 17.58,6.26 17.65,6.19
          17.58,6.12 17.51,5.98 17.44,5.98
          17.05,6.02 16.66,6.09 16.28,6.15
          16.34,6.85 16.45,7.46 16.44,8.07
          16.44,8.61 16.62,8.79 17.23,8.64M 8.70,6.29
        C 8.87,6.33 9.04,6.36 9.22,6.39
          9.33,6.41 9.44,6.42 9.55,6.44
          9.53,6.32 9.55,6.18 9.49,6.10
          9.26,5.76 8.81,5.71 8.50,5.96
          8.16,6.23 8.09,6.73 8.39,7.04
          8.56,7.22 8.81,7.31 8.96,7.49
          9.05,7.59 9.02,7.80 9.04,7.96
          8.88,7.96 8.69,8.01 8.55,7.95
          8.42,7.89 8.33,7.73 8.22,7.62
          8.16,7.64 8.11,7.67 8.05,7.69
          8.10,7.85 8.10,8.07 8.20,8.14
          8.43,8.29 8.74,8.48 8.97,8.43
          9.20,8.37 9.48,8.07 9.53,7.84
          9.58,7.61 9.41,7.30 9.26,7.07
          9.10,6.81 8.86,6.60 8.70,6.29M 18.15,6.45
        C 18.30,6.98 18.45,7.51 18.62,8.04
          18.66,8.17 18.79,8.28 18.87,8.39
          18.94,8.27 19.06,8.15 19.08,8.02
          19.19,7.39 19.28,6.75 19.38,6.11
          19.40,5.98 19.41,5.85 19.43,5.72
          19.37,5.70 19.30,5.67 19.24,5.65
          19.08,5.97 18.91,6.30 18.74,6.64
          18.44,6.37 18.56,5.76 17.88,5.85
          17.98,6.07 18.05,6.22 18.15,6.45M 12.33,4.32
        C 12.33,3.86 12.33,3.41 12.33,2.95
          12.27,2.93 12.21,2.92 12.15,2.90
          11.96,3.69 11.62,4.35 10.76,4.60
          11.32,4.67 11.87,4.97 12.33,4.32M 16.06,4.29
        C 15.71,3.84 15.37,3.39 15.03,2.93
          14.99,3.49 14.93,4.14 15.22,4.56
          15.39,4.80 16.09,4.69 16.56,4.74
          16.56,4.68 16.57,4.62 16.57,4.55
          16.43,4.48 16.28,4.41 16.06,4.29 Z
        M 14.36,17.66
        C 14.15,17.91 13.94,18.12 13.74,18.32
          13.56,18.04 13.29,17.78 13.22,17.48
          13.14,17.13 13.24,16.77 12.66,16.97
          12.56,17.01 12.23,16.74 12.20,16.57
          12.10,16.09 12.32,15.68 12.79,15.49
          13.41,15.23 14.07,15.20 14.71,15.46
          15.19,15.66 15.40,16.05 15.33,16.53
          15.31,16.71 15.00,17.01 14.91,16.98
          14.17,16.75 14.33,17.22 14.36,17.66 Z
        M 14.62,21.60
        C 13.69,22.05 12.98,21.82 12.24,20.84
          12.67,20.62 13.10,20.38 13.55,20.18
          13.67,20.12 13.85,20.12 13.96,20.17
          14.45,20.40 14.92,20.66 15.46,20.94
          15.20,21.15 14.93,21.36 14.62,21.60 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default PreventIgnitionsIcon;
