import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { rrzFetch } from "./actions/riskReductionZone";
import { geographyFetch } from "./actions/geography";
import { riskToHomesFetch } from "./actions/riskToHomes";
import { vulnerablePopulationsFetch } from "./actions/vulnerablePopulations";
import { wildfireLikelihoodFetch } from "./actions/wildfireLikelihood";
import { selectedOrDefaultComparisonLevel } from "./components/ComparisonSwitcher";
import {
  ComparisonLevel,
  CountyGeography,
  RRZResource,
  Geography,
  GeographyLevel,
  GeographyResource,
  RiskToHomesResource,
  Screen,
  StateGeography,
  WildfireLikelihoodResource,
  Ranks
} from "./models";
import { VulnerablePopulationsState } from "./reducers/vulnerablePopulations";
import { Resource } from "./resource";
import { useAppDispatch, useAppSelector } from "./store";

export type RiskParams = {
  readonly screen: Screen;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly countyGeographies: readonly CountyGeography[] | undefined;
  readonly detailGeography: Geography | undefined;
  readonly detailGeographyId: string;
  readonly effectiveComparisonLevel: ComparisonLevel;
  readonly riskReductionZone: Resource<RRZResource>;
  readonly geography: Resource<GeographyResource>;
  readonly geographyLevel: GeographyLevel;
  readonly mapStyleStateAbbrev: string | undefined;
  readonly riskToHomes: Resource<RiskToHomesResource>;
  readonly showPopulatedAreaMask: boolean;
  readonly stateGeography: StateGeography | undefined;
  readonly vulnerablePopulations: VulnerablePopulationsState;
  readonly wildfireLikelihood: Resource<WildfireLikelihoodResource>;
  readonly ranks: Ranks | undefined;
};

interface Params {
  readonly screen: Screen;
  readonly stateId: string;
  readonly countyIds?: string;
  readonly communityId?: string;
}

export function useRiskParams(): RiskParams {
  const dispatch = useAppDispatch();

  const comparisonLevel = useAppSelector(state => state.comparisonLevel);
  const geography = useAppSelector(state => state.geography);
  const showPopulatedAreaMask = useAppSelector(state => state.populatedAreaMask);

  const riskToHomes = useAppSelector(state => state.riskToHomes);
  const wildfireLikelihood = useAppSelector(state => state.wildfireLikelihood);
  const vulnerablePopulations = useAppSelector(state => state.vulnerablePopulations);
  const riskReductionZone = useAppSelector(state => state.riskReductionZone);

  // Find the most specific geometry selected
  const { screen, communityId, countyIds, stateId } = useParams<Params>();
  const detailGeographyId = communityId ? communityId : (countyIds ? countyIds : stateId) || "";
  const detailGeography: Geography | undefined =
    "resource" in geography ? geography.resource.geography : undefined;
  // Because just 'state' could be confusing in a redux application.
  const stateGeography =
    stateId && "resource" in geography ? geography.resource.geography.state : undefined;
  const countyGeographies =
    countyIds && "resource" in geography && geography.resource.geography.counties
      ? geography.resource.geography.counties
      : undefined;

  const ranks = detailGeography && detailGeography.ranks;

  // Figure out the geography level from which params are provided.
  // 'countyIds' is the second level, which could actually be either a county or a tribal area.
  // Tribal area IDs can be either 6 or 7, but counties are 5 (some with one leading zero).
  const geographyLevel = communityId
    ? GeographyLevel.community
    : countyIds && countyIds.length < 6
    ? GeographyLevel.county
    : countyIds
    ? GeographyLevel.tribal_area
    : GeographyLevel.state;

  const comparisonCountyName =
    detailGeography && detailGeography.counties ? detailGeography.counties[0].name_short : "";
  const comparisonStateName = stateGeography ? stateGeography.name : "";

  const effectiveComparisonLevel = selectedOrDefaultComparisonLevel(
    geographyLevel,
    comparisonLevel
  );

  // For the RPS layer, use the national styling function when viewing a whole state
  const mapStyleStateAbbrev =
    effectiveComparisonLevel === ComparisonLevel.national
      ? "US"
      : stateGeography
      ? stateGeography.abrv
      : undefined;

  const comparisonId =
    effectiveComparisonLevel === ComparisonLevel.county && countyIds
      ? countyIds.split("|")[0]
      : effectiveComparisonLevel === ComparisonLevel.state
      ? stateId
      : "";

  // Fetching geography data is a two-part process. First, we need to get some basic info, which
  // gets stored in detailGeography.  So if detailGeography is undefined, we might need to dispatch
  // a fetch, but only in the case that we don't already have a fetch pending.  The other case where
  // we need to dispatch a fetch is if the stored data doesn't match the ID that the user has
  // selected, which indicates that the user has just switched geographies.
  //
  // Once this initial fetch succeeds, then the next useEffect() down will take over and fetch the
  // remaining data for the geography.
  useEffect(() => {
    if (
      (detailGeography === undefined &&
        "isPending" in geography &&
        geography.isPending === false) ||
      (detailGeography !== undefined &&
        detailGeographyId !== detailGeography?.breadcrumb.split("/").slice(-1)[0])
    ) {
      dispatch(geographyFetch(detailGeographyId));
    }
  }, [dispatch, geography, detailGeography, detailGeographyId]);

  // Fetch the rest of the information we need for displaying geography info.
  // This needs to happen once the preceding useEffect has retrieved the basic geography info.
  useEffect(() => {
    // This is approximately the inverse of the logic in the preceding hook, but we don't need to
    // pay attention to the state of geography because we're relying on the previous hook to resolve
    // detailGeography if it's undefined.
    if (
      detailGeography === undefined ||
      detailGeographyId !== detailGeography?.breadcrumb.split("/").slice(-1)[0]
    )
      return;
    const breadCrumbs = detailGeography.breadcrumb.split("/");
    if (detailGeographyId !== breadCrumbs[breadCrumbs.length - 1]) return;

    // Get the data for all screens whenever we load any screen; the switching between screens for the same geography is fast,
    // and user input (e.g. selecting specific vulnerable populations) is preserved.
    dispatch(rrzFetch(geographyLevel, detailGeographyId));
    dispatch(riskToHomesFetch(geographyLevel, comparisonId, detailGeographyId, detailGeography));
    dispatch(vulnerablePopulationsFetch(detailGeographyId));
    dispatch(
      wildfireLikelihoodFetch(geographyLevel, comparisonId, detailGeographyId, detailGeography)
    );
  }, [dispatch, geographyLevel, comparisonId, detailGeographyId, detailGeography]);

  return {
    screen: screen as Screen,
    comparisonCountyName,
    comparisonStateName,
    countyGeographies,
    detailGeography,
    detailGeographyId,
    effectiveComparisonLevel,
    riskReductionZone,
    geography,
    geographyLevel,
    mapStyleStateAbbrev,
    riskToHomes,
    showPopulatedAreaMask,
    stateGeography,
    vulnerablePopulations,
    wildfireLikelihood,
    ranks
  };
}
