import { BaseMapLayer, ComparisonLevel, GeographyLevel, Screen } from "../models";

const basemapName = (screen: Screen) => {
  return screen === Screen.RiskToHomes
    ? "wildfire risk to homes"
    : screen === Screen.WildfireLikelihood
    ? "wildfire likelihood"
    : screen === Screen.RiskReductionZones
    ? "wildfire risk reduction zones"
    : screen === Screen.VulnerablePopulations
    ? "areas with vulnerable populations"
    : "";
};

export const accessibleMapLabel = (
  screen: Screen,
  placeName: string,
  vulnerablePopulationsBaseMap?: BaseMapLayer
): string => {
  const description = `Map of ${basemapName(screen)} in ${placeName}`;

  if (
    screen === Screen.VulnerablePopulations &&
    vulnerablePopulationsBaseMap !== BaseMapLayer.none
  ) {
    const vulnerablePopulationsBasemap =
      vulnerablePopulationsBaseMap === BaseMapLayer.riskToHomes
        ? basemapName(Screen.RiskToHomes)
        : vulnerablePopulationsBaseMap === BaseMapLayer.wildfireLikelihood
        ? basemapName(Screen.WildfireLikelihood)
        : basemapName(Screen.RiskReductionZones);
    return `${description} and ${vulnerablePopulationsBasemap}`;
  } else {
    return description;
  }
};

export const accessibleChartLabel = (
  chartTopic: string,
  placesCount: number | undefined | null,
  geographyLevel: GeographyLevel,
  geography: string,
  comparisonLevel: ComparisonLevel,
  comparisonCountyName?: string,
  comparisonStateName?: string
): string => {
  const geographyLevelText =
    geographyLevel !== GeographyLevel.tribal_area ? geographyLevel : "tribal areas and counties";
  const comparisonText =
    comparisonLevel === ComparisonLevel.national
      ? "the US"
      : comparisonLevel === ComparisonLevel.state
      ? comparisonStateName
      : comparisonCountyName;
  const label = `Scatter chart of ${
    placesCount || ""
  } ${geographyLevelText} in ${comparisonText} according to their ${chartTopic}`;
  return label;
};

export const accessibleDataTableLabel = (
  topic: string,
  placesCount: number | undefined | null,
  geographyLevel: GeographyLevel,
  comparisonLevel: ComparisonLevel,
  comparisonCountyName?: string,
  comparisonStateName?: string
): string => {
  const geographyLevelText =
    geographyLevel !== GeographyLevel.tribal_area ? geographyLevel : "tribal areas and counties";
  const comparisonText =
    comparisonLevel === ComparisonLevel.national
      ? "the US"
      : comparisonLevel === ComparisonLevel.state
      ? comparisonStateName
      : comparisonCountyName;
  const label = `${topic} for ${placesCount || ""} ${geographyLevelText} in ${comparisonText}`;
  return label;
};
