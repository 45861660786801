import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const CombinedLogos = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="combinedLogos"
      color="white"
      size="xlarge"
      viewBox="0 0 5544 700"
      a11yTitle="Logo"
      {...props}
    >
      <g transform="matrix(1.25 0 0 -1.25 153.57 205.66)">
        <g>
          <path
            d="M1118.6,45.1L1118.6,45.1l6.2,7.2l-5.7,7.7l-72,97.4l-5.3,7.2l-8.4-3c-53.6-19.2-104.6-27.9-165.3-28.2
			c-60.7,0.3-111.7,9-165.3,28.2l-8.4,3l-5.3-7.2l-72-97.4l-5.7-7.7l6.2-7.2c50.9-59.9,30.2-132.5,11.9-196.5
			c-8.8-30.7-17.1-59.8-17.1-85.4c0-73.7,45.9-97.3,114.9-109.9c8.9-1.6,18.7-2.8,29.1-4c36-4.2,76.8-8.9,103.1-35.8l8.6-8.8
			l8.6,8.8c26.2,26.9,67,31.7,103.1,35.8c10.4,1.2,20.2,2.4,29.1,4c69,12.7,114.9,36.2,114.9,109.9c0,25.7-8.3,54.7-17.1,85.4
			C1088.4-87.4,1067.7-14.8,1118.6,45.1z M1004.6-323.2c-8.1-1.5-17.6-2.6-27.6-3.7c-34.8-4-77.3-9.1-108.9-35.1
			C836.5-336,794-331,759.3-327c-10,1.2-19.4,2.3-27.5,3.7c-71.2,13.1-95.2,34.9-95.2,86.3c0,22.3,7.8,49.8,16.1,78.8
			c18.3,64,40.8,143.1-10.7,211.2l61.3,82.9c53.2-18.1,104.5-26.3,164.9-26.6h0.1l0,0c60.4,0.3,111.7,8.6,164.9,26.6l61.3-82.8
			c-51.5-68.1-29-147.2-10.7-211.2c8.3-29.1,16.1-56.5,16.1-78.8C1099.8-288.3,1075.8-310.1,1004.6-323.2z"
          />
          <path
            d="M850,102.3c-1,0.2-0.8-0.9-1.3-1.4c-8.3-0.9-17.2-1.5-25.1-1.9c-0.7,0.1-0.5,2.1-1.6,0.9v-9.1
			c1.3-0.7,0.7,1.1,1.8,1.4l8.9,1v-37c-0.3-0.4-0.7-1.1-1.3-1.1c0.1-0.3-0.1-0.8,0.1-1.1c3.2,0,6.4,0.5,9.3,0.8
			c0.7,1.4-1.3,0.7-1.3,2.1l-0.1,36.3l0.3,0.5l8,0.5c1-0.1,1.2-0.9,1.7-1.6c0.3-0.1,0.5,0.1,0.7,0.3L850,102.3L850,102.3z
			 M888.2,101.1c-2.9,0-5.8-0.7-8.4-2.1c-4.5-3.1-5.4-8.4-4.8-13.6c0.7-3.1,2.4-6.5,5.6-7.9c4.9-2.7,11.1-2.2,15.5-6.2
			c1.1-1.3,1.2-3.2,1.1-4.8c-0.3-2.2-1.9-4.6-4.1-5.3c-5.7-1.1-11.2,1.1-14.7,5.6c-0.5,0.7,0.5,2.6-0.9,2.5c-1.5-3-2.7-6.1-4-9.1
			c0.6-0.5,1.1,0,1.7,0c2.9-2.4,6.1-4.3,9.6-5.1c5.6-1.3,12-0.8,16,3.5c3.1,3.9,3.8,9.7,2.3,14.4c-2.9,7.9-12,8.3-18.7,10.7
			l-2.3,1.7c-1.2,1.3-1,3.3-0.8,5c0.3,1.4,1.2,2.9,2.6,3.5c4.8,2,10.5,0.1,14.2-3.4c1.1-0.8,0.1-3.2,1.8-3l3.5,9.1
			c-0.5,0.8-1.1-0.1-1.6-0.1C898.1,99.5,893.1,101.1,888.2,101.1z M905.4,99.3c-0.3-1.2,1.4-1.2,1.4-2.3V56.1
			c-0.3-0.6-0.6-1-1.3-1.1c-0.3-0.3,0.1-0.7,0-1.1l25.5-3c1.1-0.1,1-1.8,2.1-1.8c0.5,3.1,0,6.3,0.1,9.5c-1.2,0.9-0.6-0.9-1.4-1.1
			L914,59.6l-0.5,0.3l0.2,13.1l10.5-1.3c1.4,0.1,1.1-1.9,2.3-1.9c0.2,3.2,0.2,6.6,0,9.8c-1,0.2-0.7-1.1-1.5-1.3
			c-4,0.2-7.8,0.9-11.5,1.6l0.2,12.2l16.6-2.1c1.3-0.1,0.8-2.4,2.3-1.8l-0.2,9.5c-1.1,0.4-0.7-0.9-1.3-1.3
			C922.9,97.3,913.8,98.4,905.4,99.3L905.4,99.3z M810.3,97.3c-4.9,0.3-9.9-1.4-13.5-4.7c-4.3-3.6-5-9.4-4.3-14.9
			c0.7-3.1,3.2-5.5,6.3-6.2c5.1-1.7,10.5,0.3,14.9-3.1c1.3-2.3,1-5.3-0.1-7.5c-1.1-2-3.3-3.1-5.3-3.7c-4.6-1.2-9.7-0.3-12.5,3.5
			c-0.6,0.7,0.7,2.8-1.1,2.2l-3.5-8.9l0.5-0.9c0.3,0.1,0.6,0.2,1.1,0.2c2.1-1.1,4.1-2.4,6.5-2.9c6.6-1,13.1,0.5,17.8,5
			c4,4.4,5.4,11.5,2.9,17.1c-1.7,3.4-5.1,4.4-8.3,5c-4.1,0.9-8.8-0.1-12.3,2.7c-1.1,1.6-0.7,3.6-0.3,5.4c0.8,2.3,3.3,3.9,5.5,4.5
			c4.1,0.4,8.6,1.1,11.3-2.4c0.7-0.6-0.2-2.9,1.5-2.2c1.1,3.3,2.3,6.6,3.5,9.9l-1,0.1c-1.4-2-2.9,0.4-4.5,0.7
			C813.6,96.9,811.9,97.3,810.3,97.3z M934.3,95.8c-0.5-1.1,1.1-1.3,1.1-2.3l0.1-40.9c-0.1-0.5-0.7-1.4-1.4-1.1l-0.2-0.9
			c3-1,6.4-1.3,9.5-1.6c0.5,1.3-1.4,1.3-1.5,2.4v16.5l0.3,0.5c2.1-0.3,4.4-0.5,6.4-1.1c2.1-6,4.8-11.7,6.9-17.7
			c0.4-0.7,0.1-1.9-0.8-1.9c0.1-0.4-0.1-0.9,0.3-1.1c3.3-0.4,6.5-1.7,9.8-1.9c0.5,1.3-1.1,0.7-1.5,1.6c-3.1,6.4-5.4,13.3-7.9,20
			l3.7,0.8c4.8,3.5,4.8,10.3,3.6,15.8C959,95.4,944.5,92.7,934.3,95.8z M789.5,95.3c-0.8-0.2-0.4-1.6-1.3-1.8l-24.3-5.6
			c-0.6-1.4,1-0.6,1.1-1.7l0.1-41.1c-0.1-0.6-0.8-1.3-1.5-1.4l0.5-1.2c8.3,2.1,16.7,3.8,25.1,5.7c0.8-0.3,0.3-1.9,1.5-1.1v9.6
			c-1.3,0.3-0.7-1.5-1.7-1.7c-5.8-1.2-11.5-2.9-17.3-3.5l0.2,12.9l9.4,2.3c1.3,0.8,1.9-0.7,2.7-1c0.6,2.9,0.2,6.2,0.3,9.3l-0.3,0.5
			c-0.9-0.1-0.9-1.1-1.3-1.6c-3.7-0.9-7.3-2.2-11.1-2.6l0.2,12.2l15,3.3c0.9,0.3,2.5,0.4,2.4-0.9c0.3,0.1,0.7-0.1,0.8,0.1l-0.1,9.5
			C789.7,95.3,789.6,95.3,789.5,95.3z M962,90c-0.2-1,1.1-1.3,1.3-2.1c3.6-15.1,8.1-29.7,11.5-44.9c-0.5-0.5-0.4-1.1,0-1.5l8-2.1
			c0.7,1.3-1.2,1.2-0.8,2.4c3,12.1,6.8,23.8,9.8,35.9c0.5,0.9,0.5,2.4,1.9,2.5l0.3,1c-2.7,0.9-5.4,1.8-8.1,2.4
			c-0.5-1,0.8-1.3,0.9-1.9c-2.8-10-5.1-20.2-8.3-30.1l-0.2,0.1L969.9,86c0.3,0.5,0.5,0.9,1.1,0.9c-0.1,0.3,0.1,0.9-0.2,0.9
			C967.9,88.6,964.9,89.7,962,90L962,90z M942.2,88.1c5-1.4,12.4-0.5,14.7-6.4c0.3-2.3,0.7-5.2-0.3-7.5c-0.7-1.1-1.9-1.3-3.1-1.6
			c-3.6,0.7-7.7,1.2-11.3,2.3V88.1z M756.4,84.5c-8.3-0.1-14.9-3.9-22.4-5.9c-0.5-1.2,1.1-1.1,1-2.1V35.9c0-1-2-1.7-0.8-2.7l8.5,2.9
			c0.6,0.9-0.4,1.1-0.9,1.4L741.6,55c2,0.8,4.3,1.6,6.3,1.9l6.4-13c1.8-1.4-0.7-2.8-0.2-4.3c3.1,1,6.3,1.8,9.5,2.7l-0.6,1.2
			c-0.4,0-0.9,0.3-1.2,0.7l-7.4,15.2c1.7,1.1,3.5,2.2,4.8,4c3.1,5.3,5,12,2.1,17.9C760.2,83,758.4,84.1,756.4,84.5z M994.3,81.1
			l-0.2-1c0.5-0.3,0.8-1,1-1.5l0.2-40.3c0.1-0.8-0.7-1.7-1.5-1.6c0.1-0.3-0.1-0.8,0.1-1.1c3-0.9,6-2.2,9-2.6
			c0.1,1.2-1.2,1.4-1.5,2.4l-0.1,40.3c0.1,0.6,0.5,1.7,1.4,1.5v0.9C1000,79.1,997.2,80.1,994.3,81.1L994.3,81.1z M752.7,77.8
			c0.6,0,1.1-0.1,1.7-0.2c2.5-1.9,2.1-5.5,1.6-8.3c-2.3-6.1-9.6-5.5-14.4-7.7v12.9C745.3,75.5,748.7,77.9,752.7,77.8z M720.6,73.6
			c-0.9-0.1-1.8-0.2-2.6-0.4c-5-1.5-9.3-6.5-12-10.9c-4.9-9.1-7.3-22.7-2.1-32.5c1.7-2.8,4.5-4.7,7.7-4.8
			c7.7,0.5,14.1,6.1,17.6,12.8c5,9.7,6.9,25.1-1.3,33.8C726,73.3,723.3,73.8,720.6,73.6z M1014.6,72.9c-1.9-0.1-3.7-0.7-5.2-1.8
			c-5.4-5.3-5.9-12.9-5.4-20.5c1.6-10.6,7.3-23.2,18.8-26.1c4.1-1.6,7.3,1.5,10.9,0.9c-1.5,3.3-2.8,6.7-4.5,9.9
			c-0.9-0.1-0.3-1.5-0.5-2.2c-0.6-1.5-2.1-2.1-3.5-2.3c-4.2-0.3-7.3,2.1-10.1,5.1c-5.4,7.1-7.1,17.7-3.5,26.2c1.3,2.2,3.5,4.2,6.2,4
			c4-1,8.2-3.8,10.2-7.8c0.5-1-0.3-2.9,1.3-3.1l4,6.1c-0.2,0.5-0.7,1-1.4,0.8c-2.8,3-4.9,6-8.3,8.2C1021,72,1017.7,73.1,1014.6,72.9
			z M701.4,66.6c-1.1-0.1-0.5-1.6-1.5-2.1l-22.9-12.5v-1c0.5,0,1.1,0.1,1.1-0.5V9.7c-0.1-1-2.1-2-0.7-2.7l7.9,4.7
			c-0.1,0.3,0.1,0.8-0.2,1.1c-0.3-0.3-0.8,0-1.1,0.2L684,30.6l10.4,5.5l1-1l0.5,0.5l-0.2,9.4c-1.4-0.1-0.3-1.9-1.7-2.4l-9.7-5.1
			l-0.3,0.3l0.1,11.7l15.1,8c0.7,0.5,1.7,0.7,1.9-0.5c0.3-0.1,0.4,0.1,0.6,0.3C701.5,60.4,701.7,63.6,701.4,66.6z M719.8,66.6
			c0.9,0,1.8-0.2,2.5-0.5c6.3-5,5.6-14.5,3.7-21.6c-1.5-5.3-5.3-11.3-11.2-12.3c-2.5-0.5-4.5,1.3-5.7,3.2
			c-4.1,9.4-1.9,21.3,4.9,28.7l3.2,2.1C718,66.5,718.9,66.6,719.8,66.6z M1035.1,64.5c-0.5-1.1,1.3-1.4,1.1-2.4l0.3-40
			c0.4-1.7-2-1.2-1.5-2.9l21.9-11.5c1.7-0.3,1.3-2.6,2.7-2.7v9.5c-1,1-0.5-0.7-1.5-0.7l-16,8.8l0.2,13.1c3.2-2.1,6.8-3.4,9.9-5.6
			c0.9-0.5,0.2-2.2,1.4-1.9v9.5l-0.5,0.3l-0.7-1c-3.5,1.3-6.9,3.7-10.4,5.5l0.2,12.1c5.1-2.8,10.4-5.2,15.2-8.5
			c0.9-0.5,0.1-1.9,1.3-1.9c0.3,2.9,0.1,6.3,0.1,9.1l-0.5,0.7c-0.9,0.2-0.1-0.9-0.8-1C1050.2,56.6,1042.7,61.2,1035.1,64.5
			L1035.1,64.5z M868.9,52.1c-0.8-0.1-1.3-0.7-1.8-1.4c0.4-2.8-2.3-5.1-1.4-8c-2.8-3.2-3.2-7.9-6.7-10.6c0-1.3,0-2.7,0-4
			c-2.9-2.5-4.3-5.8-5.4-9.3c-3.7-3.1-5.5-7.7-9.4-10.6c0.8-1.7-2.1-2.2-1.3-4c1.3,0.4,2-0.4,2.7-1.3c-0.1-1.4,0.5-3.2-1.4-4
			c0.6-0.9,1.4-1.7,2.7-1.3c1.3,1.3,2.7,2.7,4,4c1.3,0.4,2-0.4,2.7-1.3c-6.1-9.4-9.4-20.3-16.2-29.2c-1.3,0.4-2-0.4-2.7-1.3
			c0-0.9,0-1.8,0-2.7c2.1-0.4,3.8-1.4,5.3-2.7c-1.3-1.3-2.7-2.6-4-4c0-0.9,0-1.8,0-2.7c-0.9-0.9-1.8-1.8-2.7-2.7l1.3-1.3
			c-2.3-1.8-3.9-4.2-5.4-6.6c0.9-0.9,1.8-1.8,2.7-2.7c1.3,0,2.7,0,4,0c0.9,0.9,1.8,1.8,2.7,2.7c0.9-0.7,1.7-1.4,1.3-2.7
			c-4.1-5.2-9.4-9.6-12.1-15.9c-2.8-2-4.2-5.8-8-6.6c-1.1-1.9-4.1-2.4-4-5.3c1.3,0.4,2-0.4,2.7-1.3c1,2.5,4.4,0.1,5.4,2.6
			c0.4-0.5,0.9-0.9,1.3-1.3c0.4-1.3-0.4-2-1.3-2.7c0.4-0.4,0.9-0.9,1.3-1.3c-1.7-2.3-2.9-4.9-5.4-6.6c0.4-0.4,0.9-0.9,1.3-1.3
			c0.4-1.3-0.4-2-1.3-2.7c-0.3-1.2,0-1.7,1.3-1.3c1.6,1.6,4.2,1.8,5.4,4c1.3,0.4,2-0.4,2.7-1.3c-2.2-1.3-1.1-3.5-1.4-5.3
			c-8.2-6.7-12.5-16.7-20.1-23.9c1.9,0.2,3.2-2.2,5.3-1.4c1.3,1.3,2.7,2.7,4,4c0.4-0.5,0.9-0.9,1.3-1.3c1.7-0.7,2.9,0.1,4,1.3
			c0.9-0.7,1.7-1.4,1.3-2.7c-4.3-5.9-9.6-10.9-14.7-15.9c1.3,0.3,2-0.4,2.7-1.3c-2.5-3.3-6.8-4.6-9.4-8c0.3-1.8-0.9-4,1.3-5.3
			c-0.9-0.9-1.8-1.8-2.7-2.7c-0.1-1.5,0.5-3.2-1.4-4c2.2-0.5,4.2-2.1,6.7-1.4c2.2,2.3,5.9,1.4,8,4c0.4-0.5,0.9-0.9,1.3-1.3
			c2.2,0.4,4.8-1.2,6.7,1.3c0.5-0.5,0.9-0.9,1.3-1.3c3.4,1.6,7.1,1.4,10.7,1.3c2.9,1.9,5.8,3.9,8,6.6c2.6-1,5.2-1.9,8-1.4
			c1.7,3.1,4.4,5.4,6.7,8c3.2-18.6,0.1-37.4,1-56c-1.3-1.3-2.7-2.7-4-4c-9.5-3.6-18.9-7.3-28.1-11.9c-1.8,0-3.6,0-5.3,0
			c-2.4-1.8-5-3.3-8-4c-0.9-0.6-1.7-1.4-1.3-2.7c9.8,1,19.7-2.1,29.3,1.2c7.2-0.2,14.6,0.8,21.3-2.8c9,0.2,18.1-1.2,26.7,2.5
			c4.4-0.8,9.2,1.7,13.3-1.4c2.2,0.5,4.9-1.2,6.7,1.3c3.6,0.1,7.2,0.2,10.6-1.4c0,1.3,0,2.7,0,4c-2.1,0.4-3.8,1.4-5.3,2.7
			c-1.8,0-3.5,0-5.3,0c-9.2,3.9-17.4,9.6-26.6,13.5c-1.3,1.3-2.7,2.7-4,4c0.1,18.2,0.2,36.4,0.3,54.7c2.1,0.5,0.6,3.5,2.7,4
			c3.5-2.8,3.8-7.5,6.6-10.7c3.5-1.2,7.4-0.6,10.6-2.7c1.3-0.4,2,0.4,2.7,1.3c3.5-0.7,7.4,1.5,10.7-1.4c2.5-3.4,6.3-5.2,9.3-8.1
			c0.4,0.5,0.9,0.9,1.3,1.3c0.4,1.3-0.4,2-1.3,2.7c2.5,3.3,6.9-0.7,9.4,2.6c2.5-1.7,2.9-5,5.3-6.7c1.3-0.4,2,0.4,2.7,1.3
			c0.4,1.3-0.4,2-1.3,2.7c0.1,2.9,3.5,4.8,2.7,8c-4.6,5.4-7.9,11.6-11.9,17.4c-0.4,1.3,0.4,2,1.3,2.7l2.7-2.7
			c2.5,0.8,4.2-2.2,6.6-1.4c0,0.9,0,1.8,0,2.7c-4.6,4.4-7.3,10.4-11.9,14.7c0.9,1.9,2.5,1.2,4,1.3c-4.1,3.5-7.2,7.9-10.6,12.1
			c0.9,2.1-1.5,3.4-1.3,5.3c-2.3,1.8-3.8,4.2-5.3,6.7c0.9,1.9,2.6,1.2,4,1.3c0.9-1.9,2.5-1.2,4-1.4c2.3,2.7,5.4,0.7,8,1.3
			c-2,3.8-7.2,3.1-9.3,6.7c1.2,3.1-2.9,4-2.7,6.7c-4.6,4.4-8.1,9.7-11.9,14.7c-0.3,1.3,0.4,2,1.3,2.7c1.3-1.3,2.7-2.7,4-4
			c1.5,0.1,3.1-0.6,4,1.3c1.3,0.4,2-0.4,2.7-1.3c0.4,0.4,0.9,0.9,1.3,1.3c-0.9,0.7-1.7,1.4-1.3,2.7c-0.7,0.9-1.4,1.7-2.7,1.3
			c-3.4,2.9-5.4,6.5-6.6,10.7c-1.8,1.8-3.5,3.6-5.3,5.4c0.9,0.6,1.7,1.4,1.3,2.7c-1.1,2.2-3.7,2.4-5.3,4c1.5,2.9,5.2-0.2,6.7,2.6
			c-4.7,5-9.8,9.8-14.6,14.7c-0.4,1.3,0.4,2,1.3,2.7c1.3,0,2.7,0,4,0c0.5-2.1,3.5-0.6,4-2.7c0.5,0.4,0.9,0.9,1.3,1.3
			c-1.9,2.9-3.9,5.8-6.6,8c-2.9,9-7.2,17.3-11.9,25.4c0.1,1.5-0.5,3.2,1.3,4c1.7,0.8,4.2-1.7,5.3,1.3c-4.2,3.2-6.3,7.9-9.3,12
			c-2.3,7.7-5.9,14.8-10.6,21.4C869.4,52.1,869.1,52.1,868.9,52.1L868.9,52.1z M994-8.5c-22.2,0.5-44.9-10.6-44.9-38.1
			c0-40,80-58.7,80-98c0-39.3-64.7-32-64.7,0v4h-14V-186h13.3v10.6c36.6-28.6,90-2.6,90,41.4c0,48-78,61.3-78,92
			c0,28,46.7,20.7,46.7,0v-6.7h13.3v37.3h-14v-4.6C1014-11.3,1004.1-8.7,994-8.5z M695.5-11.3c0,0,0.7-13.3,0-13.3h4.6v-120
			c0-57.3,92.7-59.3,92.7,0c0,0-0.7,120,0,120h10.7c-0.7,0-0.7,13.3,0,13.3h-36v-13.3h10v-120c0-33.3-50-32-50,0
			c-0.7,0-0.7,120,0,120h5.3c0,0-0.7,13.3,0,13.3C732.7-11.3,695.5-11.3,695.5-11.3z M1052.2-188.8l-7-1.1l-0.6-1.1c0-0.6,0-4.9,0-6
			l0.1-13.4h0.7c0,0.6,0.5,1.1,2.2,1.1l35.6,0.2c1.8,0,2.3-0.5,2.3-1.1l0.7,0l-0.2,18.8c0,1-1.1,1.2-0.3,1.2l-1.3,0.5l-5.6-0.1v-0.5
			c0.8,0,1.4-0.2,1.4-1.2l0.1-10.8h-14.5v0.1l-0.1,7c0,1,0.6,1.2,1.4,1.2v0.5l-8.6-1.1v-0.5c0.8,0,1.4-0.2,1.5-1.2l0.1-6v0l-9.2-0.1
			v0l0.1,11.9c0,1,0.6,1.2,1.4,1.2L1052.2-188.8L1052.2-188.8z M650.1-190.6l-0.3-10.2c-0.2-9.1,9.5-13.2,21-13
			c13.7,0.2,20.7,4.9,20.5,12.1l0.3,10.1l-0.6,0c0-0.6-0.4-1.1-2-1.1l-36.1,0.9c-1.6,0-2,0.6-2,1.2
			C650.8-190.6,650.1-190.6,650.1-190.6z M656.6-197.9L656.6-197.9l29.8-0.7c-0.2-5.6-5.3-9.7-15-9.5
			C661.7-207.9,656.5-204.3,656.6-197.9z M1057.8-212.5c-5.6-0.3-11.7-1.5-12.3-12.5l-0.1-11.8l0.7,0c0,0.5,1.3,2.1,3,2l34.3-2.6
			c1.7,0.1,2.1-0.5,2.2-1.3l0.7,0l0.5,9.8l-0.7,0c0.1-0.7-0.3-1.4-2-1.5l-14.6,0.7l0,3.1l14,4c0.7,0.3,1,0.3,1.2,0.3
			c0.6,0,1-0.5,1.1-1.1l0.7-0.1l0.1,9l-0.7,0c0-1.2-0.7-1.9-1.9-2.3l-14.7-3.6C1067.6-217.3,1063.3-212.1,1057.8-212.5L1057.8-212.5
			z M691.5-214.6c0-0.6-0.4-1.1-2.2-1.2h0l-36.6-0.5c-1.8,0-2.2,0.5-2.2,1.1h-0.7l0.3-19.2c0-1,1.1-1.2,0.3-1.2l1.3-0.5l5.6,0.1
			l0,0.5c-0.8,0-1.4,0.2-1.4,1.2l-0.2,13.3l13.9,0.2v0l0.1-6.9c0-1-0.6-1.2-1.4-1.2v-0.5l8.6,1.1l0,0.5c-0.8,0-1.4,0.2-1.4,1.2
			l-0.1,5.9v0.1l11,0.1v0l0.2-9.8c0-1-0.6-1.2-1.4-1.2v-0.5l7,1.1l0.3,1.1c-0.8,0-0.1,0.5-0.1,1.5l-0.3,13.7L691.5-214.6
			L691.5-214.6z M1057.5-219.3c4.1,0,5.7-4.4,6-7.4l-0.2-2.2l-11.1,0.4l0,3.6C1052.1-222.8,1053.7-219.3,1057.5-219.3z M692.2-234.2
			l-0.7-0.1c0.1-0.6-0.3-1.1-2.1-1.3l-36.5-3.9c-1.7-0.2-2.3,0.3-2.3,0.9l-0.7-0.1l1.2-8.9l0.7,0.1c-0.1,0.6,0.3,1.1,2.1,1.3
			l14.6,1.7l1.9-5.7c0.8-6.4,6.1-8.3,12.9-7.6c7.5,0.8,11,6.2,10.2,12.5C693.4-245.4,692.2-234.2,692.2-234.2z M1045.5-237.8
			l-1.3-7.3l0.6-0.2c0.1,0.6,0.7,1,2.2,0.6l23.3-5.5c4.2-0.9,6.8-2.3,5.6-6c-1-3.1-3.7-3.2-7.6-1.8l-22.6,8.6
			c-1.5,0.5-1.7,1.2-1.5,1.7l-0.6,0.2l-3.2-7.3l0.6-0.2c0.2,0.6,0.8,0.9,2.3,0.4l23.2-8.7c6.7-2.4,13.4-1.5,15.9,5.7
			c2.3,6.5-1.8,11.8-10.4,12.9l-24.1,5.1c-1.5,0.4-1.8,1-1.7,1.6L1045.5-237.8L1045.5-237.8z M686.8-242.6l0.4-4.2
			c0.5-2.6-1.2-4.5-5.2-5c-3.1-0.4-5.1,0.7-6.4,4l-1.1,4.1L686.8-242.6z M696.6-254.7l-0.5-0.3c0.2-0.5,0-0.9-0.6-1.2
			c-0.4-0.3-0.9-0.5-1.2-0.6l-31.6-11.9c-1.9-0.7-2.3-0.6-2.8-0.1l-0.5-0.4l5.1-7.8l0.5,0.4c-0.1,0.4,0,0.8,0.5,1.2
			c0.2,0.1-0.2,2.7,0.4,0.7l3.4,0.9l4.8-6.3l-0.2-2.1c-0.4-0.4-0.9-1-1.4-1.3c-0.6-0.4-1-0.5-1.4-0.1l-0.5-0.4l4.4-6.6l0.5,0.3
			c-0.4,0.5,0.4,1.4,1,2l22.1,25.6c0.3,0.4,0.6,0.7,0.9,0.9c0.6,0.4,1,0.4,1.4,0.1l0.5,0.4L696.6-254.7L696.6-254.7z M1039.8-255.1
			c-0.1,0-0.3-0.2-0.5-0.4l-9.3-10.6c-0.5-0.6-0.3-0.7-0.3-0.7l0-0.5l4.3-4.7l0.3,0.4c-0.6,0.5-0.9,1-0.2,1.8l3.5,3.7l25.8-21.1
			c1.2-1.1,1.2-1.7,0.8-2.2l0.5-0.4l5.7,6.4l-0.5,0.4c-0.4-0.5-1.7-1.3-2.9-0.3l-26.3,20.9l3.1,3.5c0.6,0.8,1.2,0.6,1.7,0.1l0.3,0.4
			l-4.9,2.7l-0.7,0.5C1040-255.2,1039.9-255.1,1039.8-255.1L1039.8-255.1z M703.3-263.7l-0.4-0.5c0.4-0.3,0.7-2.1-0.2-3.5
			l-19.4-26.9c-1-1.3-1.7-1.4-2.2-1.1l-0.4-0.5l5.7-5.2l0.4,0.5c-0.5,0.4-0.7,1,0.3,2.4l8.5,11.9l3.3-2l0.1-16.5
			c0-0.8-0.2-1-0.3-1.2c-0.2-0.5-0.7-0.8-1.2-0.6l-0.3-0.6l7.3-3.3l0.3,0.6c-0.9,0.4-1.2,1.3-1.1,2.5l0.4,17.8c3,0,6.8,0.3,9.7,5.1
			c2.9,4.8,3.3,11.1-2.3,14.9L703.3-263.7L703.3-263.7z M688.5-265.2L688.5-265.2l-9.5-12l-4.9,5.4L688.5-265.2L688.5-265.2z
			 M1028.1-267.4l-6.5-4.5l0.4-0.6c0.5,0.3,1.2,0.3,2-1.1l17.8-29.7c0.9-1.3,0.7-2,0.2-2.3l0.3-0.5l14.6,9.3
			c0.8,0.5,0.6,0.4,1.2,1.3l0.2,1l-1.9,6.2l-0.4-0.3c0.4-0.6-0.1-1-0.9-1.6l-10.1-6.7l-16.5,26.5c-0.9,1.3-0.7,2-0.2,2.3
			L1028.1-267.4L1028.1-267.4z M1020.4-272.4l-7.2-2.1l0.1-0.6c0.6,0.1,1.2-0.2,1.5-1.7l7.8-22.5c1.5-4,1.7-7-2.1-8
			c-3.2-0.8-4.6,1.5-5.5,5.5l-4.7,23.8c-0.3,1.6,0.1,2.1,0.7,2.2l-0.1,0.7l-7.6-1.6l0.1-0.6c0.6,0.1,1.2-0.2,1.5-1.7l4.7-23.8
			c1.5-6.9,5.8-12.1,13.3-10.5c6.7,1.5,9,7.8,5.4,15.7l-8.4,22.6c-0.3,1.5,0.1,2.1,0.7,2.2L1020.4-272.4L1020.4-272.4z M705.2-273.2
			l2.5-1.7c1.5-1,3.6-3.3,1-7.1c-2.3-3.4-6.3-2.6-8.5-1.5l-1.4,1.3C698.7-282.2,705.2-273.2,705.2-273.2z M721-273.4
			c-0.7,0.1-0.8-0.1-0.8-0.1l-0.5-0.2l-2.2-7l0.5-0.1c0.1,0.7,0.4,1.2,1.4,1.1l6.2-1.3l-5.6-30.1c-0.3-1.6-0.9-1.9-1.5-1.8l-0.1-0.6
			l8.4-1.8l0.1,0.6c-0.6,0.1-1,0.6-0.7,2.2l5.1,30.2l5.7-1c1-0.2,1.1-0.7,0.9-1.5l0.5-0.1l0.1,6.5l0,0.8c0,0.5-0.1,0.6-0.7,0.7
			L721-273.4z M992.3-277.3c-0.6,0-1.3-0.1-2-0.2c-8.6-1.6-8.8-11.9-6.9-22.1c1.9-10.2,8-18.7,16.4-17.1c6.6,1.2,6,3.2,7.4,4.7
			c0.1,0.1,0.3,3,0.4,3l-0.8,1.3l-4.5,3.9l-0.3-0.4c0.1-0.3,0.2-0.5,0.2-0.8c0.5-2.6-0.8-6.2-4.1-6.6c-3-0.4-7,6.2-8.4,13.5
			c-1.3,7.2-2.8,13.1,3,14.8c1.5,0.4,4.5-0.9,4.9-2.9c0.1-0.5,0.3-0.8,0.2-1l0.5-0.2l2.7,5.1l0,1.6c0,0-0.5,0.7-0.7,0.7
			c-0.3-0.1-1.4,0.8-3.1,1.5C995.8-277.7,994.3-277.2,992.3-277.3L992.3-277.3z M740.9-277.9l-0.1-0.6c0.6-0.1,1-0.6,0.8-2.2
			l-5-33.1c-0.2-1.6-0.8-1.9-1.4-1.8l-0.1-0.6l8.1-1.3l0.1,0.7c-0.6,0.1-1,0.6-0.8,2.2l2.3,15.1h0.1l2.6-19.1l9,17.6l0.1,0l-2-15.6
			c-0.2-1.6-0.8-1.9-1.4-1.9l-0.1-0.6l8.7-1.1l0.1,0.7c-0.6,0.1-1,0.6-0.8,2.2l4,33.9c0.2,1.6,0.8,1.9,1.4,1.8l0.1,0.7l-7.3,0.4
			l-0.8-0.4c-0.2-0.4-0.3-0.3-0.4-0.9c0-0.3-0.1-0.5-0.2-0.8l-6.7-14.5l-0.1,0l-3.1,15.3c-0.1,0.7-0.2,1.3-0.1,1.6
			c0,0.3,0,0.2,0,0.7l-0.9,0.9L740.9-277.9L740.9-277.9z M978.6-280.8l-7.3-0.7l0-0.7c0.6,0.1,1.2-0.3,1.3-1.9l6-34.2
			c0.2-1.6-0.2-2.1-0.8-2.2l0.1-0.6l7.2,1.1l-0.1,0.6c-0.6-0.1-1.1,0.3-1.4,1.9l-6,33.9c-0.1,1.6,0.3,2.1,0.9,2.1L978.6-280.8
			L978.6-280.8z M771.1-281.7l-0.1-0.7c0.6-0.1,1.1-0.5,0.9-2.3l-3.1-34.1c-0.1-1.7-0.7-2.2-1.3-2.1l-0.1-0.7l18.8-1.7
			c1-0.1,1.3,1,1.2,0.2l0.6,1.3l0.4,5.6l-0.5,0.1c-0.1-0.8-0.3-1.4-1.3-1.3l-10.8,0.9l1.1,11.3h0l6.9-0.5c1-0.1,1.2-0.7,1.1-1.5
			l0.5-0.1l-0.3,8.7l-0.5,0c-0.1-0.8-0.3-1.4-1.3-1.3l-5.9,0.4h-0.1l0.9,11h0l11.8-1.2c1-0.1,1.2-0.7,1.1-1.5l0.5,0l-0.5,7l-1,0.7
			c-0.6,0-4.9,0.5-5.9,0.5L771.1-281.7L771.1-281.7z M933.7-283.1c-6.7-0.4-9.3-8-9.1-19.8c0.3-13.7,5.4-23.4,12.5-23.1
			c4.1,0.2,7.2,1.7,11,7.1v0.7c-0.5,0-0.7,0.5-0.8,1.9l0.2,11.5c0,1,0.3,1.5,0.7,1.6l0,0.7l-9.8-0.2c-1,0-0.7-0.2-1.6,0.1l-0.5-0.5
			l-0.4-6.9h0.5c0,0.8,0.2,1.4,1.2,1.5l4.5,0.1l-0.3-8.6c-0.9-2.2-2.2-2.9-4.8-2.8c-2.6,0.1-5.1,6.1-5.3,13.7
			c-0.2,9.2-0.3,17.4,4.7,16.9c2.5-0.3,5-2.7,4.8-5.5l-0.1-1l0.3-0.4l3.1,6.4l-0.1,1.4c0,0-0.2,0.6-0.4,0.8
			c-0.6,0.6-0.6,0.8-2.1,2.1c-1.5,1.2-4,2.1-6.7,2.3C934.7-283.1,934.2-283.1,933.7-283.1L933.7-283.1z M961.7-283.2
			c-0.9,0.1-2,0.1-3.1,0l-10.9-0.2l0.1-0.7c0.5,0.1,1.9-1.1,2.1-2.7l3-34.1c0.3-1.6-0.2-2.2-0.8-2.3l0.1-0.7l8.3,0.5l-0.1,0.7
			c-0.6-0.1-1.3,0.2-1.5,1.9l-1.2,14.5l2.6,0.3l7.1-13.8c0.4-0.7,0.4-0.9,0.4-1.2c0-0.6-0.3-1-0.8-1.1l0.1-0.7l8.8,1.1l-0.1,0.7
			c-1-0.1-1.7,0.6-2.2,1.7l-7.3,15.9c2.2,1.9,5,5.4,4,10.9C969.4-287.6,968.2-283.7,961.7-283.2L961.7-283.2z M794.1-283.6l0-0.7
			c0.6,0,1.1-0.5,1-2.1l-2.5-35.3c-0.1-1.6-0.6-2-1.2-2l0-0.6l7.3-1l0,0.6c-0.6,0-1.1,0.5-1,2.1l1.5,15.5h0.1l6.9-16
			c0.3-0.8,0.5-1.2,0.5-1.6c0-0.4,0.4-1.1,0.5-1.5l0-0.6l6.5-0.3l0,0.7c0.2,0.5,0.2,0.7,0.3,2.3l2.2,35.4c0.1,1.6,0.6,2,1.2,2l0,0.7
			l-8.3,1.1l0-0.6c0.6,0,1.1-0.5,1-2.1l-0.9-24.9H809l-7.1,22.6c-0.3,0.7-0.3,1.1-0.4,1.6c-0.3,1.1-1.1,2.4-1.4,3.3l-1,0.8
			C799.2-284.4,794.1-283.6,794.1-283.6z M914.9-286.2l-8.1-0.3v-0.7c0.6-0.1,0.7-0.5,0.8-1.2c0-0.5-0.1-1.1-0.2-1.4l-7.5-35.1
			c-0.5-2.1-0.8-2.4-1.5-2.5l0-0.7l7.8,0.1l0,0.7c-0.4,0.2-0.5,1.1-0.5,1.7c0,0.3,0.1,0.9,0.2,1.3l1.2,2.7l10.3,0.3l0.5-2.2
			c0.1-0.6,0.3-0.9,0.3-1.5c0-0.7-0.1-1.6-0.7-1.8l0-0.7l7.9,0.2l0,0.7c-0.7,0-1,1.2-1.2,2.1l-9.7,34.8c-0.1,0.5-0.3,1-0.3,1.4
			c0,0.7,0.3,1.1,0.7,1.3V-286.2L914.9-286.2z M859.1-286.2c-7.5,0-11.6-10.2-11.7-21.1c0-11,4-21.2,11.5-21.3
			c7.5,0,11.6,10.2,11.7,21.1C870.7-296.5,866.6-286.3,859.1-286.2z M821.4-286.4c-0.7,0-0.8-0.2-0.8-0.2l-0.4-0.3l-1-7.3h0.5
			c0,0.7,0.2,1.3,1.2,1.3l6.4-0.2l-1.9-32c0-1.6-0.5-2-1.1-2l0-0.6l8.6-0.3l0,0.7c-0.6,0-1.1,0.4-1.1,2l1.3,32.1l5.8-0.1
			c1,0,1.2-0.5,1.2-1.3l0.5,0l-1,6.4l-0.1,0.8c-0.1,0.5-0.2,0.6-0.8,0.6L821.4-286.4L821.4-286.4z M890.4-287.7c-0.3,0-0.5,0-0.8,0
			l-15.7,0v-0.6c0.6,0,1.1-0.4,1.1-2l0.3-35.2c0-1.6-0.5-2-1.1-2l0-0.7h8.3v0.6c-0.6,0-1.1,0.4-1.1,2l-0.2,16.2h0h5.8
			c1,0,1,0.1,2,0.1l0.2,1l1.3,6h-0.5c0-0.7-0.2-1.3-1.2-1.3h-7.3h0l-0.2,10h0l10.2-0.4c1,0,1.2-0.5,1.2-1.3h0.5l-1.1,6.2l-0.9,1.3
			C890.9-287.8,890.6-287.7,890.4-287.7L890.4-287.7z M959.4-288.5c1.8-0.1,4.3-1.5,4.6-4.7c0.5-4.1-3-6.1-5.5-6.7h-1.9l-1.1,11.1
			l3,0.3C958.9-288.4,959.1-288.4,959.4-288.5L959.4-288.5z M859.2-293.7c3.6,0,5.3-6.8,5.3-13.8c0-7-1.7-13.7-5.4-13.7
			c-3.6,0-5.3,6.8-5.3,13.8C853.8-300.4,855.5-293.7,859.2-293.7z M911.9-300.9h0.1l4.2-14.6l-8-0.3L911.9-300.9z"
          />
        </g>
      </g>
      <g>
        <path
          d="M4.7,507v105.5h758.2l0.1-212.4c0,0-53.6-0.8-112.2,0.8c-54.5,1.4-113.3,5.1-137.4,7.2
		c-92.4,8.2-180.5,19.8-251.4,32.4c-45.8,8.2-84.3,17.7-112.4,23.7c-16.3,3.5-63.2,17-79.3,21.5"
        />
        <path
          d="M472.8,383.7c-65.6,5.1-208.7,27.1-279.4,43.5c-26.9,6.2-65.4,15.1-103.2,25.8c-23.3,6.6-25.5,7.7-59.3,18.7
		c-10.5,3.4-20.6,6.7-27.2,10.1c11-5.8,29.4-15.7,46.9-23.6C72,449.9,90.4,442,90.4,442s25.5-10.2,59-20.6
		c15.4-4.8,31.6-9.8,48.3-14.3c14.3-3.8,29.2-7,41.6-10.3c73.2-19,158.9-29.6,199-35.1c40.1-5.5,147.8-16,207.1-17.6
		c97.2-2.5,117.1-1.5,117.1-1.5v31.5c0,0-62.6-1.9-112.6,0C572.3,377.1,539.3,378.6,472.8,383.7z"
        />
        <path
          d="M4.6,394.9v30.4c0,0,8.5-2.8,19-5c14.3-3,33.2-5.9,47.7-8c25.2-3.7,117.3-15.8,117.3-15.8s-23.8-2.3-53.5-3
		c-36.5-0.9-69.1-0.1-84.2-0.1"
        />
        <path
          d="M4.6,342.5v30.4c0,0,33.2-3.4,74.9-2c16.4,0.6,48.6,1.5,81.6,3.4c50.8,2.9,103.6,7.1,103.6,7.1
		s-26.7-9.7-49.2-14.7c-11.4-2.5-33.4-7.2-58-11.2c-21.4-3.5-47.8-6.9-72.1-8.5C41.4,343.9,4.6,342.5,4.6,342.5z"
        />
        <g>
          <path
            d="M10.4,179.6c0-44.8,0-55,0-64.4c0-10.6-2.3-15.8-6.5-18.4C0,94.4,0,94.4,0,94.4s4,0.2,7.5,0
			c5.9-0.4,11.7,0,16.9,0c7.1,0,21.8,0,32.3,0c4.4,0,6.5-0.6,4.7,0.5c-3.1,2.1-4.4,2-6.1,5.1c-4.9,8.6-2.4,4.9-3,15.2
			c-0.6,9.4,0,17.7,0,62.5v41.2c0,42.7,1.2,58,14.9,70.4c13.5,12.2,25.1,12.1,35.1,12.1c13.7,0,28-2.7,39.2-14.9
			c16.2-17.8,13.3-40.4,13.3-70.7l-0.2-38.2c0-44.8,0.3-53-0.3-62.5c-0.6-10,0-6.1-2.5-15.4l-2-2.3l-3.9-3.2c0,0,0.9,0,5,0
			c15,0,13.7,0,25.2,0c4.1,0,7,0,7,0s-1.5-0.4-4.2,3.2c-3.5,4.6-3.5,4.6-4.1,14.9c-0.6,9.4-0.5,18.6-0.5,63.4l-0.4,32.6
			c0,32.7,3.6,66.5-16.9,89.5c-18.4,20.5-42,22.3-61.1,22.3c-15.6,0-43.8-1.9-65.2-21.4c-15-13.5-23.2-35.3-20.5-78.1L10.4,179.6z"
          />
          <path
            d="M216.8,311.6c-6-2.4-16.4-7.3-16.4-7.3s-1.5-36.6-1.2-41.3c0.3-4.4-0.3-13.9-0.3-13.9s5.3,14.7,6.4,16.8
			c2.1,3.8,6.5,12,10.1,15.6c17.4,17.6,29.8,22.5,46.3,21.4c11-0.7,17.8-3.8,23.3-7.6c7.2-5,11.5-12,14.2-22.5
			c3.8-15,0.3-31.7-26.9-46.3l-20.7-9.7c-46.2-22.9-52.8-43.3-51.8-68.1c0.7-17.5,9.5-35.5,26.9-45.5c15.9-9.2,26.8-12.4,45.6-12.4
			c21.8,0,38.7,7.9,46.3,11.5c2.5,1.2,1.1,13.3,1.1,15.4c0,3.8,0.5-4.6,0.5,18.4c0,6.5,0.8,15.3,0.8,15.3s-3.6-9.6-5.6-13.5
			c-3.2-6.1-5.8-9.9-13.2-16.3c-5.8-5.1-13.5-12.4-32.7-13.7c-21.5-1.4-35,9.9-37,28.1c-1.5,13.9,0.6,23.7,33.6,39.7l20.9,9.8
			c46.8,20.1,53.6,42.8,51.5,72.6c-1.6,21.3-11.6,36.5-32.7,50.3C291,317.8,275,320,257.6,320C240.6,320,229.6,316.8,216.8,311.6z"
          />
          <path
            d="M368.5,177.6c0-44.8,0-52.4,0-62.5c0-10,0-6.1-1.7-13.9c-0.5-2.4-0.7-1.9-2.7-3.9c-2.3-2.3-4.6-3.1-4.6-3.1
			s2.1,0.1,6.5,0.1c15.6,0,11.2,0,19.5,0c9.1,0,26.4,0,44,0c36.7,0,82.6,1,114.6,34.6c14.7,15.3,30.8,36.6,30.8,71.6
			c0,37.1-16.4,63.7-32.8,80.5c-13.5,13.8-42.3,36.5-96.7,36.5c-10.6,0-51-1-58.1-0.7c-6.8,0-14.4,0-20.3,0c-3.8,0-6.9,0-6.9,0
			s2.3-1.7,4.8-4c4.7-4.5,3.7-12,3.7-20.6c0-13.8,0-35.7,0-62.8V177.6z M409.7,210.1c0,31.2,0.2,90,0.2,90s4-0.3,12-0.3
			c5.5,0,12.9,0.9,22.2-0.2c30-3.5,41-7.5,58.9-22.6c8.7-7.3,13.4-16.1,17.3-25c5.1-11.7,7.9-24.4,9.1-41.1
			c2.6-35.1-11.6-61.8-24-74.2c-25.8-25.9-56.3-25.9-79.2-25.9c-5.9,0-16.6,0.8-16.6,0.8s0.1,38.1,0.1,61.3V210.1z"
          />
          <path
            d="M611,255.3l-14.1,33.2c-4.7,12-7.3,17.7-3.9,23.4l7.1,4.9c0,0-21.1,0-36.3,0c-3.8,0-8.5,0-8.5,0
			s1.7-0.8,3.5-2.3c2.6-2.2,8.1-7.1,9.1-9.2c7-14.1,3.8-6.4,9.6-21.1l75-174c3.5-8.5,9.7-22.7,9.7-22.7s0.9,2.2,3.9,9
			c8.3,19.1,57.3,134,77.7,179.9c5.7,12.8,8.9,21.1,11.7,26.4c3.1,5.8,5.6,8.1,7.1,9.1c2.4,1.6,8.1,4.9,8.1,4.9s-8.2,0-19,0
			c-10.6,0-13,0.6-36.5,0c-5.3-0.3-14,0-14,0s6.6-4.7,8-7.3c2.1-3.8,0.1-9.2-1.4-11.9l-17.2-42L611,255.3z M682.9,238.4l-33.2-80.2
			c0,0-28,69.6-32.3,79.7C617.5,237.9,661.1,238.7,682.9,238.4z"
          />
        </g>
      </g>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,134.4)">
        <g transform="scale(0.1)">
          <path
            d="M19797.8-1153h-636.3L18974,153.4L18786.5-1153h-636.4l-408.8,2056.5l617.9,86.1l166-1284.9L18675.8,968
			h654.8l147.6-1263.4l166,1263.4h574.8L19797.8-1153"
          />
          <path d="M20309.1-1153V968h608.7V-1153H20309.1" />
          <path d="M21143.7-1153V968h608.7V-621.2h461.1l86.1-531.8L21143.7-1153" />
          <path
            d="M23009.7,500.8h-6.1V-682.7h6.1c209,0,375,92.2,375,590.2C23384.7,408.6,23218.7,500.8,23009.7,500.8z
			 M23003.5-1153h-608.7V968h608.7c682.4,0,1002.2-341.2,1002.2-1057.5C24005.7-811.8,23673.6-1153,23003.5-1153"
          />
          <path d="M24774.9,457.7V128.8h359.7l86-522.6h-445.7V-1153h-605.6V968h1155.9V457.7H24774.9" />
          <path d="M25455.4-1153V968h608.7V-1153H25455.4" />
          <path
            d="M26871.1-64.8c162.9,0,270.5,64.6,270.5,292c0,215.2-107.5,282.8-270.5,282.8h-6.2V-64.8H26871.1z
			 M26898.8-476.8h-24.6V-1153h-584.1V968h608.7c482.6,0,808.5-212.1,808.5-691.7c0-267.4-113.8-439.6-285.9-562.6l381.2-805.4
			l-602.5-83L26898.8-476.8"
          />
          <path d="M27892.6-1153V968h1198.9V485.4h-602.5v-292h418.1l86.1-501.1h-504.2v-362.8h627.1V-1153H27892.6" />
          <path
            d="M30422.6-64.8c162.9,0,270.5,64.6,270.5,292c0,215.2-107.5,282.8-270.5,282.8h-6.2V-64.8H30422.6z
			 M30450.2-476.8h-24.6V-1153h-584V968h608.7c482.6,0,808.5-212.1,808.5-691.7c0-267.4-113.7-439.6-285.9-562.6l381.2-805.4
			l-602.5-83L30450.2-476.8"
          />
          <path d="M31443.8-1153V968h608.7V-1153H31443.8" />
          <path
            d="M32893.6-1177.6c-249,0-479.6,49.1-688.6,144.5l76.8,501c218.3-104.5,387.4-162.9,538-162.9
			c113.8,0,166,49.2,166,119.9c0,95.3-67.7,153.7-304.4,292c-212.1,123-448.7,319.7-448.7,627.1c0,399.6,273.5,648.6,682.4,648.6
			c227.5,0,393.4-36.9,565.6-110.6l-76.9-504.2c-175.1,98.4-316.6,132.2-433.4,132.2c-70.7,0-132.2-43-132.2-101.5
			c0-70.7,55.4-119.9,282.8-252.1c218.3-126,473.4-332,473.4-670.1C33594.4-947.1,33308.5-1177.6,32893.6-1177.6"
          />
          <path
            d="M34677.8-1174.6l-344.3,946.8V-1153h-608.7V968h608.7V79.6l316.6,888.4h599.5l-396.6-980.6l433.5-1063.6
			L34677.8-1174.6"
          />
          <path d="M36891.1,451.6V-1153h-608.7V451.6h-427.3V968h1410.9l79.9-516.4L36891.1,451.6" />
          <path
            d="M38232,537.7c-169.1,0-224.4-221.3-224.4-630.2c0-408.8,58.4-630.2,224.4-630.2
			c159.9,0,218.2,221.3,218.2,630.2C38450.3,316.3,38388.8,537.7,38232,537.7z M38229-1193c-556.4,0-842.3,405.8-842.3,1097.4
			c0,691.6,292,1103.6,848.4,1103.6c550.3,0,836.1-405.8,836.1-1097.4C39071.2-781.1,38779.2-1193,38229-1193"
          />
          <path
            d="M18887.9-4230.6c-694.6,0-1053.4,465.7-1053.4,1351.1c0,866.4,377.9,1358.7,1034.3,1358.7
			c259.5,0,435.1-49.6,595.4-129.8l-99.2-625.9c-145,91.6-240.5,137.4-385.5,137.4c-225.2,0-373.9-217.5-373.9-736.6
			c0-522.9,145-736.6,438.9-736.6c99.2,0,206.1,42,358.7,125.9l99.2-599.2C19303.9-4173.3,19124.5-4230.6,18887.9-4230.6"
          />
          <path
            d="M20571.1-2093.3c-209.9,0-278.6-274.8-278.6-782.4c0-507.6,72.5-782.4,278.6-782.4
			c198.5,0,271,274.7,271,782.4C20842.1-2368,20765.7-2093.3,20571.1-2093.3z M20567.3-4242c-690.8,0-1045.8,503.8-1045.8,1362.5
			c0,858.7,362.6,1370.1,1053.5,1370.1c683.1,0,1038.1-503.8,1038.1-1362.5C21613-3730.6,21250.5-4242,20567.3-4242"
          />
          <path
            d="M23685.9-4192.4l-53.5,1763.3l-202.3-1763.3h-751.8l-202.3,1763.3l-64.9-1763.3h-656.4l95.4,2633.5h1053.3
			l179.4-1580.1l179.4,1580.1h1049.6l99.2-2633.5H23685.9"
          />
          <path
            d="M26523.1-4192.4l-53.5,1763.3l-202.3-1763.3h-751.8l-202.3,1763.3l-64.9-1763.3h-656.5l95.4,2633.5h1053.4
			L25920-3139l179.4,1580.1h1049.6l99.3-2633.5H26523.1"
          />
          <path
            d="M28415.8-4230.6c-622.1,0-969.4,335.9-969.4,889.3V-1559h755.7v-1824.3c0-167.9,91.6-263.3,217.5-263.3
			c129.8,0,213.7,95.4,213.7,263.3V-1559h755.7v-1782.3C29389-3929,29041.7-4230.6,28415.8-4230.6"
          />
          <path d="M30909.6-4192.4l-572.4,1255.7v-1255.7h-690.8V-1559h740.4l484.8-1160.2V-1559h690.8v-2633.5H30909.6" />
          <path d="M31834.9-4192.4V-1559h755.7v-2633.5H31834.9" />
          <path d="M33993.2-2200.1v-1992.3h-755.7v1992.3H32707v641.2h1751.8l99.2-641.2L33993.2-2200.1" />
          <path d="M34665.1-4192.4V-1559h755.7v-2633.5H34665.1" />
          <path d="M35691-4192.4V-1559h1488.4v-599.2h-748v-362.6h519l106.9-622.1h-625.9v-450.4h778.6v-599.2H35691" />
          <path
            d="M38165-4222.9c-309.2,0-595.3,61.1-854.9,179.4l95.4,622.1c271-129.7,480.9-202.2,667.9-202.2
			c141.2,0,206.1,61,206.1,148.8c0,118.3-84,190.8-377.8,362.6c-263.3,152.7-557.2,397-557.2,778.6c0,496.1,339.6,805.3,847.3,805.3
			c282.4,0,488.5-45.8,702.3-137.4l-95.4-625.9c-217.6,122.1-393.1,164.1-538.2,164.1c-87.8,0-164.1-53.4-164.1-125.9
			c0-87.8,68.7-148.9,351.1-313c271-156.5,587.8-412.2,587.8-832C39035.3-3936.7,38680.3-4222.9,38165-4222.9"
          />
          <path
            d="M14651.8-3738.3c0-158.3,0-316.5,0-474.8l939.9-0.4c0,158.4,0,316.8,0,475.1c-25.8,25.6-51.6,52-77.5,78.6
			c-24.7,25.6-49.4,51.5-74.2,77.7c-5.2,5.5-10.4,10.8-15.6,16.4c0,71.9,0,143.7,0,215.5c-41.6,3.1-83.4,5.5-125.1,7.3
			c0-29.5,0-59.1,0-88.6c-59.1,63.7-118.3,127.7-177.5,190c-120.2-126.7-240.5-259.7-360.2-384.7l0,0
			C14724.9-3664.3,14688.3-3702,14651.8-3738.3"
          />
          <path
            d="M15648.4-3738.3v-475.1l260.8-0.1c-0.1,0.1-0.2,0.1-0.3,0.2c104.6-1.8,204.7,5.6,300.2,20.6
			c0,144.7,0,289.2,0,433.9c-55.6,55.3-111.4,113.4-167.3,172.7c0,71.8,0,143.6,0,215.5c-41.7,3.1-83.4,5.5-125.2,7.3
			c0-29.5,0-59,0-88.6c-59.1,63.8-118.3,127.7-177.5,190.1c-86.1-90.7-172.2-184.4-258.1-276.4v-4.8
			c57.3-60.6,105.5-110.2,150.6-155C15642.4-3708.7,15648.4-3723.2,15648.4-3738.3"
          />
          <path
            d="M14372.1-4212.9l223,0v474.7c0,15.1,6,29.6,16.7,40.2c37,36.7,74.3,75.3,111.6,114.3c0,71.1,0,142.2,0,213.2
			c-41.6,3.1-83.4,5.5-125.1,7.3c0-29.5,0-59.1,0-88.6c-59.1,63.8-118.3,127.7-177.5,190.1c-157-165.4-314.1-342-469.9-497
			c0-141.3,0-282.5,0-423.7C14080.5-4202.2,14220.6-4212.9,14372.1-4212.9"
          />
          <path
            style={{ fill: "#bf1b2c", stroke: "none" }}
            d="M17415.3-2439.8c-89.5,380.4-348.4,722.9-568.4,1056.7c-177.3,269.1-561.3,626.1-559.1,940.8
			c-76.4-615.6,497.5-1191.8-257.3-1686.3c334.7,756.2-358,960.5-722.3,1536.7c-335.7,531.1,129.7,1091.3,332.6,1581.5
			c0,0-962.4-640.4-1214-1379.6c-107.4-269.7-55.8-511.1,57.2-832.7c-640.7,66.6-752.3,705.6-594.5,1038.2
			c-713.7-416.3-211.5-1108.6-211.8-1649.1c-0.1-175.1-202.5-765.1-514.2-456.2c-139.4,138.2,43.1,517.6-73.8,722.4
			c-61.9-445-455.4-738.7-455.8-1214.7c-0.5-623.6,365.6-1232.2,1260.2-1389.6v30.6c-197.8,88.5-283.6,247.7-271.2,433.7
			c10.5,156.2,270,620.3,327.8,767.9c63.4,161.8,104.9,370.9,84.1,533.3c152.5-67.4,314.8-228.5,413.8-334.5
			c188.6,280,247.1,700.6,99.6,1010c-8.4,17.7,13.7,33.9,35.1,26.4c62.2-21.8,130.8-18.1,312.3-118.1
			c136-75,299.6-190.2,398.4-296.1c92-98.8,170.2-218.9,201.3-340.4c30.9-120.8,10.1-211.8,7.5-325.5
			c220.9-26.6,648.2,165.2,852.2,378.4c-46.8-383.6,174-364.1,311.5-609.8c112.4-200.7,116.4-870.2-401-1109.2v-55.2
			C17131.4-4011.2,17589.6-3180.9,17415.3-2439.8"
          />
        </g>
      </g>
    </StyledIcon>
  </ThemeProvider>
);

export default CombinedLogos;
