import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { VulnerablePopulationsAction } from "../actions";
import {
  ActionTypes,
  vulnerablePopulationsFetchSuccess,
  vulnerablePopulationsFetchFailure
} from "../actions/vulnerablePopulations";

import { fetchVulnerablePopulations } from "../api";
import {
  BaseMapLayer,
  GeographyVulnerablePopulationData,
  NormalizedTractId,
  VulnerablePopulationTractDictionary,
  VulnerablePopulationTractInfo,
  VulnerablePopulationTractInfoResource,
  VulnerablePopulationMetricType
} from "../models";
import { Resource } from "../resource";

export interface VulnerablePopulationsState {
  readonly selectedVulnerablePopulations: ReadonlyArray<string>;
  readonly rankedVulnerablePopulations: ReadonlyArray<string>;
  readonly tractInfo: Resource<VulnerablePopulationTractInfoResource>;
  readonly selectedBaseMapLayer: BaseMapLayer;
  readonly selectedPlaceId: string | undefined;
  readonly selectedModalTract: NormalizedTractId;
  readonly selectedPanelTract: NormalizedTractId;
  readonly displayExpandedMenu: boolean;
  readonly defaultHighlightedTractIds: number[];
  readonly selectedHighlightedTractIds: number[];
  readonly isWidgetInitialState: boolean;
}

export const initialState: VulnerablePopulationsState = {
  selectedVulnerablePopulations: [],
  rankedVulnerablePopulations: [],
  tractInfo: { isPending: false },
  selectedBaseMapLayer: BaseMapLayer.none,
  selectedPlaceId: undefined,
  selectedModalTract: undefined,
  selectedPanelTract: undefined,
  displayExpandedMenu: false,
  defaultHighlightedTractIds: [],
  selectedHighlightedTractIds: [],
  isWidgetInitialState: true
};

// Returns 'true' if the given tract exceeds the given geography's values for all selected indicators
const tractAboveAverage = (
  tractData: GeographyVulnerablePopulationData,
  selectedIndicators: VulnerablePopulationMetricType[],
  geoData: GeographyVulnerablePopulationData
) => {
  return selectedIndicators.every(indicator => {
    const tractVal = tractData[indicator as keyof typeof tractData];
    const geoVal = geoData[indicator as keyof typeof geoData];
    return (
      tractVal && tractVal.pct && geoVal && geoVal.pct_median && tractVal.pct >= geoVal.pct_median
    );
  });
};

const calculateHighlightedTracts = (
  tractInfo: VulnerablePopulationTractInfo,
  placeId: string,
  selectedIndicators: VulnerablePopulationMetricType[]
) => {
  // If no indicators are selected, don't highlight any tracts.
  if (selectedIndicators.length === 0) return [];
  const intersectingTracts = tractInfo.geos.tracts as VulnerablePopulationTractDictionary;
  const selectedGeoData = tractInfo.geos[placeId];
  const highlightedTractIds = Object.entries(intersectingTracts)
    .filter(tract => tractAboveAverage(tract[1].data, selectedIndicators, selectedGeoData))
    .map(tract => parseInt(tract[0], 10));
  return highlightedTractIds;
};

const VulnerablePopulationsCollectionReducer: LoopReducer<
  VulnerablePopulationsState,
  VulnerablePopulationsAction
> = (
  state: VulnerablePopulationsState = initialState,
  action: VulnerablePopulationsAction
): VulnerablePopulationsState | Loop<VulnerablePopulationsState, VulnerablePopulationsAction> => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_VULNERABLE_POPULATIONS:
      return {
        ...state,
        selectedVulnerablePopulations: action.selectedVulnerablePopulations,
        selectedHighlightedTractIds:
          "resource" in state.tractInfo
            ? calculateHighlightedTracts(
                state.tractInfo.resource.tractInfo,
                state.selectedPlaceId || "",
                action.selectedVulnerablePopulations as VulnerablePopulationMetricType[]
              )
            : []
      };
    case ActionTypes.VULNERABLE_POPULATIONS_FETCH:
      return loop(
        {
          ...state,
          selectedPlaceId: action.geoId,
          tractInfo: { isPending: true },
          selectedVulnerablePopulations: [],
          rankedVulnerablePopulations: []
        },
        Cmd.run(fetchVulnerablePopulations, {
          args: [action.geoId],
          successActionCreator: vulnerablePopulationsFetchSuccess,
          failActionCreator: vulnerablePopulationsFetchFailure
        })
      );
    case ActionTypes.VULNERABLE_POPULATIONS_FETCH_SUCCESS:
      // In contrast to most other success handlers which just set the data on state and let the
      // components figure out how to display it, here we also need to fire off a new action to
      // select the top two most highly ranked vulnerable population variables.
      return loop(
        {
          ...state,
          rankedVulnerablePopulations: action.tractInfo.variablesRanked,
          tractInfo: {
            resource: {
              tractInfo: action.tractInfo
            }
          },
          defaultHighlightedTractIds: calculateHighlightedTracts(
            action.tractInfo,
            state.selectedPlaceId || "",
            action.tractInfo.variablesRanked.slice(0, 2)
          ),
          isWidgetInitialState: true
        },
        Cmd.action<VulnerablePopulationsAction>({
          type: ActionTypes.SET_SELECTED_VULNERABLE_POPULATIONS,
          selectedVulnerablePopulations: action.tractInfo.variablesRanked.slice(0, 2)
        })
      );
    case ActionTypes.VULNERABLE_POPULATIONS_FETCH_FAILURE:
      return {
        ...state,
        tractInfo: { errorMessage: action.errorMessage }
      };
    case ActionTypes.SET_SELECTED_BASE_MAP_LAYER:
      return {
        ...state,
        selectedBaseMapLayer: action.selectedBaseMapLayer
      };
    case ActionTypes.SET_SELECTED_MODAL_TRACT:
      return {
        ...state,
        selectedModalTract: action.selectedModalTract
      };
    case ActionTypes.SET_SELECTED_PANEL_TRACT:
      return {
        ...state,
        selectedPanelTract: action.selectedPanelTract
      };
    case ActionTypes.SET_DISPLAY_EXPANDED_MENU:
      return {
        ...state,
        displayExpandedMenu: action.displayExpandedMenu
      };
    case ActionTypes.SET_IS_WIDGET_INITIAL_STATE:
      return {
        ...state,
        isWidgetInitialState: action.isWidgetInitialState
      };
    default:
      return state;
  }
};

export default VulnerablePopulationsCollectionReducer;
