import styled from "styled-components";

const Chart = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  font-size: 1.3rem;
  overflow: hidden;
`;

const ChartContainer = styled.div`
  flex: 1;
  height: 400px;
  overflow: hidden;
`;

const ChartLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ChartCorner = styled.div`
  height: 20px;
`;

const ChartXAxis = styled.div`
  width: 100%;
  height: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

const TextRotate = styled.div`
  transform: rotate(-90deg) translateY(-90px) translateX(-100%) translateX(30px);
  width: 220px;
  text-align: center;
  height: 20px;
`;

const ChartYAxis = styled.div`
  width: 20px;
  height: 400px;
`;

const Tooltip = styled.div`
  color: inherit;
  font-size: 13px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 5px 9px;
  background-color: #fff;
`;

const TooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 6px;
`;

const TooltipProperty = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 4px;
`;

const TooltipValue = styled.div`
  font-size: 12px;
  margin: 0;
  font-weight: 400;
`;

const TooltipColor = styled.div`
  background-color: ${props => props.color};
  position: relative;
  top: 2px;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
`;

export {
  Chart,
  ChartContainer,
  ChartLeft,
  ChartRight,
  ChartCorner,
  ChartXAxis,
  TextRotate,
  ChartYAxis,
  Tooltip,
  TooltipTitle,
  TooltipProperty,
  TooltipValue,
  TooltipColor
};
