import { GeographyList } from "../models";

export enum ActionTypes {
  GEOLIST_CLEAR = "GEOLIST_CLEAR",
  GEOLIST_FETCH = "GEOLIST_FETCH",
  GEOLIST_FETCH_SUCCESS = "GEOLIST_FETCH_SUCCESS",
  GEOLIST_FETCH_FAILURE = "GEOLIST_FETCH_FAILURE"
}

// There are separate actions for making the API request and handling both its
// success and failure scenarios
export type GeographyListAction =
  | { readonly type: ActionTypes.GEOLIST_CLEAR; readonly geographies: readonly never[] }
  | { readonly type: ActionTypes.GEOLIST_FETCH; readonly searchTerm: string }
  | {
      readonly type: ActionTypes.GEOLIST_FETCH_SUCCESS;
      readonly geographies: GeographyList;
    }
  | { readonly type: ActionTypes.GEOLIST_FETCH_FAILURE; readonly errorMessage: string };

export function geographyListClear(): GeographyListAction {
  return {
    type: ActionTypes.GEOLIST_CLEAR,
    geographies: []
  };
}

export function geographyListFetch(searchTerm: string): GeographyListAction {
  return {
    type: ActionTypes.GEOLIST_FETCH,
    searchTerm
  };
}

export function geographyListFetchSuccess(geographies: GeographyList): GeographyListAction {
  return {
    type: ActionTypes.GEOLIST_FETCH_SUCCESS,
    geographies
  };
}

export function geographyListFetchFailure(errorMessage: string): GeographyListAction {
  return {
    type: ActionTypes.GEOLIST_FETCH_FAILURE,
    errorMessage
  };
}
