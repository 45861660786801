import React, { useMemo } from "react";
import { Box, Heading } from "grommet";

import { RRZDataSet, RRZClass } from "../models";
import RRZChartItem from "./RiskReductionZoneChartItem";
import RRZChartLegend from "./RiskReductionZoneChartLegend";
import { RRZStructureScale, rrzColors } from "../constants";

interface StateProps {
  readonly rrz: RRZDataSet;
  readonly size: string;
  readonly structureScale: null | RRZStructureScale;
}

interface RRZChartData {
  readonly percentage: number;
  readonly count: number;
}

const createRRZChartData = (percentage: number | undefined, total: number | undefined) => ({
  percentage: Math.round((percentage || 0) * 100),
  count: Math.round((percentage || 0) * (total || 0))
});

const RRZChart = ({ rrz, size, structureScale }: StateProps) => {
  const rrzData: Record<string, RRZChartData> = useMemo(
    () => ({
      [RRZClass.Minimal]: createRRZChartData(rrz.buildings_fraction_me, rrz.total_buildings),
      [RRZClass.Indirect]: createRRZChartData(rrz.buildings_fraction_ie, rrz.total_buildings),
      [RRZClass.Direct]: createRRZChartData(rrz.buildings_fraction_de, rrz.total_buildings)
    }),
    [rrz]
  );

  return structureScale ? (
    <Box pad={{ horizontal: "medium", bottom: "medium" }} className="content-box">
      <Heading level={3}>Exposed buildings by zone</Heading>
      <Box
        direction={size === "small" ? "column" : "row"}
        gap="small"
        aria-label="Chart of exposed buildings by zone."
        role="list"
      >
        <RRZChartItem
          label={RRZClass.Minimal}
          percentage={rrzData[RRZClass.Minimal].percentage}
          structureCount={rrzData[RRZClass.Minimal].count}
          themeColor={rrzColors[RRZClass.Minimal]}
          strokeColor="#8D8F99"
          bgColor="rgba(185, 187, 194, 0.08)"
          structureScale={structureScale}
          size={size}
        />
        <RRZChartItem
          label={RRZClass.Indirect}
          percentage={rrzData[RRZClass.Indirect].percentage}
          structureCount={rrzData[RRZClass.Indirect].count}
          themeColor={rrzColors[RRZClass.Indirect]}
          strokeColor="#C28300"
          bgColor="rgba(245, 199, 102, 0.10)"
          structureScale={structureScale}
          size={size}
        />
        <RRZChartItem
          label={RRZClass.Direct}
          percentage={rrzData[RRZClass.Direct].percentage}
          structureCount={rrzData[RRZClass.Direct].count}
          themeColor={rrzColors[RRZClass.Direct]}
          strokeColor={rrzColors[RRZClass.Direct]}
          bgColor="rgba(255, 59, 84, 0.08)"
          structureScale={structureScale}
          size={size}
        />
      </Box>
      {rrz.total_buildings && (
        <RRZChartLegend structureScale={structureScale} totalStructures={rrz.total_buildings} />
      )}
    </Box>
  ) : (
    <></>
  );
};

export default RRZChart;
