import React from "react";
import { Box, Text } from "grommet";

interface TooltipProps {
  readonly label: String;
}

const Tooltip = ({ label }: TooltipProps) => {
  return (
    <Box direction="column" align="center">
      <Text
        color="white"
        size="small"
        style={{
          background: "var(--warm-gray-900)",
          padding: ".1rem .4rem",
          borderRadius: "var(--border-radius)"
        }}
      >
        {label}
      </Text>
      <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.70711 4.29289L10 0H0L4.29289 4.29289C4.68342 4.68342 5.31658 4.68342 5.70711 4.29289Z"
          fill="var(--warm-gray-900)"
        />
      </svg>
    </Box>
  );
};

export default Tooltip;
