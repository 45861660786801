import { Box, Grid, Heading, Text } from "grommet";
import React, { useMemo } from "react";
import styled from "styled-components";

const Swatch = styled(Box)`
  position: relative;
  &:first-child::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

interface StateProps {
  readonly colors: ReadonlyArray<string>;
  readonly colorsLabel: string;
  readonly minBubbleSize: number;
  readonly maxBubbleSize: number;
}

const ChartLegends = (props: StateProps) => {
  const { colors, colorsLabel, minBubbleSize, maxBubbleSize } = props;

  const bubbleLegendSizes: ReadonlyArray<number> = useMemo(() => {
    // Show four bubbles, with the sizes of the middle two interpolated between the min and max
    const sizeIncrement = Math.floor((maxBubbleSize - minBubbleSize) / 3);
    return [
      minBubbleSize,
      minBubbleSize + sizeIncrement,
      maxBubbleSize - sizeIncrement,
      maxBubbleSize
    ];
  }, [minBubbleSize, maxBubbleSize]);

  return (
    <Grid
      columns={["16rem", "11.25rem"]}
      gap="large"
      pad="small"
      style={{
        background: "var(--warm-gray-100)",
        borderRadius: "var(--border-radius)"
      }}
      role="group"
      a11yTitle="Legend"
    >
      <Box gap="small">
        <Heading level={4}>{colorsLabel}</Heading>
        <Grid
          rows={["fill"]}
          columns={{ count: colors.length, size: "auto" }}
          fill={true}
          gap="1px"
          height=".8rem"
          style={{
            borderRadius: "var(--border-radius)",
            overflow: "hidden"
          }}
        >
          {colors.map((color, idx) => (
            <Swatch key={idx} background={color} />
          ))}
        </Grid>
        <Grid rows={["fill"]} columns={["1/3", "1/3", "1/3"]} gap="none">
          <Box direction="row">
            <Text size="small" color="text-800">
              Low
            </Text>
          </Box>
          <Box direction="row">
            <Text size="small" color="text-800">
              Moderate
            </Text>
          </Box>
          <Box direction="row" justify="end">
            <Text size="small" color="text-800">
              High
            </Text>
          </Box>
        </Grid>
      </Box>
      <Box>
        <Heading level={4}>Population</Heading>
        <Box
          direction="row"
          alignSelf="start"
          alignContent="center"
          height="2.7rem"
          pad={{ left: "1.5rem" }}
        >
          {bubbleLegendSizes.map((size, idx) => (
            <div
              key={idx}
              style={{
                border: "solid 1px var(--text-800)",
                borderRadius: 20,
                flexShrink: 0,
                width: size,
                height: size,
                marginLeft: 0,
                marginRight: 10,
                marginTop: (maxBubbleSize - size) / 2 + 8
              }}
            />
          ))}
        </Box>
        <Grid rows={["fill"]} columns={["1/2", "1/2"]} gap="none">
          <Box direction="row">
            <Text size="small" color="text-800">
              Lower
            </Text>
          </Box>
          <Box direction="row" justify="end">
            <Text size="small" color="text-800">
              Higher
            </Text>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ChartLegends;
