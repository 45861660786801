import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const PlusIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 16 16" a11yTitle="Plus" {...props}>
      <path
        d="M8.84375 1.78125V7.40625H14.4688H15.3125V9.09375H14.4688H8.84375V14.7188V15.5625H7.15625V14.7188V9.09375H1.53125H0.6875V7.40625H1.53125H7.15625V1.78125V0.9375H8.84375V1.78125Z"
        fill={props.color}
        stroke="none"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default PlusIcon;
