import React, { useState } from "react";
import { RiskToHomesData, WildfireLikelihoodData } from "../models";

interface AccessibleDataTableProps {
  readonly riskToHomesData?: ReadonlyArray<RiskToHomesData>;
  readonly wildfireLikelihoodData?: ReadonlyArray<WildfireLikelihoodData>;
  readonly description: string;
}

const AccessibleDataTable = ({
  riskToHomesData,
  wildfireLikelihoodData,
  description
}: AccessibleDataTableProps) => {
  const [open, setOpen] = useState(false);

  const columnLabel = riskToHomesData ? "Risk percentile" : "Wildfire likelihood percentile";
  const getColumnValueByRow = (row: RiskToHomesData | WildfireLikelihoodData) => {
    if (riskToHomesData) {
      return (row as RiskToHomesData)["rpsPctRank"];
    } else {
      return (row as WildfireLikelihoodData)["bpPctRank"]!;
    }
  };
  const data = [...(riskToHomesData || wildfireLikelihoodData || [])].sort((a, b) => {
    const aColumn = getColumnValueByRow(a);
    const bColumn = getColumnValueByRow(b);
    return bColumn - aColumn;
  });

  const handleToggle = (event: { currentTarget: { open: boolean } }) =>
    setOpen(event.currentTarget.open);

  return (
    <details onToggle={handleToggle} open={open} tabIndex={-1} className="sr-only">
      <summary>Data table</summary>
      {open && (
        <table>
          <caption>{description}</caption>
          <thead>
            <tr>
              <th scope="col" role="columnheader">
                Place
              </th>
              <th scope="col" role="columnheader">
                Number of housing units
              </th>
              <th scope="col" role="columnheader" aria-sort="descending">
                {columnLabel}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, i) => (
              <tr key={i}>
                <td>{row.name}</td>
                <td>{row.total_housing_units}</td>
                <td>{Math.round(Number(getColumnValueByRow(row)) * 100)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </details>
  );
};

export default AccessibleDataTable;
