import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const MinusIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 16 2" a11yTitle="Minus" {...props}>
      <path
        d="M15.3125 2.09375H14.4688H1.53125H0.6875V0.40625H1.53125H14.4688H15.3125V2.09375Z"
        fill={props.color}
        stroke="none"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default MinusIcon;
