import React, { useRef, useState, useLayoutEffect } from "react";
import { Button } from "grommet";
import styled from "styled-components";
import { vulnerablePopulationsGlossaryTermLabels } from "../constants";
import { VulnerablePopulationMetricType } from "../models";
import { PreviousTerm } from "./VulnerablePopWidgetExpanded";

import PlusIconCircle from "../icons/PlusIconCircle";
import Tooltip from "./Tooltip";

const PillButton = styled(Button)`
  background: white !important;
  border: 1px solid var(--warm-gray-400) !important;
  border-radius: 2rem !important;
  font-size: 1.4rem;
  padding: 0 0.6rem 0 0.6rem !important;
  height: 2.6rem !important;
  display: inline-flex !important;
  align-items: center;
  --svg-background: var(--highlight-100);
  --svg-color: var(--teal-700);
  color: var(--text-900);

  &:hover,
  &:focus-visible {
    border-color: var(--teal-700) !important;
    background: white !important;
    --svg-background: var(--teal-700);
    --svg-color: white;
  }

  &:focus-visible {
    outline: 2px solid var(--teal-700) !important;
    outline-offset: -1px !important;
  }

  &[aria-pressed="true"] {
    background: var(--teal-400) !important;
    border-color: transparent !important;
    color: var(--teal-900);
    font-weight: 700;

    &:hover {
      background: var(--teal-400) !important;
      opacity: 0.8;
    }
  }

  svg {
    margin: 0 -0.6rem 0 -0.3rem;
  }
`;

interface PillProps {
  term: VulnerablePopulationMetricType;
  selected?: boolean;
  previousTerm: PreviousTerm;
  setPreviousTerm: React.Dispatch<React.SetStateAction<PreviousTerm>>;
  handleClick: Function;
}

const Pill = ({ term, selected, previousTerm, setPreviousTerm, handleClick }: PillProps) => {
  const ref = useRef<HTMLAnchorElement & HTMLButtonElement>(null);
  const [style, setStyle] = useState<Object>({});
  // If the component should be animated, ie. fly from one section to another,
  // visually move it to its previous location.
  // useLayoutEffect runs before the component is mounted.
  useLayoutEffect(() => {
    if (ref.current && previousTerm.term === term && previousTerm.position !== null) {
      const origin = previousTerm.position;
      const target = ref.current.getBoundingClientRect();
      // Visually move the component to its previous location
      setStyle({
        transform: `translate(
          ${-1 * (target.left - origin.left)}px,
          ${-1 * (target.top - origin.top)}px
        )`,
        // Prevents flashing a tooltip when transitioning from unselected to selected
        pointerEvents: "none"
      });
      // After mounting, reset the transform with a transition and focus the button
      const timeout = setTimeout(() => {
        setStyle({
          transform: `translate(0,0)`,
          transition: "transform .3s cubic-bezier(0.215, 0.61, 0.355, 1)"
        });
        ref.current && ref.current.focus();
      });
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [previousTerm, term, ref]);

  return (
    <PillButton
      ref={ref}
      key={term}
      label={vulnerablePopulationsGlossaryTermLabels[term].short}
      icon={
        selected ? undefined : (
          <PlusIconCircle
            size="18px"
            background="var(--svg-background)"
            color="var(--svg-color)"
            aria-hidden
          />
        )
      }
      tip={
        selected
          ? {
              content: <Tooltip label="Press to remove" />,
              dropProps: {
                stretch: false,
                align: {
                  top: "top"
                },
                margin: {
                  top: "-2.7rem"
                },
                style: {
                  pointerEvents: "none"
                }
              },
              plain: true
            }
          : undefined
      }
      aria-pressed={selected}
      style={style}
      onClick={e => {
        ref.current &&
          setPreviousTerm({
            term: term,
            position: ref.current.getBoundingClientRect()
          });
        handleClick(term);
      }}
    />
  );
};

export default Pill;
