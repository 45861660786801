export enum ActionTypes {
  POPULATED_AREA_MASK_SET = "POPULATED_AREA_MASK_SET"
}

export type PopulatedAreaMaskAction = {
  readonly type: ActionTypes.POPULATED_AREA_MASK_SET;
  readonly visible: boolean;
};

export function setPopulatedAreaMaskVisibility(visible: boolean): PopulatedAreaMaskAction {
  return {
    type: ActionTypes.POPULATED_AREA_MASK_SET,
    visible
  };
}
