import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const InfoCircleIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="InfoCircleIcon" viewBox="0 0 16 15" a11yTitle="Info" {...props}>
      <path
        d="M8 14.75C5.48438 14.75 3.1875 13.4375 1.92969 11.25C0.671875 9.08984 0.671875 6.4375 1.92969 4.25C3.1875 2.08984 5.48438 0.75 8 0.75C10.4883 0.75 12.7852 2.08984 14.043 4.25C15.3008 6.4375 15.3008 9.08984 14.043 11.25C12.7852 13.4375 10.4883 14.75 8 14.75ZM6.90625 9.9375H6.25V11.25H6.90625H9.09375H9.75V9.9375H9.09375H8.875V7.53125V6.875H8.21875H6.90625H6.25V8.1875H6.90625H7.5625V9.9375H6.90625ZM8.875 6V4.25H7.125V6H8.875Z"
        fill={props.color}
        stroke="none"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default InfoCircleIcon;
