import { Box, Grid, Header, ResponsiveContext, Heading } from "grommet";
import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";

import TakeAction from "../components/TakeAction";
import HowWeCalculate from "../components/HowWeCalculate";
import RiskDetailHeader from "../components/RiskDetailHeader";
import GeographyDescription from "../components/GeographyDescription";
import RiskDetailComponent from "../components/RiskDetail";
import Map from "../components/Map";
import { ComparisonSwitcher } from "../components/ComparisonSwitcher";
import AppFooter from "../components/AppFooter";
import BoundaryLegend from "../components/BoundaryLegend";
import FAQ from "../components/FAQ";
import {
  BaseMapLayer,
  RRZClass,
  Screen,
  riskToHomesColors,
  wildfireLikelihoodColorBreaks
} from "../models";
import { RiskParams } from "../hooks";
import OpenGlossaryButton from "../components/OpenGlossaryButton";
import GlossaryComponent from "../components/GlossaryComponent";
import { getRiskReductionZoneDescription } from "../components/RRZComparisonText";
import { getRiskToHomesDescription } from "../components/RiskToHomesComparisonText";
import { getWildfireLikelihoodDescription } from "../components/WildfireLikelihoodComparisonText";
import PageMeta from "../components/PageMeta";
import { rrzColors } from "../constants";
import { MapLegendComponent } from "../components/MapLegend";
import { MapSelectorComponent } from "../components/VulnerablePopMapSelector";
import VulnerablePopWidgetExpanded from "../components/VulnerablePopWidgetExpanded";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledHeader = styled(Header)`
  background: white;
  border-bottom: 1px solid var(--warm-gray-300);
`;

const getVulnerablePopulationDescription = () =>
  "Potentially vulnerable populations may experience difficulty " +
  "preparing for and responding to wildfire. Learn who is most at " +
  "risk and discover resources to reduce risk.";

const DetailScreen = ({
  screen,
  geography,
  detailGeography,
  detailGeographyId,
  countyGeographies,
  stateGeography,
  geographyLevel,
  ranks,

  // Comparison-related params
  effectiveComparisonLevel,
  comparisonCountyName,
  comparisonStateName,
  mapStyleStateAbbrev,

  // Map params
  showPopulatedAreaMask,

  // Risk factor data params
  riskReductionZone,
  riskToHomes,
  vulnerablePopulations,
  wildfireLikelihood
}: RiskParams) => {
  const size = useContext(ResponsiveContext);
  const pageTitle = useMemo(() => {
    if (!detailGeography) return "";
    const prefix = screen === Screen.RiskReductionZones ? "Wildfire " : "";
    const screenName = screen.replaceAll("-", " ");
    return `${prefix}${screenName.charAt(0).toUpperCase() + screenName.slice(1)} in ${
      detailGeography.name
    }`;
  }, [screen, detailGeography]);

  const pageDescription = useMemo(() => {
    if (!detailGeography) return "";
    switch (screen) {
      case Screen.RiskToHomes:
        return getRiskToHomesDescription(
          detailGeography,
          effectiveComparisonLevel,
          geographyLevel,
          comparisonCountyName,
          comparisonStateName,
          ranks
        );
      case Screen.RiskReductionZones:
        if ("resource" in riskReductionZone && riskReductionZone.resource.rrz[detailGeographyId]) {
          return getRiskReductionZoneDescription(
            riskReductionZone.resource.rrz[detailGeographyId].d,
            detailGeography
          );
        }
        return "";
      case Screen.WildfireLikelihood:
        return getWildfireLikelihoodDescription(
          detailGeography,
          effectiveComparisonLevel,
          geographyLevel,
          comparisonCountyName,
          comparisonStateName,
          ranks
        );
      case Screen.VulnerablePopulations:
        return getVulnerablePopulationDescription();
      default:
        return "";
    }
  }, [
    screen,
    detailGeography,
    riskReductionZone,
    detailGeographyId,
    comparisonCountyName,
    comparisonStateName,
    geographyLevel,
    ranks,
    effectiveComparisonLevel
  ]);

  // Define the props for different legends once so that they can be mixed and matched in the
  // Vulnerable Populations case without repeating ourselves too much.
  const riskToHomesProps = useMemo(
    () => ({
      rampLegend: {
        colors: riskToHomesColors.filter(c => !c.hideInLegend).map(c => c.value),
        legendLabel: `Wildfire risk to homes in ${
          mapStyleStateAbbrev === "US" ? "the US" : detailGeography?.state.name
        }`,
        lowLabel: "Low",
        midLabel: "Moderate",
        highLabel: "High"
      }
    }),
    [mapStyleStateAbbrev, detailGeography]
  );
  const likelihoodProps = useMemo(
    () => ({
      rampLegend: {
        colors: wildfireLikelihoodColorBreaks.filter(c => !c.hideInLegend).map(c => c.value),
        legendLabel: `Wildfire likelihood in ${
          mapStyleStateAbbrev === "US" ? "the US" : detailGeography?.state.name
        }`,
        lowLabel: "Low",
        midLabel: "Moderate",
        highLabel: "High"
      }
    }),
    [mapStyleStateAbbrev, detailGeography]
  );
  const rrzProps = useMemo(
    () => ({
      classLegend: {
        classes: [
          {
            color: rrzColors[RRZClass.Minimal],
            label: RRZClass.Minimal,
            definition: "Homes are not likely to be subjected to wildfire."
          },
          {
            color: rrzColors[RRZClass.Indirect],
            label: RRZClass.Indirect,
            definition:
              "Homes may be ignited by indirect sources such as embers and home-to-home ignition."
          },
          {
            color: rrzColors[RRZClass.Direct],
            label: RRZClass.Direct,
            definition:
              "Homes may be ignited by adjacent flammable vegetation, as well as indirect sources."
          },

          {
            color: rrzColors[RRZClass.Transmission],
            label: RRZClass.Transmission,
            definition: "Area near homes where flammable vegetation may expose homes to wildfire."
          }
        ]
      },
      noLabel: true,
      compact: size !== "large"
    }),
    [size]
  );

  const MobileLegend = useCallback(() => {
    if (size !== "small") return <></>;
    switch (screen) {
      case Screen.RiskToHomes:
        return <MapLegendComponent {...riskToHomesProps} />;
      case Screen.WildfireLikelihood:
        return <MapLegendComponent {...likelihoodProps} />;
      case Screen.RiskReductionZones:
        return <MapLegendComponent {...rrzProps} />;
      case Screen.VulnerablePopulations:
        return <VulnerablePopWidgetExpanded />;
      default:
        return <></>;
    }
  }, [size, screen, likelihoodProps, riskToHomesProps, rrzProps]);

  const VulPopSelector = useCallback(() => {
    switch (vulnerablePopulations.selectedBaseMapLayer) {
      case BaseMapLayer.riskToHomes:
        return <MapSelectorComponent width="100%" {...riskToHomesProps} />;
      case BaseMapLayer.wildfireLikelihood:
        return <MapSelectorComponent width="100%" {...likelihoodProps} />;
      case BaseMapLayer.riskReductionZones:
        return <MapSelectorComponent width="100%" {...rrzProps} />;
      default:
        return <MapSelectorComponent width="100%" />;
    }
  }, [likelihoodProps, riskToHomesProps, rrzProps, vulnerablePopulations.selectedBaseMapLayer]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <PageMeta title={pageTitle} description={pageDescription} />
          <ScreenContainer>
            {"resource" in geography ? (
              <>
                <StyledHeader
                  align={size === "small" ? "start" : undefined}
                  direction={size === "small" ? "column" : "row"}
                  wrap={true}
                  gap="none"
                  pad={{
                    horizontal: "2rem",
                    vertical: "small"
                  }}
                >
                  <Box className="set-width-content-container">
                    <Box
                      direction={size === "small" ? "column" : "row"}
                      fill={true}
                      justify="between"
                      style={{
                        paddingTop: ".5rem"
                      }}
                    >
                      <GeographyDescription
                        countyDescNames={
                          countyGeographies ? countyGeographies.map(c => c.name_short) : []
                        }
                        stateDescName={stateGeography ? stateGeography.name : ""}
                        geographyLevel={geographyLevel}
                        detailPlaceId={detailGeographyId}
                        detailPlaceName={detailGeography ? detailGeography.name_short : ""}
                      />

                      <Box
                        direction="row"
                        gap={size === "small" ? "medium" : "large"}
                        pad={
                          size === "small"
                            ? { top: "medium", bottom: "medium" }
                            : { top: "xsmall", bottom: "xsmall" }
                        }
                        justify="between"
                      >
                        <ComparisonSwitcher
                          comparisonLevel={effectiveComparisonLevel}
                          geographyLevel={geographyLevel}
                          disabled={
                            screen !== Screen.WildfireLikelihood && screen !== Screen.RiskToHomes
                          }
                        />
                        <Box align="end" justify="center">
                          <OpenGlossaryButton />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </StyledHeader>

                <Grid
                  as="main"
                  id="main-content"
                  tabIndex={-1}
                  alignSelf="center"
                  columns={size === "large" ? ["1fr", "1fr"] : ["auto"]}
                  gap={size !== "small" ? "large" : "5rem"}
                  justify="stretch"
                  justifyContent="stretch"
                  margin={{
                    bottom: "xlarge"
                  }}
                  pad={{
                    top: "large",
                    horizontal: "medium"
                  }}
                  rows="auto"
                  width={{ max: "1140px" }}
                >
                  <RiskDetailHeader
                    detailGeography={detailGeography}
                    detailGeographyId={detailGeographyId}
                    vulnerablePopulations={vulnerablePopulations}
                    screen={screen}
                    geographyLevel={geographyLevel}
                    comparisonCountyName={comparisonCountyName}
                    comparisonStateName={comparisonStateName}
                    comparisonLevel={effectiveComparisonLevel}
                    rrz={riskReductionZone}
                    ranks={ranks}
                  />

                  {detailGeography && (
                    <Box
                      style={{
                        gridColumn: size === "large" ? "span 2" : "",
                        borderRadius: "var(--border-radius)"
                      }}
                      background="white"
                    >
                      <MobileLegend />
                      <Box
                        style={{
                          height: "53rem",
                          minHeight: "40rem",
                          maxHeight: "60vh"
                        }}
                      >
                        <Map
                          geographyLevel={geographyLevel}
                          detailPlaceId={detailGeographyId}
                          geography={detailGeography}
                          mapStyleStateAbbrev={mapStyleStateAbbrev}
                          stateGeography={stateGeography}
                          screen={screen}
                          showPopulatedAreaMask={showPopulatedAreaMask}
                          mapContainerId="map-container-risk-to-homes"
                          displayControlsAndLabels={true}
                          selectedBaseMapLayer={vulnerablePopulations.selectedBaseMapLayer}
                          vulnerablePopulations={vulnerablePopulations}
                          riskToHomesProps={riskToHomesProps}
                          likelihoodProps={likelihoodProps}
                          rrzProps={rrzProps}
                        />
                      </Box>
                      {size === "small" && screen === Screen.VulnerablePopulations && (
                        <Box style={{ borderBottom: "1px solid var(--warm-gray-300)" }}>
                          <VulPopSelector />
                        </Box>
                      )}
                      <BoundaryLegend screen={screen} geographyLevel={geographyLevel} />
                    </Box>
                  )}

                  <RiskDetailComponent
                    comparisonLevel={effectiveComparisonLevel}
                    comparisonAbbr={mapStyleStateAbbrev ? mapStyleStateAbbrev : "US"}
                    comparisonCountyName={comparisonCountyName}
                    comparisonStateName={comparisonStateName}
                    detailPlaceId={detailGeographyId}
                    geography={detailGeography ? detailGeography.name_short : ""}
                    geographyLevel={geographyLevel}
                    screen={screen}
                    rrz={riskReductionZone}
                    riskToHomes={riskToHomes}
                    vulnerablePopulations={vulnerablePopulations}
                    wildfireLikelihood={wildfireLikelihood}
                  />

                  <TakeAction screen={screen} />

                  <section style={{ display: "contents" }} aria-labelledby="about-our-data">
                    <Heading
                      level={3}
                      id="about-our-data"
                      weight={400}
                      color="var(--text-700) !important"
                      style={{
                        gridColumn: size === "large" ? "span 2" : "",
                        textAlign: "center",
                        paddingTop: "3rem",
                        paddingBottom: ".5rem",
                        fontSize: "2.4rem"
                      }}
                      aria-hidden
                    >
                      About our data
                    </Heading>

                    <HowWeCalculate screen={screen} />
                    <FAQ />
                  </section>
                </Grid>
              </>
            ) : "errorMessage" in geography ? (
              geography.errorMessage
            ) : null}
          </ScreenContainer>
          <AppFooter />
          <GlossaryComponent />
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default DetailScreen;
