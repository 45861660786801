import { Loop, LoopReducer } from "redux-loop";
import { Action } from "../actions";
import { ActionTypes } from "../actions/populatedAreaMask";

export type PopulatedAreaMaskState = boolean;

export const initialState: PopulatedAreaMaskState = false;

const populatedAreaMaskReducer: LoopReducer<PopulatedAreaMaskState, Action> = (
  state: PopulatedAreaMaskState = initialState,
  action: Action
): PopulatedAreaMaskState | Loop<PopulatedAreaMaskState, Action> => {
  switch (action.type) {
    case ActionTypes.POPULATED_AREA_MASK_SET:
      return action.visible;
    default:
      return state;
  }
};

export default populatedAreaMaskReducer;
