import { Geography, GeographyLevel, RiskToHomesCollection } from "../models";

export enum ActionTypes {
  RISK_TO_HOMES_FETCH = "RISK_TO_HOMES_FETCH",
  RISK_TO_HOMES_FETCH_SUCCESS = "RISK_TO_HOMES_FETCH_SUCCESS",
  RISK_TO_HOMES_FETCH_FAILURE = "RISK_TO_HOMES_FETCH_FAILURE"
}

// There are separate actions for making the API request and handling both its
// success and failure scenarios
export type RiskToHomesCollectionAction =
  | {
      readonly type: ActionTypes.RISK_TO_HOMES_FETCH;
      readonly level: GeographyLevel;
      readonly geoId: string;
      readonly detailGeoId: string;
      readonly detailGeo?: Geography;
    }
  | {
      readonly type: ActionTypes.RISK_TO_HOMES_FETCH_SUCCESS;
      readonly riskToHomes: RiskToHomesCollection;
    }
  | { readonly type: ActionTypes.RISK_TO_HOMES_FETCH_FAILURE; readonly errorMessage: string };

export function riskToHomesFetch(
  level: GeographyLevel,
  geoId: string,
  detailGeoId: string,
  detailGeo?: Geography
): RiskToHomesCollectionAction {
  return {
    level,
    geoId,
    detailGeoId,
    detailGeo,
    type: ActionTypes.RISK_TO_HOMES_FETCH
  };
}

export function riskToHomesFetchSuccess(
  riskToHomes: RiskToHomesCollection
): RiskToHomesCollectionAction {
  return {
    type: ActionTypes.RISK_TO_HOMES_FETCH_SUCCESS,
    riskToHomes
  };
}

export function riskToHomesFetchFailure(errorMessage: string): RiskToHomesCollectionAction {
  return {
    type: ActionTypes.RISK_TO_HOMES_FETCH_FAILURE,
    errorMessage
  };
}
