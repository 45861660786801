import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Search } from "grommet-icons";
import { Text, TextInput, Box } from "grommet";
import { useLocalStorage } from "@rehooks/local-storage";
import styled from "styled-components";

import { geographyListClear, geographyListFetch } from "../actions/geographyList";
import { SearchEntry, Screen } from "../models";
import { RootState } from "../store";
import RecentSearchIcon from "../icons/RecentSearch";

const KEY = "he-wrc-search-history";
const HISTORY_COUNT = 5;

interface SearchResult extends SearchEntry {
  isHistory: boolean;
}

interface Suggestion {
  label: JSX.Element;
  value: string;
  term: null | SearchResult;
}

interface SuggestionItemProps {
  readonly label: string;
  readonly type: string;
  readonly isSearchPage: boolean;
  readonly isHistory: boolean;
  readonly noResults?: boolean;
}

const GeographySuggestion = styled.span<{ noResults?: boolean }>`
  padding: 0.45rem 0.6rem;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  cursor: ${props => (props.noResults ? "not-allowed" : "pointer")};
`;

const GeographyName = styled(Text)<{ isSearchPage: boolean; isHistory: boolean }>`
  flex: 1;
  margin-left: ${props =>
    props.isHistory
      ? props.isSearchPage
        ? "0.4rem"
        : "-0.2rem"
      : props.isSearchPage
      ? "3.9rem"
      : "2.5rem"};
`;

const GeographyType = styled(Text)`
  text-transform: uppercase;
`;

const SuggestionItem = ({
  label,
  type,
  isSearchPage,
  isHistory,
  noResults = false
}: SuggestionItemProps) => (
  <GeographySuggestion noResults={noResults}>
    {isHistory && (
      <Box
        as="span"
        align="center"
        justify="center"
        height="100%"
        pad={{ left: isSearchPage ? "1.1rem" : "0.4rem" }}
      >
        <RecentSearchIcon size="14px" />
        <Text className="sr-only">Recent search&nbsp;</Text>
      </Box>
    )}
    <GeographyName size="medium" color="text-900" isSearchPage={isSearchPage} isHistory={isHistory}>
      {label}
    </GeographyName>
    &nbsp;
    <GeographyType size="1.3rem" color="text-700">
      {type}
    </GeographyType>
  </GeographySuggestion>
);

const GeographySearchInput = () => {
  const geographyList = useSelector((state: RootState) => state.geographyList);
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  // Figure out if we're on the search screen by checking the "screen" param
  // (which is empty on the search screen)
  const { screen } = useParams<{ screen: Screen }>();
  const isSearchPage = !screen;

  const [searchHistory, setSearchHistory] = useLocalStorage<SearchEntry[]>(KEY, []);

  useEffect(() => {
    if (inputValue === "") {
      dispatch(geographyListClear());
      return;
    }
    setIsPending(true);
    const debounced = setTimeout(() => {
      dispatch(geographyListFetch(inputValue));
      setIsPending(false);
    }, 100);
    return () => {
      clearTimeout(debounced);
    };
  }, [inputValue, dispatch]);

  /*
    Search results have two parts displayed in this order:
    1. Search history: filtered and sliced to most recent top `HISTORY_COUNT` number of records
    2. Geography search response: filtered and de-duped so that records in history don't show up twice
  */
  useEffect(() => {
    if (isFirstLoad || isPending || ("isPending" in geographyList && geographyList.isPending)) {
      setSuggestions([]);
      return;
    }
    let geoDisplayList = [];
    const topSearchHistory = searchHistory
      .filter(entry => entry.searchText.toUpperCase().includes(inputValue.toUpperCase()))
      .slice(0, HISTORY_COUNT)
      .map(term => ({ ...term, isHistory: true } as SearchResult));
    if (!inputValue.length) {
      geoDisplayList = topSearchHistory;
    } else {
      const geographySearchList =
        "resource" in geographyList ? geographyList.resource.searchList : [];
      const historyKeys = topSearchHistory.map(term => term.key);
      const geographyDeduped = geographySearchList
        .filter(term => !historyKeys.includes(term.key))
        .map(term => ({ ...term, isHistory: false } as SearchResult));
      geoDisplayList = [...topSearchHistory, ...geographyDeduped];
    }
    if (!geoDisplayList.length && inputValue.length) {
      setSuggestions([
        {
          label: (
            <SuggestionItem
              label="No results"
              type=""
              isHistory={false}
              isSearchPage={isSearchPage}
              noResults={true}
            />
          ),
          value: "No results",
          term: null
        }
      ]);
    } else {
      setSuggestions(
        geoDisplayList.map(term => ({
          label: (
            <SuggestionItem
              label={term.searchText}
              type={term.type}
              isHistory={term.isHistory}
              isSearchPage={isSearchPage}
            />
          ),
          value: term.searchText,
          term: term
        }))
      );
    }
  }, [isFirstLoad, isPending, geographyList, isSearchPage, inputValue, searchHistory]);

  const onClick = () => {
    setIsFirstLoad(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsFirstLoad(false);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      setIsFirstLoad(false);
    }
  };

  const onSuggestionSelect = ({ suggestion }: { suggestion: Suggestion }) => {
    if (!suggestion.term) return;
    const path = [
      suggestion.term.state,
      suggestion.term.type === "tribal area"
        ? suggestion.term.tribal_area
        : suggestion.term.counties
        ? suggestion.term.counties.join("|")
        : "",
      suggestion.term.community
    ]
      .filter(term => term)
      .join("/");
    setInputValue("");
    // When you select a place from the header search bar using the keyboard, the focus stays in
    // the search field. Setting 'isFirstLoad' back to true prevents the past search results list
    // from showing up immediately when the new location page loads.
    setIsFirstLoad(true);
    setSearchHistory([
      suggestion.term,
      // @ts-ignore
      // suggestion.term should not be null if it gets here
      ...searchHistory.filter(history => history.key !== suggestion.term.key)
    ]);
    dispatch(geographyListClear());
    history.push(`/${Screen.Overview}/${path}/`);
    // Move focus to <body>
    const currentFocus = document.activeElement as HTMLElement;
    currentFocus.blur();
  };

  return (
    <TextInput
      id="geographySearchBox"
      size="large"
      dropHeight="medium"
      icon={<Search id="SearchBoxIcon" aria-hidden="true" />}
      value={inputValue}
      placeholder="Search by community, tribal area, county, or state"
      suggestions={suggestions}
      autoFocus={isSearchPage}
      onClick={onClick}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onSuggestionSelect={onSuggestionSelect}
    />
  );
};

export default GeographySearchInput;
