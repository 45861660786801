import { base } from "grommet-icons";
import { deepMerge } from "grommet-icons/utils";

// Add an xxlarge icon size
// https://github.com/grommet/grommet-icons/blob/009a0138f8f646b72ee1c2fe9f2dea40122a58b0/README.md#customize
const IconTheme = deepMerge(base, {
  icon: {
    size: {
      small: "15px",
      xxlarge: "192px",
      houseSmall: "18px",
      houseMedium: "25px"
    }
  }
});

export default IconTheme;
