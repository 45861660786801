import mapboxgl, { LngLatBoundsLike, MapMouseEvent, MapboxGeoJSONFeature } from "mapbox-gl";
import { GeographyLevel, NormalizedTractId, VulnerablePopulationTractDictionary } from "../models";
import { setSelectedModalTract, setSelectedPanelTract } from "../actions/vulnerablePopulations";
import store from "../store";
import { normalizeGeoId } from "../utils";
import { BOUNDARY_LAYERS, OVERLAY_IDS, PLACE_LABEL_LAYERS } from "./mapLayers";
import { FuncShowTractPopup } from "./mapLayersVulnerablePopulation";
import { stateToFips } from "../constants";
import { fetchGeography } from "../api";
import { defaultMapOptions } from "./mapStyles";

export default undefined;

/**********************************************************
 * Label linking
 *********************************************************/

export interface HoveredLabelFeature {
  layerId: string;
  sourceLayer?: string;
  source?: string;
  id: string | number;
}

export type FuncSetBreadcrumb = React.Dispatch<React.SetStateAction<string>>;

const COUNTY_LABEL_ID = PLACE_LABEL_LAYERS[GeographyLevel.county].layerId;
const STATE_LABEL_ID = PLACE_LABEL_LAYERS[GeographyLevel.state].layerId;
const COMMUNITY_LABEL_ID = PLACE_LABEL_LAYERS[GeographyLevel.community].layerId;
const DC_GEO_ID = "11";
const DC_LABEL_NAME = "Washington";

export const getGeoIdFromFeature = (feature: MapboxGeoJSONFeature): string | undefined => {
  const {
    layer: { id: layerId },
    properties
  } = feature;
  if (layerId === COUNTY_LABEL_ID) {
    return normalizeGeoId(feature.id?.toString());
  }
  if (layerId === STATE_LABEL_ID && properties?.name) {
    return stateToFips[properties.name as string];
  }
  // handle special case DC
  if (layerId === COMMUNITY_LABEL_ID && properties?.name === DC_LABEL_NAME) {
    return DC_GEO_ID;
  }
  return normalizeGeoId(feature?.properties?.id);
};

const navigateToClickedLocation = async (
  feature: MapboxGeoJSONFeature,
  setBreadcrumb: FuncSetBreadcrumb
) => {
  const geoId = getGeoIdFromFeature(feature);
  if (!!geoId) {
    const { breadcrumb } = await fetchGeography(geoId);
    setBreadcrumb(breadcrumb);
  }
};

const recenterSelectedPlaceLabel = (map: mapboxgl.Map, bounds: mapboxgl.LngLatBoundsLike) => {
  map.fitBounds(bounds, {
    maxZoom: defaultMapOptions.maxZoom,
    padding: 25
  });
};

const clearLabelHover = (map: mapboxgl.Map) => {
  map.getCanvas().style.cursor = "";
  Object.values(PLACE_LABEL_LAYERS).forEach(({ mapboxTilesetId, sourceLayer }) => {
    map.getSource(mapboxTilesetId) &&
      map.removeFeatureState({
        source: mapboxTilesetId,
        sourceLayer: sourceLayer
      });
  });
};

/**********************************************************
 * Tract details
 *********************************************************/

export const makeNormalizedTractId = (
  tractId: string | number | undefined,
  placeId: string
): NormalizedTractId => {
  if (typeof tractId === "undefined") {
    return tractId;
  }
  const stringId = String(tractId);
  return stringId.length === 10 && placeId.length !== 6 ? `0${stringId}` : stringId;
};

export const highlightTract = (map: mapboxgl.Map, tractId: string, style: "light" | "dark") => {
  // Highlight hovered tract
  map.setFeatureState(
    {
      source: BOUNDARY_LAYERS.census_tracts.mapboxTilesetId,
      sourceLayer: BOUNDARY_LAYERS.census_tracts.sourceLayer,
      id: tractId
    },
    {
      highlight: style
    }
  );
  // Set cursor to pointer
  map.getCanvas().style.cursor = "pointer";
};

export const selectTract = (map: mapboxgl.Map, tractId: string, placeId: string, size: string) => {
  // Highlight the tract and show the tract detail panel
  highlightTract(map, tractId, "dark");
  const normalizedTractId = makeNormalizedTractId(tractId, placeId);
  size === "small"
    ? store.dispatch(setSelectedModalTract(normalizedTractId))
    : store.dispatch(setSelectedPanelTract(normalizedTractId));
};

// This clears all interactive tract highlighting, i.e. hover and selected shading
// It doesn't clear shading based on selected risk factors
export const clearTractHighlighting = (map: mapboxgl.Map) => {
  const { mapboxTilesetId: source, sourceLayer } = BOUNDARY_LAYERS.census_tracts;
  map.getSource(source) && map.removeFeatureState({ source, sourceLayer });
};

// Fit tract in the part of the map that's not covered by map UI
export const zoomAndCenterTract = (
  map: mapboxgl.Map,
  tractBounds: LngLatBoundsLike,
  size: string
) => {
  map.fitBounds(tractBounds, {
    padding: {
      left: size !== "small" ? 425 : 25,
      right: 25,
      top: 25,
      bottom: 25
    },
    maxZoom: defaultMapOptions.maxZoom
  });
};

export const closeTractDetailPanel = (map: mapboxgl.Map) => {
  store.dispatch(setSelectedPanelTract(undefined));
  store.dispatch(setSelectedModalTract(undefined));
  // and clear selected-tract highlighting
  clearTractHighlighting(map);
};

export const onTractMouseOver = (
  e: mapboxgl.MapLayerMouseEvent,
  tractId: string,
  placeId: string,
  showTractPopup: FuncShowTractPopup
) => {
  const map = e.target;

  // If the user selected a tract with their keyboard and then hovers a tract with
  // their mouse, remove focus from the button. In turn, this hides the keyboard
  // navigation instructions.
  if (document.activeElement?.classList.contains("accessible-tract-button")) {
    (document.activeElement as HTMLButtonElement).blur();
  }

  // Set tract hover styling, show the tooltip, and set cursor to a pointer
  highlightTract(map, tractId, "light");
  const normalizedTractId = makeNormalizedTractId(tractId, placeId);
  showTractPopup(normalizedTractId, e.lngLat, "Click tract to see details");
};

/**********************************************************
 * Combined listener functions
 *********************************************************/

const placeLabelLayerIds = Object.values(PLACE_LABEL_LAYERS).map(l => l.layerId);

// Helper function to get the first feature from a list that matches a layerId in another list
const firstMatchingFeature = (features: MapboxGeoJSONFeature[], layerIds: string[]) => {
  return features.find(feature => layerIds.includes(feature.layer.id));
};

const onMapClick = (
  e: MapMouseEvent,
  size: string,
  placeId: string,
  bounds: LngLatBoundsLike,
  selectedTract: NormalizedTractId,
  tractInfo: VulnerablePopulationTractDictionary,
  setBreadcrumb: FuncSetBreadcrumb
) => {
  const map = e.target;

  // Always reset label highlighting
  clearLabelHover(map);

  // If tract panel is open, any new click closes it.
  if (selectedTract) {
    closeTractDetailPanel(map);
    return;
  }

  const features = map.queryRenderedFeatures(e.point);

  // If the click is on the label of the selected place, just recenter
  const selectedFeature = firstMatchingFeature(features, [OVERLAY_IDS.selectedLabelLayerId]);
  if (selectedFeature) {
    recenterSelectedPlaceLabel(map, bounds);
    return;
  }

  // If the click is on another label layer, navigate there
  const labelFeature = firstMatchingFeature(features, placeLabelLayerIds);
  if (labelFeature) {
    navigateToClickedLocation(labelFeature, setBreadcrumb);
    return;
  }

  // If the click is on a tract, open details for it
  const tractFeature = firstMatchingFeature(features, [OVERLAY_IDS.tractsLayerBaseFill]);
  if (tractFeature) {
    const tractId = String(tractFeature.id);
    selectTract(map, tractId, placeId, size);
    const normalizedTractId = makeNormalizedTractId(tractId, placeId) || tractId;
    const tractBounds = tractInfo[normalizedTractId].bounds as LngLatBoundsLike;
    zoomAndCenterTract(map, tractBounds, size);
  }
};

// Rather than track 'mouseenter' and 'mouseleave' events across a bunch of different layers,
// all hover behavior is handled by reacting to 'mousemove' and inspecting what's under the cursor.
export const onMouseMove = (
  e: MapMouseEvent,
  placeId: string,
  selectedTract: NormalizedTractId,
  showTractPopup: FuncShowTractPopup
) => {
  // If the tract panel is open, that overrides all other map interactivity. So do nothing.
  if (selectedTract) {
    return;
  }

  const map = e.target;
  const features = map.queryRenderedFeatures(e.point);

  // Knowing the prior state of all these and only making changes where the new state needs to be
  // different would be more elegant, but also a lot harder to pull off. It doesn't seem like
  // clearing the hover/highlight styles and replacing them with the same thing causes flicker,
  // fortunately.
  clearTractHighlighting(map);
  clearLabelHover(map);
  showTractPopup();

  // Label interactivity gets priority, so look for that first.
  const hoveredLabelFeature = firstMatchingFeature(features, [
    OVERLAY_IDS.selectedLabelLayerId,
    ...placeLabelLayerIds
  ]);
  if (hoveredLabelFeature && hoveredLabelFeature.id) {
    // change the cursor to a pointer
    map.getCanvas().style.cursor = "pointer";
    // set the hovered label feature's state to hover: true
    const { id, source, sourceLayer } = hoveredLabelFeature;
    map.setFeatureState({ id, source, sourceLayer }, { hover: true });
    return;
  }

  // If there wasn't a label under the cursor, look for a tract.
  const hoveredTractFeature = firstMatchingFeature(features, [OVERLAY_IDS.tractsLayerBaseFill]);
  if (hoveredTractFeature) {
    const tractId = String(hoveredTractFeature.id);
    onTractMouseOver(e, tractId, placeId, showTractPopup);
  }
};

// This only gets called when the mouse leaves the map canvas.
const onMouseOut = (
  e: MapMouseEvent,
  selectedTract: NormalizedTractId,
  showTractPopup: FuncShowTractPopup
) => {
  const map = e.target;
  clearLabelHover(map);
  showTractPopup();
  // If the tract panel is open, leave the selected tract highlighted
  if (!selectedTract) {
    clearTractHighlighting(map);
  }
};

// This creates and attaches listeners with the provided parameters embedded, and returns a
// cleanup function to clear them out when the component gets destroyed (i.e. replaced by React
// because the parameters changed).
export const attachMapListeners = (
  map: mapboxgl.Map,
  size: string,
  placeId: string,
  bounds: mapboxgl.LngLatBoundsLike,
  selectedTract: NormalizedTractId,
  tractInfo: VulnerablePopulationTractDictionary,
  showTractPopup: FuncShowTractPopup,
  setBreadcrumb: FuncSetBreadcrumb
) => {
  const clickHandler = (e: MapMouseEvent) =>
    onMapClick(e, size, placeId, bounds, selectedTract, tractInfo, setBreadcrumb);
  const mouseMoveHandler = (e: MapMouseEvent) =>
    onMouseMove(e, placeId, selectedTract, showTractPopup);
  const mouseOutHandler = (e: MapMouseEvent) => onMouseOut(e, selectedTract, showTractPopup);
  map.on("click", clickHandler);
  map.on("mousemove", mouseMoveHandler);
  map.on("mouseout", mouseOutHandler);

  return () => {
    map.off("click", clickHandler);
    map.off("mousemove", mouseMoveHandler);
    map.off("mouseout", mouseOutHandler);
  };
};
