import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const MapIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 23 21" a11yTitle="Map" {...props}>
      <path
        stroke="none"
        d="M 16.3542 5.1875 C 16.3542 7.33594 13.4635 11.125 12.2135 12.6875 C 11.9401 13.0781 11.3542 13.0781 11.0807 12.6875 C 9.83072 11.125 6.97916 7.33594 6.97916 5.1875 C 6.97916 2.60938 9.04947 0.5 11.6667 0.5 C 14.2448 0.5 16.3542 2.60938 16.3542 5.1875 Z M 11.6667 6.4375 C 12.526 6.4375 13.2292 5.77344 13.2292 4.875 C 13.2292 4.01562 12.526 3.3125 11.6667 3.3125 C 10.7682 3.3125 10.1042 4.01562 10.1042 4.875 C 10.1042 5.77344 10.7682 6.4375 11.6667 6.4375 Z M 17.0182 7.53125 C 17.0573 7.49219 17.0573 7.45312 17.0963 7.41406 L 21.6276 5.57812 C 22.2135 5.34375 22.9167 5.77344 22.9167 6.4375 V 17.0234 C 22.9167 17.4141 22.6823 17.7656 22.2917 17.9219 L 16.6667 20.1484 V 8.35156 C 16.7838 8.07812 16.901 7.80469 17.0182 7.53125 Z M 6.27603 7.53125 C 6.39322 7.80469 6.51041 8.07812 6.66666 8.35156 V 18.1562 L 1.66666 20.1484 C 1.08072 20.3828 0.416656 19.9531 0.416656 19.2891 V 8.70312 C 0.416656 8.3125 0.611969 7.96094 1.00259 7.84375 L 5.76822 5.92969 C 5.88541 6.47656 6.04166 7.02344 6.27603 7.53125 Z M 13.1901 13.4688 C 13.737 12.8047 14.5963 11.7109 15.4167 10.4609 V 20.2266 L 7.91666 18.0781 V 10.4609 C 8.69791 11.7109 9.55728 12.8047 10.1042 13.4688 C 10.8854 14.4844 12.4088 14.4844 13.1901 13.4688 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default MapIcon;
