import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";

const GoogleAnalytics = () => {
  ReactGA4.initialize("G-K1ZBKGMZBN");
  const location = useLocation();

  // send a pageview to GA every time the route changes
  useEffect(() => {
    ReactGA4.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return null;
};
const logGoogleAnalyticsEvent = (category: string, action: string, label: string) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label
  });
};
export { logGoogleAnalyticsEvent };
export default GoogleAnalytics;
