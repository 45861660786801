import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const EquitableRiskIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="EquitableRiskIcon"
      viewBox="0 0 26 19"
      a11yTitle="Equitable Risk Reduction"
      {...props}
    >
      <path
        stroke="none"
        d="M 7.6875 2.9375 C 7.6875 4.14844 6.67188 5.125 5.5 5.125 C 4.28906 5.125 3.3125 4.14844 3.3125 2.9375 C 3.3125 1.76562 4.28906 0.75 5.5 0.75 C 6.67188 0.75 7.6875 1.76562 7.6875 2.9375 Z M 8.625 7.19531 C 7.45312 8.21094 6.75 9.73438 6.75 11.375 C 6.75 12.7422 7.17969 13.9531 8 14.9297 V 15.75 C 8 16.4531 7.41406 17 6.75 17 H 4.25 C 3.54688 17 3 16.4531 3 15.75 V 14.7344 C 1.51562 14.0312 0.5 12.5078 0.5 10.75 C 0.5 8.36719 2.45312 6.375 4.875 6.375 H 6.125 C 7.02344 6.375 7.92188 6.6875 8.625 7.19531 Z M 3 9.10938 C 2.60938 9.53906 2.375 10.125 2.375 10.75 C 2.375 11.4141 2.60938 12 3 12.4297 V 9.10938 Z M 18 15.75 V 14.9297 C 18.7812 13.9531 19.25 12.7422 19.25 11.375 C 19.25 9.73438 18.5078 8.21094 17.3359 7.19531 C 18.0391 6.6875 18.9375 6.375 19.875 6.375 H 21.125 C 23.5078 6.375 25.5 8.36719 25.5 10.75 C 25.5 12.5078 24.4453 14.0312 23 14.7344 V 15.75 C 23 16.4531 22.4141 17 21.75 17 H 19.25 C 18.5469 17 18 16.4531 18 15.75 Z M 23 12.4297 C 23.3516 12 23.625 11.4141 23.625 10.75 C 23.625 10.125 23.3516 9.53906 23 9.10938 V 12.4297 Z M 22.6875 2.9375 C 22.6875 4.14844 21.6719 5.125 20.5 5.125 C 19.2891 5.125 18.3125 4.14844 18.3125 2.9375 C 18.3125 1.76562 19.2891 0.75 20.5 0.75 C 21.6719 0.75 22.6875 1.76562 22.6875 2.9375 Z M 10.5 3.25 C 10.5 1.88281 11.5938 0.75 13 0.75 C 14.3672 0.75 15.5 1.88281 15.5 3.25 C 15.5 4.65625 14.3672 5.75 13 5.75 C 11.5938 5.75 10.5 4.65625 10.5 3.25 Z M 18 11.375 C 18 13.1328 16.9453 14.6562 15.5 15.3594 V 17 C 15.5 17.7031 14.9141 18.25 14.25 18.25 H 11.75 C 11.0469 18.25 10.5 17.7031 10.5 17 V 15.3594 C 9.01562 14.6562 8 13.1328 8 11.375 C 8 8.99219 9.95312 7 12.375 7 H 13.625 C 16.0078 7 18 8.99219 18 11.375 Z M 10.5 13.0547 V 9.73438 C 10.1094 10.1641 9.875 10.75 9.875 11.375 C 9.875 12.0391 10.1094 12.625 10.5 13.0547 Z M 15.5 9.73438 V 13.0547 C 15.8516 12.5859 16.125 12.0391 16.125 11.375 C 16.125 10.75 15.8516 10.1641 15.5 9.73438 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default EquitableRiskIcon;
