import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { WildfireLikelihoodAction } from "../actions";
import {
  ActionTypes,
  wildfireLikelihoodFetchFailure,
  wildfireLikelihoodFetchSuccess
} from "../actions/wildfireLikelihood";

import { fetchWildfireLikelihood } from "../api";
import { WildfireLikelihoodResource } from "../models";
import { Resource } from "../resource";

export type WildfireLikelihoodState = Resource<WildfireLikelihoodResource>;

export const initialState: WildfireLikelihoodState = {
  isPending: false
};

const WildfireLikelihoodCollectionReducer: LoopReducer<
  WildfireLikelihoodState,
  WildfireLikelihoodAction
> = (
  state: WildfireLikelihoodState = initialState,
  action: WildfireLikelihoodAction
): WildfireLikelihoodState | Loop<WildfireLikelihoodState, WildfireLikelihoodAction> => {
  switch (action.type) {
    case ActionTypes.WILDFIRE_LIKELIHOOD_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchWildfireLikelihood, {
          args: [action.level, action.geoId, action.detailGeoId, action.detailGeo],
          successActionCreator: wildfireLikelihoodFetchSuccess,
          failActionCreator: wildfireLikelihoodFetchFailure
        })
      );
    case ActionTypes.WILDFIRE_LIKELIHOOD_FETCH_SUCCESS:
      return {
        resource: {
          wildfireLikelihood: action.wildfireLikelihood
        }
      };
    case ActionTypes.WILDFIRE_LIKELIHOOD_FETCH_FAILURE:
      return {
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default WildfireLikelihoodCollectionReducer;
