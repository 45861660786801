import React from "react";
import { RadioButtonGroup, Box, Text } from "grommet";

import { ComparisonLevel, GeographyLevel } from "../models";
import { setComparisonLevel } from "../actions/comparisonLevel";
import store from "../store";

interface Props {
  readonly comparisonLevel: ComparisonLevel;
  readonly geographyLevel: GeographyLevel;
  readonly disabled: boolean;
}

const defaultComparisonLevel = (geoLevel: GeographyLevel) => {
  return {
    [GeographyLevel.community]: ComparisonLevel.national,
    [GeographyLevel.county]: ComparisonLevel.national,
    [GeographyLevel.tribal_area]: ComparisonLevel.national,
    [GeographyLevel.state]: ComparisonLevel.national
  }[geoLevel];
};

const availableComparisonLevels = (geoLevel: GeographyLevel) => {
  return {
    [GeographyLevel.community]: [
      ComparisonLevel.national,
      ComparisonLevel.state,
      ComparisonLevel.county
    ],
    [GeographyLevel.county]: [ComparisonLevel.state, ComparisonLevel.national],
    [GeographyLevel.tribal_area]: [ComparisonLevel.state, ComparisonLevel.national],
    [GeographyLevel.state]: [ComparisonLevel.national]
  }[geoLevel];
};

export const selectedOrDefaultComparisonLevel = (
  geographyLevel: GeographyLevel,
  comparisonLevel: ComparisonLevel
) => {
  return availableComparisonLevels(geographyLevel).includes(comparisonLevel)
    ? comparisonLevel
    : defaultComparisonLevel(geographyLevel);
};

export const ComparisonSwitcher = ({ comparisonLevel, geographyLevel, disabled }: Props) => {
  const levelLabels = {
    [ComparisonLevel.county]: "County",
    [ComparisonLevel.state]: "State",
    [ComparisonLevel.national]: "Nation"
  };

  const availableLevels = availableComparisonLevels(geographyLevel).map(level => {
    return {
      value: level,
      label: levelLabels[level],
      id: level
    };
  });
  return availableLevels.length > 1 ? (
    <Box direction="row" gap=".8rem" align="center">
      <Text color={disabled ? "text-700" : "text-800"} style={{ flexShrink: 0 }} aria-hidden>
        Compare to
      </Text>
      <RadioButtonGroup
        name="Compare to"
        a11yTitle="Compare to"
        className="multi-toggle"
        gap="0"
        options={availableLevels}
        value={!disabled && comparisonLevel}
        disabled={disabled}
        onChange={({ target }) => {
          store.dispatch(setComparisonLevel(target.value as ComparisonLevel));
        }}
        style={{
          pointerEvents: disabled ? "none" : "all"
        }}
      />
    </Box>
  ) : null;
};
