import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const SmokeReadyIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="SmokeReadyIcon" viewBox="0 0 26 21" a11yTitle="Smoke Ready" {...props}>
      <path
        stroke="none"
        d="M 5.79166 8 C 6.14322 8 6.53384 8.03906 6.92447 8.11719 C 7.86197 5.85156 10.0495 4.25 12.6667 4.25 C 13.6823 4.25 14.6979 4.52344 15.5963 5.03125 C 15.9479 4.60156 16.3776 4.28906 16.8073 4.01562 C 15.9088 2.64844 14.3854 1.75 12.6667 1.75 C 11.4167 1.75 10.2838 2.21875 9.42447 2.96094 C 8.56509 1.51562 6.96353 0.5 5.16666 0.5 C 2.39322 0.5 0.166656 2.76562 0.166656 5.5 C 0.166656 7.02344 0.830719 8.35156 1.84634 9.25 C 2.97916 8.46875 4.30728 8 5.79166 8 Z M 15.8698 6.71094 C 15.0104 5.96875 13.8776 5.5 12.6667 5.5 C 10.6354 5.5 8.91666 6.71094 8.09634 8.42969 C 8.72134 8.66406 9.30728 8.97656 9.85416 9.36719 C 10.987 8.46875 12.3932 8 13.9167 8 C 16.026 8 18.0182 9.01562 19.3463 10.7344 C 19.8151 10.5781 20.2838 10.5 20.7917 10.5 C 22.1588 10.5 23.4088 11.0469 24.3854 11.8672 C 24.8542 11.125 25.1667 10.2266 25.1667 9.25 C 25.1667 6.51562 22.901 4.25 20.1667 4.25 C 18.3307 4.25 16.7292 5.26562 15.8698 6.71094 Z M 20.7917 11.75 C 20.0885 11.75 19.4245 11.9453 18.8385 12.2578 C 17.862 10.4609 16.026 9.25 13.9167 9.25 C 12.3151 9.25 10.8698 9.95312 9.85416 11.0078 C 8.79947 9.95312 7.39322 9.25 5.79166 9.25 C 2.66666 9.25 0.166656 11.7891 0.166656 14.875 C 0.166656 18 2.66666 20.5 5.79166 20.5 H 20.7917 C 23.1745 20.5 25.1667 18.5469 25.1667 16.125 C 25.1667 13.7422 23.1745 11.75 20.7917 11.75 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default SmokeReadyIcon;
