import React from "react";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const RecentSearchIcon = (props: any) => (
  <StyledIcon id="RecentSearchIcon" viewBox="0 0 14 14" a11yTitle="Recent search" {...props}>
    <path
      stroke="none"
      d="M12.2812 7.125C12.2812 5.24609 11.2656 3.51953 9.64062 2.55469C7.99023 1.61523 5.98438 1.61523 4.35938 2.55469C2.70898 3.51953 1.71875 5.24609 1.71875 7.125C1.71875 9.0293 2.70898 10.7559 4.35938 11.7207C5.98438 12.6602 7.99023 12.6602 9.64062 11.7207C11.2656 10.7559 12.2812 9.0293 12.2812 7.125ZM0.5 7.125C0.5 4.81445 1.71875 2.68164 3.75 1.51367C5.75586 0.345703 8.21875 0.345703 10.25 1.51367C12.2559 2.68164 13.5 4.81445 13.5 7.125C13.5 9.46094 12.2559 11.5938 10.25 12.7617C8.21875 13.9297 5.75586 13.9297 3.75 12.7617C1.71875 11.5938 0.5 9.46094 0.5 7.125ZM6.39062 3.67188C6.39062 3.3418 6.64453 3.0625 7 3.0625C7.33008 3.0625 7.60938 3.3418 7.60938 3.67188V6.82031L9.76758 8.24219C10.0469 8.44531 10.123 8.82617 9.94531 9.10547C9.74219 9.38477 9.36133 9.46094 9.08203 9.25781L6.64453 7.63281C6.49219 7.53125 6.39062 7.32812 6.39062 7.125V3.67188Z"
      fill="var(--text-700)"
    />
  </StyledIcon>
);

export default RecentSearchIcon;
