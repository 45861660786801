import { Box, Nav, ResponsiveContext } from "grommet";
import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import GeographySearchInput from "./GeographySearch";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { Screen } from "../models";

const StyledNavLink = styled(NavLink)`
  color: var(--text-500);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  padding: 1.7rem 1rem;
  outline-offset: -8px !important;
  position: relative;
  flex-shrink: 0;
  &:hover {
    color: white;
  }
  &.active {
    color: white;
    pointer-events: none;
    &::after {
      content: "";
      position: absolute;
      background: var(--warm-gray-500);
      height: 0.4rem;
      bottom: 0;
      left: 1rem;
      right: 1rem;
    }
  }
`;

const headerElementId = "app-header";

const AppHeader = ({
  history,
  location,
  match: {
    params: { screen }
  }
}: RouteComponentProps<{ readonly screen: Screen }> &
  RouteComponentProps<"history"> &
  RouteComponentProps<"location">) => {
  const isSearchScreen = location.pathname === "/search" || location.pathname === "/";

  useEffect(() => {
    const activeNavElem = document.querySelector("a.active");
    if (activeNavElem && activeNavElem.scrollIntoView) {
      activeNavElem.scrollIntoView({
        block: "nearest",
        inline: "nearest"
      });
    }
  });

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {!isSearchScreen && (
            <Box
              background="warm-gray-900"
              id={headerElementId}
              pad={{
                horizontal: size !== "small" ? "medium" : ""
              }}
              style={{
                position: "sticky",
                top: size === "small" ? "-4.2rem" : 0,
                zIndex: 20
              }}
            >
              <Box
                className="set-width-content-container"
                direction={size === "small" ? "column" : "row"}
                gap={size !== "small" ? "small" : "0"}
                align="start"
              >
                <Box
                  pad={{
                    horizontal: size === "small" ? "2rem" : undefined,
                    vertical: "small",
                    bottom: size === "small" ? "0" : ""
                  }}
                  style={{
                    width: "100%",
                    maxWidth: size !== "small" ? "36rem" : "100%",
                    flexGrow: 1
                  }}
                >
                  <GeographySearchInput />
                </Box>

                <Nav
                  direction="row"
                  gap=".2rem"
                  style={{
                    overflowX: size === "small" ? "scroll" : "visible",
                    marginRight: size !== "small" ? "-1rem" : 0,
                    padding: size === "small" ? "0 1rem" : undefined
                  }}
                >
                  <StyledNavLink
                    className="header-footer-nav-links"
                    to={history.location.pathname.replace(screen, Screen.Overview)}
                  >
                    Overview
                  </StyledNavLink>

                  <StyledNavLink
                    className="header-footer-nav-links"
                    to={history.location.pathname.replace(screen, Screen.RiskToHomes)}
                  >
                    Risk to Homes
                  </StyledNavLink>

                  <StyledNavLink
                    className="header-footer-nav-links"
                    to={history.location.pathname.replace(screen, Screen.WildfireLikelihood)}
                  >
                    {size === "large" ? "Wildfire Likelihood" : "Likelihood"}
                  </StyledNavLink>

                  <StyledNavLink
                    className="header-footer-nav-links"
                    to={history.location.pathname.replace(screen, Screen.RiskReductionZones)}
                  >
                    {size === "large" ? "Risk Reduction Zones" : "Risk Reduction"}
                  </StyledNavLink>

                  <StyledNavLink
                    className="header-footer-nav-links"
                    to={history.location.pathname.replace(screen, Screen.VulnerablePopulations)}
                  >
                    Vulnerable populations
                  </StyledNavLink>
                </Nav>
              </Box>
            </Box>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default withRouter(AppHeader);
