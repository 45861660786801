import React from "react";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const MenuIcon = (props: any) => (
  <StyledIcon id="MenuIcon" viewBox="0 0 15 15" width="15" height="10" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.75C0 0.335786 0.335786 0 0.75 0H14.25C14.6642 0 15 0.335786 15 0.75C15 1.16421 14.6642 1.5 14.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75ZM0 4.75C0 4.33579 0.335786 4 0.75 4H14.25C14.6642 4 15 4.33579 15 4.75C15 5.16421 14.6642 5.5 14.25 5.5H0.75C0.335786 5.5 0 5.16421 0 4.75ZM0.75 8C0.335786 8 0 8.33579 0 8.75C0 9.16421 0.335786 9.5 0.75 9.5H14.25C14.6642 9.5 15 9.16421 15 8.75C15 8.33579 14.6642 8 14.25 8H0.75Z"
      fill="black"
    />
  </StyledIcon>
);

export default MenuIcon;
