import React, { useContext, useEffect, useMemo } from "react";
import { AnnounceContext } from "grommet";
import { Helmet } from "react-helmet";

interface PageMetaProps {
  readonly description: string;
  readonly title: string;
}

const PageMeta = ({ title, description }: PageMetaProps) => {
  const announce = useContext(AnnounceContext);

  const pageTitle = useMemo(() => {
    if (title.length > 0) {
      return `${title} - Wildfire Risk to Communities`;
    } else {
      return "Loading ...";
    }
  }, [title]);

  useEffect(() => {
    if (title.length > 0) {
      announce(title, "polite", 2000);
    }
  }, [title, announce]);

  return (
    <Helmet defer={false}>
      <meta name="description" content={description} />
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default PageMeta;
