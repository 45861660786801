import { compose, createStore } from "redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { install, StoreCreator } from "redux-loop";
import rootReducer, { initialState, State } from "./reducers";
import { Action } from "./actions";

const enhancedCreateStore = createStore as StoreCreator;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* tslint:disable:no-unused-declaration */
declare global {
  interface Window {
    readonly __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
/* tslint:enable */

const store = enhancedCreateStore<State, Action>(
  rootReducer,
  initialState,
  composeEnhancers(install())
);

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

// Use instead of plain `useDispatch` and `useSelector` to get free types
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
