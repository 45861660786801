import { Paragraph } from "grommet";
import React, { useMemo } from "react";
import { ComparisonLevel, Geography, GeographyLevel, Ranks, Screen } from "../models";
import GlossaryLink from "./GlossaryLink";
import { PercentileComparisonText } from "./PercentileComparisonText";
import { getCompareGeoName, getDetailScreenGeoTypeLabel } from "../utils";

interface ComponentProps {
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly ranks: Ranks | undefined;
}

const getBpPctRank = (ranks: Ranks, level: ComparisonLevel) => {
  let pct = 0;
  if (level === ComparisonLevel.county && ranks.bp_within_county !== undefined) {
    pct = ranks.bp_within_county;
  } else if (level === ComparisonLevel.state && ranks.bp_within_state !== undefined) {
    pct = ranks.bp_within_state;
  } else {
    pct = ranks.bp_within_nation;
  }
  return Math.round(100 * pct);
};

export const getWildfireLikelihoodDescription = (
  detailGeography: Geography,
  compareLevel: ComparisonLevel,
  geoLevel: GeographyLevel,
  compareCounty: string,
  compareState: string,
  ranks: Ranks | undefined
) => {
  const riskClass = detailGeography.wildfire_likelihood_class;
  const location = detailGeography.name_short;
  const bpPctRank = !ranks ? undefined : getBpPctRank(ranks, compareLevel);
  const geoName = getCompareGeoName(compareLevel, compareCounty, compareState);
  const { label, extraText } = getDetailScreenGeoTypeLabel(geoLevel, geoName);
  if (bpPctRank === undefined) return "";
  if (bpPctRank > 5) {
    return (
      `${
        riskClass ? riskClass.charAt(0).toUpperCase() + riskClass.slice(1) : ""
      } risk: ${location} has, ` +
      `on average, greater wildfire likelihood than ${bpPctRank}% of ` +
      `${label + extraText} ${geoName ? "in " + geoName : ""}. ` +
      "Learn more and discover resources to reduce risk."
    );
  }
  return (
    `${riskClass ? riskClass.charAt(0).toUpperCase() + riskClass.slice(1) : ""} risk:` +
    `Nearly all other ${geoName} ${label + extraText},` +
    `on average, have greater wildfire likelihood than ${location}. ` +
    "Learn more and discover resources to reduce risk."
  );
};

const WildfireLikelihoodComparisonText = (props: ComponentProps) => {
  const {
    geography,
    geographyLevel,
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    ranks
  } = props;

  const comparisonText = useMemo(() => {
    const bpPctRank = !ranks ? undefined : getBpPctRank(ranks, comparisonLevel);
    const comparisonGeographyName = getCompareGeoName(
      comparisonLevel,
      comparisonCountyName,
      comparisonStateName
    );

    return bpPctRank === undefined ? (
      // Avoids possibly flashing the wrong text while bpPctRank is being recalculated
      <></>
    ) : (
      <PercentileComparisonText
        screen={Screen.WildfireLikelihood}
        comparisonGeographyName={comparisonGeographyName}
        geography={geography}
        geographyLevel={geographyLevel}
        percentileRank={bpPctRank}
        quantityLabel={<GlossaryLink term="wildfire_likelihood">wildfire likelihood</GlossaryLink>}
      />
    );
  }, [
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    geographyLevel,
    geography,
    ranks
  ]);

  return (
    <>
      {geography && (
        <Paragraph
          size="xxlarge"
          color="var(--text-900) !important"
          style={{ fontWeight: 700 }}
          id="risk-description"
        >
          {comparisonText}
        </Paragraph>
      )}
    </>
  );
};

export default WildfireLikelihoodComparisonText;
