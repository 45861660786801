import React from "react";
import { Root } from "react-dom/client";
import { Box, Text } from "grommet";
import styled from "styled-components";
import { Provider } from "react-redux";
import store from "../store";

const PopupBox = styled(Box)`
  box-shadow: var(--box-shadow-medium);
  padding: 1rem;
  border-radius: var(--border-radius);
`;

const TractName = styled(Text)`
  font-weight: 700;
  font-family: var(--font-family);
  color: black;
`;

const SubHeader = styled(Text)`
  color: var(--text-800);
  font-weight: 400;
  font-family: var(--font-family);
`;

interface Props {
  readonly tractName: string;
  readonly instructions: string;
}

const VulnerablePopulationsPopUpComponent = ({ tractName, instructions }: Props) => {
  return (
    <PopupBox>
      <TractName size="small">{tractName}</TractName>
      <SubHeader size="small">{instructions}</SubHeader>
    </PopupBox>
  );
};

const VulnerablePopulationsPopUp = (reactRoot: Root, tractName: string, instructions: string) => {
  reactRoot.render(
    <Provider store={store}>
      <VulnerablePopulationsPopUpComponent tractName={tractName} instructions={instructions} />
    </Provider>
  );
};

export default VulnerablePopulationsPopUp;
