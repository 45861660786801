import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { RRZAction } from "../actions";
import { ActionTypes, rrzFetchFailure, rrzFetchSuccess } from "../actions/riskReductionZone";

import { fetchRRZ } from "../api";
import { RRZResource } from "../models";
import { Resource } from "../resource";

export type RRZState = Resource<RRZResource>;

export const initialState: RRZState = {
  isPending: false
};

const RRZCollectionReducer: LoopReducer<RRZState, RRZAction> = (
  state: RRZState = initialState,
  action: RRZAction
): RRZState | Loop<RRZState, RRZAction> => {
  switch (action.type) {
    case ActionTypes.RRZ_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchRRZ, {
          args: [action.level, action.geoId],
          successActionCreator: rrzFetchSuccess,
          failActionCreator: rrzFetchFailure
        })
      );
    case ActionTypes.RRZ_FETCH_SUCCESS:
      return {
        resource: {
          rrz: action.rrz
        }
      };
    case ActionTypes.RRZ_FETCH_FAILURE:
      return {
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default RRZCollectionReducer;
