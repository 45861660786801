import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const PlayIcon = (props: any) => {
  const { fill } = props;
  return (
    <ThemeProvider theme={IconTheme}>
      <StyledIcon id="PlayIcon" viewBox="0 0 96 94" {...props}>
        <rect
          x="0.714294"
          y="0.5"
          width="94.5714"
          height="93"
          rx="46.5"
          fill="white"
          stroke="none"
        />
        <path
          d="M68.8464 47L37.5768 64.7535L37.5768 29.2465L68.8464 47Z"
          fill={fill}
          stroke="none"
        />
      </StyledIcon>
    </ThemeProvider>
  );
};

export default PlayIcon;
