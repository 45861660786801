import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const ResponseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 26 21" a11yTitle="Response" {...props}>
      <path
        stroke="none"
        d="M 9.875 8.46875 L 12.5312 11.125 L 3.50781 20.1484 C 3 20.6562 2.21875 20.6562 1.75 20.1484 L 0.851562 19.2891 C 0.34375 18.7812 0.34375 18 0.851562 17.4922 L 9.875 8.46875 Z M 25.5 6.75 V 8 C 25.5 12.8047 21.5547 16.75 16.75 16.75 H 15.5 L 15.4609 12.2969 L 9.60156 6.4375 C 9.09375 5.92969 9.09375 5.14844 9.60156 4.64062 L 13.3516 0.890625 C 13.625 0.65625 13.9375 0.5 14.25 0.5 C 14.5625 0.5 14.875 0.65625 15.1484 0.890625 L 16.2812 2.0625 L 17.4531 0.890625 C 17.9609 0.382812 18.7422 0.382812 19.25 0.890625 L 20.1094 1.75 C 20.6172 2.25781 20.6172 3.03906 20.1094 3.54688 L 18.9375 4.71875 L 21.0078 6.75 H 25.5 Z M 22.8438 9.25 H 21.3594 C 20.7344 10.8125 19.5234 12.0234 18 12.6484 V 14.1328 C 20.4219 13.625 22.375 11.7109 22.8438 9.25 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default ResponseIcon;
