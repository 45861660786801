import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { SearchGeographiesAction } from "../actions";
import {
  ActionTypes,
  geographyListFetchFailure,
  geographyListFetchSuccess
} from "../actions/geographyList";

import { fetchGeographyList } from "../api";
import {
  Geography,
  GeographyList,
  GeographyListResource,
  SearchEntry,
  SearchEntryList
} from "../models";
import { Resource } from "../resource";

export type GeographyListState = Resource<GeographyListResource>;

export const initialState: GeographyListState = {
  isPending: false
};

const buildSearchList = (geographies: GeographyList) => {
  return Object.values(geographies)
    .map((geography: Geography) => {
      const breadcrumbArray = geography.breadcrumb.split("/");
      const key = breadcrumbArray[breadcrumbArray?.length - 1];
      const stateKey = breadcrumbArray[0];
      const counties =
        geography.geo_level === "county" ? [key] : geography.counties?.map(({ id }) => id) || [];
      const geographyType = (() => {
        switch (geography.geo_level) {
          case "place":
            return "community";
          case "tribal":
            return "tribal area";
          default:
            return geography.geo_level;
        }
      })();
      const searchText = geography.search ?? geography.name;

      return {
        key,
        type: geographyType,
        state: stateKey,
        counties,
        tribal_area: geographyType === "tribal area" ? key : "",
        community: geographyType === "community" ? key : "",
        searchText
      } as SearchEntry;
    })
    .sort((a, b) => a.searchText.localeCompare(b.searchText));
};

// In Redux, your reducer listens for actions which may optionally have a
// payload. Each type of action is handled separately within the reducer to
// return a new state object based on some logic. `redux-loop` handles effects
// within the reducer, so now instead of just returning a new state object (eg.
// `GeographyListState`) we may also return a `Loop` which wraps the new state plus some
// effect (very often a `Cmd.run`).
const geographyListReducer: LoopReducer<GeographyListState, SearchGeographiesAction> = (
  state: GeographyListState = initialState,
  action: SearchGeographiesAction
): GeographyListState | Loop<GeographyListState, SearchGeographiesAction> => {
  switch (action.type) {
    case ActionTypes.GEOLIST_CLEAR:
      return {
        resource: {
          geographies: {},
          searchList: []
        }
      };
    case ActionTypes.GEOLIST_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchGeographyList, {
          args: [action.searchTerm],
          successActionCreator: geographyListFetchSuccess,
          failActionCreator: geographyListFetchFailure
        })
      );
    case ActionTypes.GEOLIST_FETCH_SUCCESS:
      const s: SearchEntryList = buildSearchList(action.geographies);
      return {
        resource: {
          geographies: action.geographies,
          searchList: s
        }
      };
    case ActionTypes.GEOLIST_FETCH_FAILURE:
      return {
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default geographyListReducer;
