import { Box, Grid, Grommet, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { setDisplayExpandedMenu } from "../actions/vulnerablePopulations";
import CollapsedMenuIcon from "../icons/CollapsedMenuIcon";
import { isWindowNarrow } from "../utils";
import styled from "styled-components";
import CustomGrommetTheme from "../grommet-theme";
import store, { useAppSelector } from "../store";
import mapboxgl from "mapbox-gl";
import VulnerablePopWidgetExpanded from "./VulnerablePopWidgetExpanded";

const ExpandedMobileMenu = styled.div`
  width: 28rem;
  position: absolute;
  top: 3.2rem;
  background: white;
  box-shadow: var(--box-shadow-medium);
  border-radius: var(--border-radius);
`;

const WidgetComponent = () => {
  const vulnerablePopulationsState = useAppSelector(state => state.vulnerablePopulations);
  const { displayExpandedMenu } = vulnerablePopulationsState;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleExpandedMenu = () => {
    store.dispatch(setDisplayExpandedMenu(!displayExpandedMenu));
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      if (isWindowNarrow(window.innerWidth)) {
        store.dispatch(setDisplayExpandedMenu(false));
      }
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const WidgetCollapsed = () => {
    return (
      <Box margin=".7rem" onClick={toggleExpandedMenu} focusIndicator={false} width={"max-content"}>
        <Grid columns={["auto", "auto"]} gap="xsmall" align="center">
          <CollapsedMenuIcon size="13px" color="var(--text-800)" />
          <Text weight="bold" size="1.4rem" color="black">
            Select populations
          </Text>
        </Grid>
      </Box>
    );
  };

  return (
    <Grommet theme={CustomGrommetTheme} options={{ box: { cssGap: true } }}>
      <Box
        width={isWindowNarrow(windowWidth) ? "auto" : "27.5rem"}
        style={{
          backgroundColor: displayExpandedMenu ? "var(--warm-gray-300)" : "#FFFFFF",
          borderRadius: "var(--border-radius)"
        }}
      >
        {isWindowNarrow(windowWidth) ? <WidgetCollapsed /> : <VulnerablePopWidgetExpanded />}
      </Box>
      {displayExpandedMenu ? (
        <ExpandedMobileMenu>
          <VulnerablePopWidgetExpanded />
        </ExpandedMobileMenu>
      ) : (
        ""
      )}
    </Grommet>
  );
};

const VulnerablePopulationsWidget = (el: HTMLDivElement): mapboxgl.IControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(
        <Provider store={store}>
          <WidgetComponent />
        </Provider>
      );
      return el;
    },
    onRemove: () => {
      root.render(undefined);
    }
  };
};

export default VulnerablePopulationsWidget;
