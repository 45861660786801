import React from "react";
import { Box, Heading, Paragraph, ResponsiveContext, Layer, Button } from "grommet";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { setSelectedModalTract } from "../actions/vulnerablePopulations";
import { GeographyVulnerablePopulationData, VulnerablePopulationNameAndData } from "../models";
import VulnerablePopulationsTable from "./VulnerablePopulationsTable";
import store from "../store";
import CloseIcon from "../icons/CloseIcon";

interface StateProps {
  readonly geography: string;
  readonly detailPlaceId: string;
  readonly vulnerablePopulations: VulnerablePopulationsState;
}

const VulnerablePopulationsComponent = (props: StateProps) => {
  const { detailPlaceId, vulnerablePopulations, geography } = props;

  const descriptionText = (
    <>
      <Paragraph size="xlarge">
        Social and economic factors can make it more difficult for some people to prepare for,
        respond to, and recover from wildfire. Vulnerable populations may lack access to resources,
        experience cultural and institutional barriers, have limited mobility, or have medical
        conditions exacerbated by stress or smoke.
      </Paragraph>
      <Paragraph size="xlarge">
        For example, people over age 65 and people who are disabled are more susceptible to air
        pollution and particulates associated with wildfire smoke. Language barriers can make it
        difficult to follow directions during an evacuation or to access support after a disaster.
        Race and ethnicity are strongly correlated with disparities in health and access to aid and
        resources. Wildfires disproportionately impact people with low incomes because of factors
        such as inadequate housing and a diminished ability to evacuate or relocate.
      </Paragraph>
    </>
  );
  const closeTractModal = () => {
    store.dispatch(setSelectedModalTract(undefined));
  };

  const tractsData =
    "resource" in vulnerablePopulations.tractInfo
      ? vulnerablePopulations.tractInfo.resource.tractInfo.geos
      : undefined;
  const detailPlaceData = (
    tractsData && tractsData[detailPlaceId] ? tractsData[detailPlaceId] : undefined
  ) as GeographyVulnerablePopulationData;
  const modalTract = (
    tractsData && tractsData.tracts
      ? tractsData.tracts[
          vulnerablePopulations.selectedModalTract as keyof typeof tractsData.tracts
        ]
      : undefined
  ) as VulnerablePopulationNameAndData;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box pad={{ horizontal: "medium", bottom: "medium" }} className="content-box">
            <Heading level={3}>About vulnerable populations</Heading>
            {descriptionText}
          </Box>
          <Box pad={{ horizontal: "medium", bottom: "medium" }} className="content-box">
            <Heading level={3}>Vulnerable populations in {geography}</Heading>
            <VulnerablePopulationsTable
              tableData={detailPlaceData}
              detailPlaceId={detailPlaceId}
              mode={size === "small" ? "narrow" : "page"}
              caption={`Vulnerable populations: All areas in ${geography}`}
            />
            <Paragraph size="small">
              Data are from the U.S. Census Bureau, American Community Survey. See{" "}
              <a href="/about/methods/">methods</a> for more information.
            </Paragraph>
          </Box>
          {modalTract && !vulnerablePopulations.displayExpandedMenu && (
            <Layer
              onEsc={closeTractModal}
              onClickOutside={closeTractModal}
              animation="fadeIn"
              background={{
                opacity: 0
              }}
            >
              <Box
                fill={true}
                background={{
                  color: "rgba(0,0,0,.2)"
                }}
                onClick={closeTractModal}
                justify="center"
                align="center"
              >
                <Box
                  background="white"
                  onClick={e => e.stopPropagation()}
                  width={{ max: "400px" }}
                  elevation="large"
                  style={{
                    borderRadius: "var(--border-radius)"
                  }}
                >
                  <Box direction="row" justify="between" gap="medium" pad="medium">
                    <Heading level={4} style={{ fontSize: "1.5rem" }} alignSelf="center">
                      {modalTract.name}
                    </Heading>
                    <Button
                      onClick={closeTractModal}
                      icon={<CloseIcon size="11px" fill="var(--text-800)" />}
                      secondary
                      size="small"
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        justifyContent: "center"
                      }}
                    />
                  </Box>
                  <Box style={{ borderBottom: "1px solid #e9e5e2" }}>
                    <VulnerablePopulationsTable
                      tableData={modalTract.data}
                      detailPlaceId={vulnerablePopulations.selectedModalTract}
                      mode="modal"
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default VulnerablePopulationsComponent;
