export enum ActionTypes {
  GLOSSARY_TERM_SET = "GLOSSARY_TERM_SET",
  GLOSSARY_TERM_CLEAR = "GLOSSARY_TERM_CLEAR",
  GLOSSARY_OPEN_TOGGLE = "GLOSSARY_OPEN_TOGGLE"
}

export type GlossaryControlAction =
  | { readonly type: ActionTypes.GLOSSARY_TERM_SET; readonly term: string }
  | { readonly type: ActionTypes.GLOSSARY_TERM_CLEAR }
  | { readonly type: ActionTypes.GLOSSARY_OPEN_TOGGLE; readonly open: boolean };

export function setGlossaryTerm(term: string): GlossaryControlAction {
  return {
    type: ActionTypes.GLOSSARY_TERM_SET,
    term
  };
}

export function clear(): GlossaryControlAction {
  return {
    type: ActionTypes.GLOSSARY_TERM_CLEAR
  };
}

export function toggleGlossary(open: boolean): GlossaryControlAction {
  return {
    type: ActionTypes.GLOSSARY_OPEN_TOGGLE,
    open
  };
}
