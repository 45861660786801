import { RiskClassLevel, RRZClass } from "./models";
import HouseIcon from "./icons/HouseIcon";
import EquitableRiskIcon from "./icons/EquitableRiskIcon";
import EvacuationReadinessIcon from "./icons/EvacuationReadinessIcon";
import HazardousFuelsIcon from "./icons/HazardousFuelsIcon";
import MapIcon from "./icons/MapIcon";
import PreventIgnitionsIcon from "./icons/PreventIgnitionsIcon";
import RecoveryRebuildingIcon from "./icons/RecoveryRebuildingIcon";
import ResponseIcon from "./icons/ResponseIcon";
import SmokeReadyIcon from "./icons/SmokeReadyIcon";

export const vulnerablePopulationsGlossaryTermLabels = {
  family_poverty: {
    long: "Families in poverty",
    short: "Poverty",
    subCategory: false
  },
  disabled: {
    long: "People with disabilities",
    short: "Disabilities",
    subCategory: false
  },
  over_65: {
    long: "People over 65 years",
    short: "Over 65 years",
    subCategory: false
  },
  under_5: {
    long: "People under 5 years",
    short: "Under 5 years",
    subCategory: false
  },
  minority: {
    long: "People of color",
    short: "People of color",
    subCategory: false
  },
  pop_black: {
    long: "Black",
    short: "Black",
    subCategory: true
  },
  pop_ind: {
    long: "Native American",
    short: "Native American",
    subCategory: true
  },
  pop_hisp: {
    long: "Hispanic",
    short: "Hispanic",
    subCategory: true
  },
  not_good_english: {
    long: "Difficulty with English",
    short: "Limited English",
    subCategory: false
  },
  no_car: {
    long: "Households with no car",
    short: "No car",
    subCategory: false
  },
  mobile: {
    long: "Mobile homes",
    short: "Mobile homes",
    subCategory: false
  }
};

export const riskFillColors = {
  [RiskClassLevel.Low]: "#EBCB77",
  [RiskClassLevel.Medium]: "#D68000",
  [RiskClassLevel.High]: "#FF0000",
  [RiskClassLevel.VeryHigh]: "#C91D13"
};

export const riskLabelColors = {
  [RiskClassLevel.Low]: "#CDA934",
  [RiskClassLevel.Medium]: "#A86500",
  [RiskClassLevel.High]: "#E80000",
  [RiskClassLevel.VeryHigh]: "#C91D13"
};

export const rrzColors = {
  [RRZClass.Minimal]: "#B9BBC2",
  [RRZClass.Indirect]: "#F5C766",
  [RRZClass.Direct]: "#FF3B54",
  [RRZClass.Transmission]: "#D5E3D5"
};

export enum RRZStructureScale {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

export const RRZHouseIconCount = {
  [RRZStructureScale.Small]: 10,
  [RRZStructureScale.Medium]: 20,
  [RRZStructureScale.Large]: 25
};

export const actions = {
  IgnitionResistantHomes: {
    name: "Ignition-Resistant Homes",
    icon: HouseIcon,
    description: "Use wildfire-resistant building materials and landscaping.",
    url: "/reduce-risk/ignition-resistant-homes"
  },
  LandUsePlanning: {
    name: "Land Use Planning",
    icon: MapIcon,
    description:
      "Direct how communities develop in wildfire-prone areas using plans and regulations.",
    url: "/reduce-risk/land-use-planning"
  },
  EvacuationAndReadiness: {
    name: "Evacuation & Readiness",
    icon: EvacuationReadinessIcon,
    description:
      "Be ready for wildfires with community alerts, evacuation routes, and family plans.",
    url: "/reduce-risk/evacuation-readiness"
  },
  EquitableRiskReduction: {
    name: "Equitable Risk Reduction",
    icon: EquitableRiskIcon,
    description: "Address the social and economic vulnerabilities of people in the community.",
    url: "/reduce-risk/equitable-risk-reduction"
  },
  SmokeReady: {
    name: "Smoke Ready",
    icon: SmokeReadyIcon,
    description: "Prepare for the health impacts of wildfire smoke in the community. ",
    url: "/reduce-risk/smoke-ready"
  },
  PreventIgnitions: {
    name: "Prevent Ignitions",
    icon: PreventIgnitionsIcon,
    description: "Reduce ignitions from campfires, debris burning, vehicles, and other sources.",
    url: "/reduce-risk/prevent-ignitions"
  },
  Response: {
    name: "Response",
    icon: ResponseIcon,
    description: "Manage and fight wildfires to protect lives, communities, and resources.",
    url: "/reduce-risk/wildfire-response"
  },
  HazardousFuelsManagement: {
    name: "Hazardous Fuels Management",
    icon: HazardousFuelsIcon,
    description: "Cut, thin, burn, or otherwise reduce flammable vegetation on the landscape.",
    url: "/reduce-risk/fuel-treatments"
  },
  RecoveryAndRebuilding: {
    name: "Recovery & Rebuilding",
    icon: RecoveryRebuildingIcon,
    description: "Restore the landscape and community following a wildfire.",
    url: "/reduce-risk/recovery"
  }
};

export const stateToFips: Record<string, string> = {
  Alabama: "01",
  Alaska: "02",
  Arizona: "04",
  Arkansas: "05",
  California: "06",
  Colorado: "08",
  Connecticut: "09",
  Delaware: "10",
  "District of Columbia": "11",
  Florida: "12",
  Geogia: "13",
  Hawaii: "15",
  Idaho: "16",
  Illinois: "17",
  Indiana: "18",
  Iowa: "19",
  Kansas: "20",
  Kentucky: "21",
  Louisiana: "22",
  Maine: "23",
  Maryland: "24",
  Massachusetts: "25",
  Michigan: "26",
  Minnesota: "27",
  Mississippi: "28",
  Missouri: "29",
  Montana: "30",
  Nebraska: "31",
  Nevada: "32",
  "New Hampshire": "33",
  "New Jersey": "34",
  "New Mexico": "35",
  "New York": "36",
  "North Carolina": "37",
  "North Dakota": "38",
  Ohio: "39",
  Oklahoma: "40",
  Oregon: "41",
  Pennsylvania: "42",
  "Rhode Island": "44",
  "South Carolina": "45",
  "South Dakota": "46",
  Tennessee: "47",
  Texas: "48",
  Utah: "49",
  Vermont: "50",
  Virginia: "51",
  Washington: "53",
  "West Virginia": "54",
  Wisconsin: "55",
  Wyoming: "56"
};
