import React from "react";
import { ComparisonLevel, GeographyLevel, Screen } from "../models";
import { WildfireLikelihoodState } from "../reducers/wildfireLikelihood";
import { RRZState } from "../reducers/riskReductionZone";
import { RiskToHomesState } from "../reducers/riskToHomes";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import RiskToHomesComponent from "./RiskToHomes";
import WildfireLikelihoodComponent from "./WildfireLikelihood";
import RRZComponent from "./RiskReductionZone";
import VulnerablePopulationsComponent from "./VulnerablePopulations";

interface ComponentProps {
  readonly comparisonLevel: ComparisonLevel;
  readonly comparisonAbbr: string;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly detailPlaceId: string;
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly screen: Screen;
  readonly rrz: RRZState;
  readonly riskToHomes: RiskToHomesState;
  readonly vulnerablePopulations: VulnerablePopulationsState;
  readonly wildfireLikelihood: WildfireLikelihoodState;
}

/* This is just a big switch statement. Its purpose is just to get the ugly repetition of the
 * different but substantially-overlapping risk factor component declarations out of the
 * file where there are interesting things happening and into a place where it will be easy
 * to see but also easy to ignore.
 */
const RiskDetailComponent = (props: ComponentProps) => {
  const {
    comparisonLevel,
    comparisonAbbr,
    comparisonCountyName,
    comparisonStateName,
    detailPlaceId,
    geography,
    geographyLevel,
    screen,
    rrz,
    riskToHomes,
    vulnerablePopulations,
    wildfireLikelihood
  } = props;

  switch (screen) {
    case Screen.RiskReductionZones:
      return <RRZComponent detailPlaceId={detailPlaceId} rrz={rrz} geography={geography} />;

    case Screen.RiskToHomes:
      return (
        <RiskToHomesComponent
          comparisonLevel={comparisonLevel}
          comparisonAbbr={comparisonAbbr}
          comparisonCountyName={comparisonCountyName}
          comparisonStateName={comparisonStateName}
          detailPlaceId={detailPlaceId}
          geography={geography}
          geographyLevel={geographyLevel}
          riskToHomes={riskToHomes}
        />
      );

    case Screen.VulnerablePopulations:
      return (
        <VulnerablePopulationsComponent
          detailPlaceId={detailPlaceId}
          geography={geography}
          vulnerablePopulations={vulnerablePopulations}
        />
      );

    case Screen.WildfireLikelihood:
      return (
        <WildfireLikelihoodComponent
          comparisonLevel={comparisonLevel}
          comparisonCountyName={comparisonCountyName}
          comparisonStateName={comparisonStateName}
          detailPlaceId={detailPlaceId}
          geography={geography}
          geographyLevel={geographyLevel}
          wildfireLikelihood={wildfireLikelihood}
        />
      );
    default:
      return null;
  }
};

export default RiskDetailComponent;
