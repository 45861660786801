import { Box, BoxProps, ResponsiveContext } from "grommet";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Geography,
  GeographyLevel,
  Screen,
  StateGeography,
  BaseMapLayer,
  VulnerablePopulationTractDictionary
} from "../models";
import { setSelectedModalTract, setSelectedPanelTract } from "../actions/vulnerablePopulations";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { addPlacesLabelLayers } from "./mapLabels";
import {
  loadBoundaryLayers,
  toggleRPSLayer,
  toggleRRZLayer,
  toggleBPLayer,
  toggleSelectedLayer,
  togglePopulatedAreaLayer
} from "./mapLayers";
import {
  initializeVulnerablePopulationPopup,
  toggleCensusTractsLayer
} from "./mapLayersVulnerablePopulation";
import { defaultMapOptions, miniMapOptions } from "./mapStyles";
import PopulatedAreaMaskToggleControl from "./PopulatedAreaMaskControl";
import VulnerablePopulationsWidget from "./VulnerablePopWidget";
import VulnerablePopMapSelector from "./VulnerablePopMapSelector";
import VulnerablePopulationsTractDetailPanel from "./VulnerablePopTractDetailPanel";
import MapLegend, { MapLegendComponentProps } from "./MapLegend";
import store from "../store";
import { attachMapListeners } from "./mapListeners";
import { accessibleMapLabel } from "./accessibleLabels";
import AccessibleTractsWidget from "./AccessibleTracts";

/* tslint:disable-next-line:no-object-mutation */
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || "";

export type ReactIControl = mapboxgl.IControl & { readonly render: (props: any) => HTMLDivElement };

interface Props {
  readonly geographyLevel: GeographyLevel;
  readonly detailPlaceId: string;
  readonly geography: Geography;
  readonly mapStyleStateAbbrev: string | undefined;
  readonly stateGeography: StateGeography | undefined;
  readonly screen: Screen;
  readonly showPopulatedAreaMask: boolean;
  readonly mapContainerId: string;
  readonly displayControlsAndLabels: boolean;
  readonly selectedBaseMapLayer?: BaseMapLayer;
  readonly vulnerablePopulations?: VulnerablePopulationsState;
  readonly riskToHomesProps?: MapLegendComponentProps;
  readonly likelihoodProps?: MapLegendComponentProps;
  readonly rrzProps?: MapLegendComponentProps;
}

// Helper function to trigger effect-driven map changes.
// Waiting for a map `idle` event is the only consistent way we've been able to get them to
// load at the appropriate time.  Using a `load` event doesn't quite work because there's
// still a chance the referenced layers won't have loaded, which will result in an exception.
const runOnMapIdle = (map: mapboxgl.Map, action: (map: mapboxgl.Map) => void) => {
  map && (map.isStyleLoaded() ? action(map) : map.once("idle", () => action(map)));
};

// Helper function to add a buffer to a bounds array.
const bufferBounds = ([swLon, swLat, neLon, neLat]: readonly number[], bufferDeg: number) => {
  // Assume Northern / Western hemispheres because this is a US-specific app.  The typecast is
  // necessary because returning a four-element array results in an auto-detected return type of
  // number[], while map.setMaxBounds() requires [number, number, number, number].
  /* tslint:disable-next-line: readonly-array */
  return [swLon - bufferDeg, swLat - bufferDeg, neLon + bufferDeg, neLat + bufferDeg] as [
    number,
    number,
    number,
    number
  ];
};

const Map = ({
  geography,
  mapStyleStateAbbrev,
  stateGeography,
  screen,
  geographyLevel,
  detailPlaceId,
  showPopulatedAreaMask,
  mapContainerId,
  displayControlsAndLabels,
  selectedBaseMapLayer,
  vulnerablePopulations,
  riskToHomesProps,
  likelihoodProps,
  rrzProps,
  ...props
}: Props & BoxProps) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const size = useContext(ResponsiveContext);
  const history = useHistory();
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [populatedAreasToggle, setPopulatedAreasToggle] = useState<mapboxgl.IControl | null>(null);
  const [accessibleTractsWidget, setAccessibleTractsWidget] = useState<mapboxgl.IControl | null>(
    null
  );
  const [vulnerablePopWidget, setVulnerablePopWidget] = useState<mapboxgl.IControl | null>(null);
  const [vulnerablePopMapSelector, setVulnerablePopMapSelector] = useState<ReactIControl | null>(
    null
  );
  const [vulnerablePopTractPanel, setVulnerablePopTractPanel] = useState<mapboxgl.IControl | null>(
    null
  );
  const [mapLegend, setMapLegend] = useState<ReactIControl | null>(null);
  const [clickedLabelBreadcrumb, setClickedLabelBreadcrumb] = useState<string>("");

  // Initialize the map
  useEffect(() => {
    const mapStyle = displayControlsAndLabels ? defaultMapOptions : miniMapOptions;
    const map = new mapboxgl.Map({
      ...mapStyle,
      container: mapContainerId
    });

    map.touchZoomRotate.enable();
    map.touchZoomRotate.disableRotation();

    map.getCanvas().tabIndex = displayControlsAndLabels ? 0 : -1;

    // Create an HTML element with the necessary classes to be used as a container for a Mapbox GL
    // IControl
    const createMapCtrlContainer = (cssClasses: readonly string[]) => {
      const containerEl = document.createElement("div");
      containerEl.classList.add(...cssClasses);
      return containerEl;
    };
    // create containers for populated area mask toggle, vulnerable population widget, map legend
    const popAreasToggleCtrl = PopulatedAreaMaskToggleControl(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth"
      ])
    );
    const vulnerablePopSelectionWidget = VulnerablePopulationsWidget(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth",
        "vulnerable-populations-widget"
      ])
    );
    const vulnerablePopulationsMapLayerSelector = VulnerablePopMapSelector(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth",
        "vulnerable-populations-map-selector"
      ])
    );
    const vulnerablePopTractPanel = VulnerablePopulationsTractDetailPanel(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth",
        "vulnerable-populations-tract-detail-panel"
      ])
    );
    const accessibleTractsSelectionWidget = AccessibleTractsWidget(
      createMapCtrlContainer(["accessible-tracts-widget"]),
      geography.bounds,
      detailPlaceId
    );

    // MapLegend takes props rather than connecting directly to state
    const mapLegendCtrl = MapLegend(
      createMapCtrlContainer(["mapboxgl-ctrl", "mapboxgl-ctrl-group", "mapboxgl-input-autowidth"]),
      {}
    );
    // Reset the state variables that are used to control the widget and tract details components
    // on the Vulnerable Populations tab, since they could still be set from viewing a different
    // place.
    store.dispatch(setSelectedPanelTract(undefined));
    store.dispatch(setSelectedModalTract(undefined));

    // Add reference to accessible description
    map && map.getCanvas().setAttribute("aria-describedby", "risk-description");

    const onLoad = () => {
      loadBoundaryLayers(map);
      if (displayControlsAndLabels) {
        map.addControl(
          new mapboxgl.ScaleControl({ maxWidth: 100, unit: "imperial" }),
          "bottom-right"
        );
        map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "bottom-right");
        addPlacesLabelLayers(map);
      } else {
        // On the Overview page, disable keyboard focus for the Mapbox logo and attribution.
        // This makes it easier to tab through the 4 risk cards using a keyboard or screen reader.
        // The user can still interact with both element using their mouse and, on detail pages, their keyboard
        const mapboxElements = mapRef.current?.querySelectorAll(
          "a.mapboxgl-ctrl-logo, button.mapboxgl-ctrl-attrib-button"
        );
        mapboxElements?.forEach(elem => elem.setAttribute("tabindex", "-1"));
      }
    };
    map.once("load", onLoad);
    if (displayControlsAndLabels) {
      setPopulatedAreasToggle(popAreasToggleCtrl);
      setAccessibleTractsWidget(accessibleTractsSelectionWidget);
      setVulnerablePopWidget(vulnerablePopSelectionWidget);
      setVulnerablePopMapSelector(vulnerablePopulationsMapLayerSelector);
      setVulnerablePopTractPanel(vulnerablePopTractPanel);
      setMapLegend(mapLegendCtrl);
    }
    setMap(map);
    // remove zoom on scroll for easier navigation of detail page
    map.scrollZoom.disable();
  }, [mapContainerId, displayControlsAndLabels, geographyLevel, geography, detailPlaceId]);

  useEffect(() => {
    if (map) {
      const tractInfo = (
        vulnerablePopulations && "resource" in vulnerablePopulations.tractInfo
          ? vulnerablePopulations.tractInfo.resource.tractInfo.geos.tracts
          : {}
      ) as VulnerablePopulationTractDictionary;

      // Get a function to pass into the event handlers that will show a tract popup.
      // Getting the function here reduces the number of times we have to create the React
      // root, which is expensive.
      const showTractPopup = initializeVulnerablePopulationPopup(map, tractInfo);

      return attachMapListeners(
        map,
        size,
        detailPlaceId,
        geography.bounds as mapboxgl.LngLatBoundsLike,
        vulnerablePopulations?.selectedPanelTract,
        tractInfo,
        showTractPopup,
        setClickedLabelBreadcrumb
      );
    }
  }, [map, size, detailPlaceId, geography, vulnerablePopulations]);

  // Listen to window width changes for the purposes of adding/removing listeners
  // to control the vulnerable populations modal on mobile or panel on desktop
  useEffect(() => {
    size === "small"
      ? store.dispatch(setSelectedPanelTract(undefined))
      : store.dispatch(setSelectedModalTract(undefined));
  }, [size]);

  // Set max bounds when the state or comparison level changes
  useEffect(() => {
    // Don't set max bounds if the user views a state directly because we'll be using national
    // breaks. Passing undefined to map.setMaxBounds() clears any previously set max bounds.
    // Buffering by a degree on all sides seems to provide a decent amount of play in the bounds
    // without allowing the user to stray too far from the state they're in.
    const maxBounds =
      stateGeography && stateGeography.bounds && mapStyleStateAbbrev !== "US"
        ? bufferBounds(stateGeography.bounds, 1.0)
        : undefined;
    map && map.setMaxBounds(maxBounds);
  }, [map, stateGeography, mapStyleStateAbbrev]);

  // Zoom to geometry when it changes
  useEffect(() => {
    const bounds = geography.bounds;
    map &&
      map.fitBounds(bounds as mapboxgl.LngLatBoundsLike, {
        maxZoom: defaultMapOptions.maxZoom,
        padding: 25,
        duration: 0
      });
  }, [map, geography, detailPlaceId]);

  // Show / hide the toggle control for turning the populated areas mask layer on and off
  // This needs to be before the map legend useEffect in order to keep this IControl above the
  // legend when they are vertically stacked.
  // It's only shown for the Risk to Homes and Wildfire Likelihood tabs.
  useEffect(() => {
    map &&
      populatedAreasToggle &&
      (screen === Screen.RiskToHomes || screen === Screen.WildfireLikelihood
        ? map.addControl(populatedAreasToggle, "top-right")
        : map.removeControl(populatedAreasToggle));
  }, [map, screen, size, populatedAreasToggle]);

  useEffect(() => {
    map &&
      accessibleTractsWidget &&
      (screen === Screen.VulnerablePopulations
        ? map.addControl(accessibleTractsWidget, "top-left")
        : map.removeControl(accessibleTractsWidget));
  }, [map, screen, accessibleTractsWidget]);

  // Show / hide the Vulnerable Populations widget and panel
  // The control of the widget needs to be above the useEffect for the base map selector so the
  // widget will stay above the selector when they are vertically stacked.
  useEffect(() => {
    if (map && vulnerablePopWidget) {
      !vulnerablePopulations?.selectedPanelTract && screen === Screen.VulnerablePopulations
        ? size === "small"
          ? map.removeControl(vulnerablePopWidget)
          : map.addControl(vulnerablePopWidget, "top-left")
        : map.removeControl(vulnerablePopWidget);
    }
    if (map && vulnerablePopTractPanel) {
      vulnerablePopulations?.selectedPanelTract && screen === Screen.VulnerablePopulations
        ? map.addControl(vulnerablePopTractPanel, "top-left")
        : map.removeControl(vulnerablePopTractPanel);
    }
  }, [map, screen, size, vulnerablePopTractPanel, vulnerablePopWidget, vulnerablePopulations]);

  useEffect(() => {
    if (map && vulnerablePopMapSelector) {
      screen === Screen.VulnerablePopulations && size !== "small"
        ? map.addControl(vulnerablePopMapSelector, "top-right")
        : map.removeControl(vulnerablePopMapSelector);
    }
  }, [map, screen, size, vulnerablePopMapSelector]);

  // Display appropriate map legend in the proper position depending on page state
  useEffect(() => {
    // The vulnerable populations screen is special because it can display multiple layers.
    // So we can handle the easy cases (i.e., any other screen *besides* Vulnerable Populations)
    // first.
    // If on small screen, don't render any legend on the map, because it goes outside
    if (map && mapLegend) map.removeControl(mapLegend);
    if (size === "small") return;
    if (map && mapLegend && screen !== Screen.VulnerablePopulations) {
      map.addControl(mapLegend, "top-left");
      switch (screen) {
        case Screen.RiskToHomes:
          mapLegend.render(riskToHomesProps);
          break;
        case Screen.WildfireLikelihood:
          mapLegend.render(likelihoodProps);
          break;
        case Screen.RiskReductionZones:
          mapLegend.render(rrzProps);
          break;
        default:
          break;
      }
    } else if (map && vulnerablePopMapSelector && screen === Screen.VulnerablePopulations) {
      const sharedProps = {
        isVulPopNarrowScreen: size !== "large"
      };
      switch (selectedBaseMapLayer) {
        case BaseMapLayer.riskToHomes:
          vulnerablePopMapSelector.render({
            ...sharedProps,
            ...riskToHomesProps
          });
          break;
        case BaseMapLayer.wildfireLikelihood:
          vulnerablePopMapSelector.render({
            ...sharedProps,
            ...likelihoodProps
          });
          break;
        case BaseMapLayer.riskReductionZones:
          vulnerablePopMapSelector.render({
            ...sharedProps,
            ...rrzProps
          });
          break;
        default:
          vulnerablePopMapSelector.render({
            ...sharedProps
          });
      }
    }
  }, [
    map,
    mapLegend,
    vulnerablePopMapSelector,
    screen,
    selectedBaseMapLayer,
    mapStyleStateAbbrev,
    geography.state.name,
    riskToHomesProps,
    likelihoodProps,
    rrzProps,
    size
  ]);

  // Toggle the selected layer
  useEffect(() => {
    const doToggle = (map: mapboxgl.Map) => {
      toggleSelectedLayer(
        map,
        geography.name,
        geographyLevel,
        detailPlaceId,
        screen,
        displayControlsAndLabels
      );
    };
    map && runOnMapIdle(map, doToggle);
  }, [map, geography.name, geographyLevel, detailPlaceId, screen, displayControlsAndLabels]);

  // Load/show the corresponding layer when a screen is selected, and hide the others.
  useEffect(() => {
    const toggleLayers = (map: mapboxgl.Map) => {
      toggleRPSLayer(
        map,
        mapStyleStateAbbrev || "US",
        screen === Screen.RiskToHomes ||
          (screen === Screen.VulnerablePopulations &&
            selectedBaseMapLayer === BaseMapLayer.riskToHomes)
      );
      toggleRRZLayer(
        map,
        screen === Screen.RiskReductionZones ||
          (screen === Screen.VulnerablePopulations &&
            selectedBaseMapLayer === BaseMapLayer.riskReductionZones)
      );
      toggleBPLayer(
        map,
        screen === Screen.WildfireLikelihood ||
          (screen === Screen.VulnerablePopulations &&
            selectedBaseMapLayer === BaseMapLayer.wildfireLikelihood)
      );
    };
    map && runOnMapIdle(map, toggleLayers);
  }, [
    map,
    screen,
    mapStyleStateAbbrev,
    selectedBaseMapLayer,
    vulnerablePopulations,
    detailPlaceId,
    displayControlsAndLabels,
    size
  ]);

  // Load/show the vulnerable population layers
  useEffect(() => {
    const toggle = (map: mapboxgl.Map) => {
      if (
        vulnerablePopulations &&
        "resource" in vulnerablePopulations.tractInfo &&
        detailPlaceId in vulnerablePopulations.tractInfo.resource.tractInfo.geos
      ) {
        const {
          tractInfo: {
            resource: { tractInfo }
          }
        } = vulnerablePopulations;
        toggleCensusTractsLayer(
          map,
          screen === Screen.VulnerablePopulations,
          tractInfo,
          vulnerablePopulations,
          size,
          displayControlsAndLabels
        );
        return;
      }
    };
    map && runOnMapIdle(map, toggle);
  }, [map, screen, vulnerablePopulations, detailPlaceId, size, displayControlsAndLabels]);

  useEffect(() => {
    const doToggle = (map: mapboxgl.Map) => {
      togglePopulatedAreaLayer(
        map,
        showPopulatedAreaMask &&
          (screen === Screen.RiskToHomes || screen === Screen.WildfireLikelihood)
      );
    };
    map && runOnMapIdle(map, doToggle);
  }, [map, screen, showPopulatedAreaMask]);

  // Update accessible label when basemap changes
  useEffect(() => {
    map &&
      (map.getCanvas().ariaLabel = accessibleMapLabel(
        screen,
        geography.name_short,
        vulnerablePopulations && vulnerablePopulations.selectedBaseMapLayer
      ));
  }, [map, screen, selectedBaseMapLayer, vulnerablePopulations, geography]);

  // Update app route when breadcrumb change is triggered by label click events
  useEffect(() => {
    if (
      [
        Screen.RiskReductionZones,
        Screen.RiskToHomes,
        Screen.VulnerablePopulations,
        Screen.WildfireLikelihood
      ].includes(screen) &&
      clickedLabelBreadcrumb
    ) {
      history.push(`/${screen}/${clickedLabelBreadcrumb}`);
    }
  }, [screen, clickedLabelBreadcrumb, history]);

  return (
    <Box
      id={mapContainerId}
      ref={mapRef}
      flex={true}
      style={{
        borderRadius: "var(--border-radius) !important",
        overflow: "visible"
      }}
      {...props}
    />
  );
};

export default Map;
