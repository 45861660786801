import { Loop, LoopReducer } from "redux-loop";
import { Action } from "../actions";
import { ActionTypes } from "../actions/glossaryControl";

export type GlossaryControlState = {
  readonly term: string;
  readonly open: boolean;
};

export const initialState: GlossaryControlState = {
  term: "",
  open: false
};

const glossaryControlReducer: LoopReducer<GlossaryControlState, Action> = (
  state: GlossaryControlState = initialState,
  action: Action
): GlossaryControlState | Loop<GlossaryControlState, Action> => {
  switch (action.type) {
    case ActionTypes.GLOSSARY_TERM_CLEAR:
      return initialState;
    case ActionTypes.GLOSSARY_TERM_SET:
      return {
        ...state,
        term: action.term
      };
    case ActionTypes.GLOSSARY_OPEN_TOGGLE:
      return {
        ...state,
        open: action.open
      };
    default:
      return state;
  }
};

export default glossaryControlReducer;
