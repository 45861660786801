import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const PlusIconCircle = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 18 18" a11yTitle="Plus" {...props}>
      <rect width="18" height="18" rx="9" fill={props.background} stroke="none" />
      <path
        d="M9.6875 5.21875V8.3125H12.7812H13.4688V9.6875H12.7812H9.6875V12.7812V13.4688H8.3125V12.7812V9.6875H5.21875H4.53125V8.3125H5.21875H8.3125V5.21875V4.53125H9.6875V5.21875Z"
        fill={props.color}
        stroke="none"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default PlusIconCircle;
