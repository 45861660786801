import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

export const RRZEmptyHouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="RRZEmptyHouseIcon" viewBox="0 0 20 21" a11yTitle="Empty House Icon" {...props}>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.2668L1.99993 10.1018V18.4148C1.99993 19.305 2.71628 20.0266 3.59993 20.0266H16.3999C17.2836 20.0266 17.9999 19.305 17.9999 18.4148V10.1016L10 2.2668ZM16.9999 10.5272L10 3.67177L2.99993 10.5274V18.4148C2.99993 18.7486 3.26856 19.0192 3.59993 19.0192H16.3999C16.7313 19.0192 16.9999 18.7486 16.9999 18.4148V10.5272Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3487 1.17678C10.1546 0.986372 9.84525 0.986372 9.65112 1.17678L0.84874 9.81063L0.151123 9.08888L8.95351 0.455036C9.53589 -0.116197 10.464 -0.116197 11.0464 0.455036L19.8487 9.08888L19.1511 9.81063L10.3487 1.17678Z"
        />
      </g>
    </StyledIcon>
  </ThemeProvider>
);

export const RRZHalfHouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="RRZHalfHouseIcon" viewBox="0 0 20 21" a11yTitle="Half House Icon" {...props}>
      <g clipPath="url(#clip0_7315_7563)">
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20H16.3999C17.2836 20 17.9999 19.2784 17.9999 18.3882V10.075L10 2.24019L10 2.24024V3.6452L10 3.64516L16.9999 10.5006V18.3882C16.9999 18.722 16.7313 18.9926 16.3999 18.9926H10V20ZM10 1.00736C10.1258 1.00738 10.2517 1.05498 10.3487 1.15017L19.1511 9.78402L19.8487 9.06227L11.0464 0.428425C10.7552 0.142825 10.3776 1.7281e-05 10 0V1.00736Z"
          style={{
            fill: props.fill
          }}
        />
        <path
          d="M9.30363 0.785384C9.3642 0.725969 9.43022 0.675878 9.5 0.635068V0.641659C9.42904 0.682787 9.36208 0.73333 9.301 0.793243L0.858202 9.07456L0.855582 9.07185L9.30363 0.785384ZM2.49993 18.3882V10.2852L9.5 3.42942V19.5H3.59993C2.99588 19.5 2.49993 19.0057 2.49993 18.3882Z"
          strokeWidth={1}
          style={{
            stroke: props.stroke
          }}
        />
      </g>
      <defs>
        <clipPath id="clip0_7315_7563">
          <rect width="20" height="21" fill="white" />
        </clipPath>
      </defs>
    </StyledIcon>
  </ThemeProvider>
);

export const RRZFullHouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="RRZFullHouseIcon"
      viewBox="0 0 20 21"
      a11yTitle="Full House Icon"
      {...props}
      strokeWidth="0"
    >
      <path
        d="M9.65112 1.17681C9.84525 0.986391 10.1546 0.986391 10.3487 1.17681L19.1511 9.81083L19.8487 9.08907L11.0464 0.455045C10.464 -0.1162 9.53589 -0.1162 8.95351 0.455045L0.151123 9.08907L0.84874 9.81083L9.65112 1.17681Z"
        style={{
          fill: props.stroke
        }}
      />
      <path
        d="M10 2.26644L17.9999 10.1014V18.4148C17.9999 19.305 17.2836 20.0266 16.3999 20.0266H3.59993C2.71628 20.0266 1.99993 19.305 1.99993 18.4148V10.1016L10 2.26644Z"
        strokeWidth={1}
        style={{
          stroke: props.stroke
        }}
      />
    </StyledIcon>
  </ThemeProvider>
);
