import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";

// Documentation on Grommet themes:
// https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
// The base theme this extends and overrides can be found here:
// https://github.com/grommet/grommet/blob/master/src/js/themes/base.js

const bodyHeight = "1.4";
const headingHeight = "1.2";
const borderRadius = "2px";
const headingWeight = 900;

const bodyFontSizes = {
  small: {
    size: "1.4rem",
    height: bodyHeight,
    maxWidth: undefined
  },
  medium: {
    size: "1.5rem",
    height: bodyHeight,
    maxWidth: undefined
  },
  large: {
    size: "1.8rem",
    height: bodyHeight,
    maxWidth: undefined
  },
  xlarge: {
    size: "2rem",
    height: bodyHeight,
    maxWidth: undefined
  },
  xxlarge: {
    size: "2.1rem",
    height: bodyHeight,
    maxWidth: undefined
  }
};

const headingFontSizes = {
  small: {
    size: "1.4rem", // 1.3rem
    height: headingHeight,
    maxWidth: undefined
  },
  large: {
    size: "2.4rem",
    height: headingHeight,
    maxWidth: undefined
  },
  xlarge: {
    size: "2.8rem",
    height: headingHeight,
    maxWidth: undefined
  },
  xxlarge: {
    size: "4.5rem",
    height: headingHeight,
    maxWidth: undefined
  }
};

const CustomGrommetTheme = deepMerge(grommet, {
  global: {
    // Hovered/focussed item in search
    active: {
      background: "warm-gray-300",
      color: "text-900",
      opacity: 1
    },
    animation: {
      duration: false
    },
    borderSize: {
      small: borderRadius
    },
    breakpoints: {
      small: {
        value: 768,
        edgeSize: {
          xsmall: "0.5rem",
          small: "1rem",
          medium: "1rem",
          large: "2rem",
          xlarge: "9rem"
        },
        size: {
          xsmall: "0.5rem",
          small: "1rem",
          medium: "1rem",
          large: "2rem",
          xlarge: "9rem"
        }
      },
      medium: {
        value: 940,
        edgeSize: {
          xsmall: "0.5rem",
          small: "1rem",
          medium: "2rem",
          large: "3rem",
          xlarge: "9rem"
        },
        size: {
          xsmall: "0.5rem",
          small: "1rem",
          medium: "2rem",
          large: "3rem",
          xlarge: "9rem"
        }
      },
      large: {
        edgeSize: {
          xsmall: "0.5rem",
          small: "1rem",
          medium: "2rem",
          large: "3rem",
          xlarge: "9rem"
        },
        size: {
          xsmall: "0.5rem",
          small: "1rem",
          medium: "2rem",
          large: "4rem",
          xlarge: "9rem"
        }
      }
    },
    colors: {
      // Warm gray
      "warm-gray-900": "#39312D",
      "warm-gray-700": "#7C675A",
      "warm-gray-600": "#A69183",
      "warm-gray-500": "#BCB5B0",
      "warm-gray-400": "#D8D3CF",
      "warm-gray-300": "#E9E5E2",
      "warm-gray-200": "#F3F2F0",
      "warm-gray-100": "#FAFAFA",
      // Highlight
      "highlight-800": "#00698F",
      "highlight-700": "#0095CB",
      "highlight-400": "#CEE8F5",
      "highlight-200": "#E5F8FF",
      "highlight-100": "#F2F7F9",
      // Text
      "text-900": "#333333",
      "text-800": "#555555",
      "text-700": "#757575",
      "text-500": "#CCCCCC",
      // Vulnerable populations
      "teal-900": "#00686F",
      "teal-700": "#02929B",
      "teal-400": "rgba(2, 146, 155, .2)",

      active: "warm-gray-200",
      "background-back": "warm-gray-200", // placeholder bg on vp. checkboxes
      placeholder: "text-500",
      text: "text-800",
      icon: "white"
    },
    control: {
      border: {
        width: 0,
        radius: borderRadius,
        color: "red"
      }
    },
    // Search results, select items, expanded areas window
    drop: {
      extend: (props: any) => `
        animation-duration: 0s;
        &:focus {
          outline: none !important;
        }
      `,
      background: "white",
      border: {
        radius: borderRadius
      },
      elevation: "medium",
      intelligentMargin: "true"
    },
    edgeSize: {
      xsmall: "0.5rem",
      small: "1rem",
      medium: "2rem",
      large: "3rem",
      xlarge: "9rem",
      // Don't fully understand this but it seems like this means as at
      // breakpoint small or larger, use the edgeSize in global.breakpoints
      responsiveBreakpoint: "small"
    },
    elevation: {
      light: {
        none: "none",
        // defined in index.css
        small: "var(--box-shadow-small)",
        medium: "var(--box-shadow-medium)",
        large: "var(--box-shadow-large)"
      }
    },
    focus: {
      border: {
        color: "transparent"
      },
      outline: {
        color: "highlight-700",
        size: "2px"
      },
      shadow: "none"
    },
    font: {
      family: "Lato, sans-serif"
    },
    hover: {
      background: "warm-gray-300",
      color: "text-800"
    },
    input: {
      padding: "0",
      font: {
        // size: bodyFontSizes.small
      }
    },
    selected: {
      background: "warm-gray-200",
      color: "text-800"
    }
  },

  anchor: {
    color: undefined,
    size: undefined,
    fontWeight: undefined,
    border: {
      radius: borderRadius
    },
    hover: {
      textDecoration: "none"
    }
  },

  box: {
    responsiveBreakpoint: "small" // when we switch rows to columns
  },

  button: {
    border: undefined,
    extend: `
      font-weight: 400;
      display: inline-flex;
      text-align: left;
      justify-content: space-between;
    `,
    size: {
      small: {
        border: {
          radius: borderRadius
        },
        pad: {
          vertical: ".5rem",
          horizontal: ".9rem"
        }
      },
      medium: {
        border: {
          radius: borderRadius
        },
        pad: {
          vertical: ".75rem",
          horizontal: "1rem"
        }
      },
      large: {
        border: {
          radius: borderRadius
        },
        pad: {
          vertical: "1rem",
          horizontal: "1.25rem"
        }
      }
    },
    default: {
      pad: {
        vertical: undefined,
        horizontal: undefined
      }
    },
    primary: {
      background: "highlight-200",
      color: "highlight-800",
      font: {
        size: undefined,
        weight: 400
      },
      extend: ({ sizeProp }: any) => {
        return {
          fontSize: `${sizeProp === "small" ? "1.5rem" : "large"} !important`
        };
      }
    },
    secondary: {
      background: "warm-gray-200",
      color: "text-800",
      font: {
        size: undefined,
        weight: 400
      },
      pad: {
        vertical: ".4rem",
        horizontal: ".9rem"
      },
      extend: ({ sizeProp }: any) => {
        return {
          fontSize: `${sizeProp === "small" ? "1.4rem" : "large"} !important`
        };
      }
    },
    // active: {
    //   background: undefined,
    //   //   border: undefined,
    //   // color: undefined,
    //   //   extend: undefined,
    //   //   default: {},
    //   //   primary: {},
    //   //   secondary: {},
    // },
    // disabled: {},
    hover: {
      //   border: undefined,
      //   extend: undefined,
      default: {},
      primary: {
        background: "highlight-400",
        color: "highlight-800"
      },
      secondary: {
        background: "warm-gray-300"
      }
    },

    transition: {
      duration: 0
    }
  },

  text: bodyFontSizes,

  paragraph: {
    extend: `
      margin: 0;
      color: var(--text-800);
    `,
    ...bodyFontSizes
  },

  heading: {
    // Note: Heading colors and weight are set in App.css
    extend: ({ theme, level }: any) => {
      // Custom colors for different headings
      // Approach from: https://github.com/grommet/grommet/issues/3049#issuecomment-765517093
      const colors: any = {
        "1": "black",
        "2": "black",
        "3": theme.global.colors["warm-gray-900"],
        "4": "black"
      };
      return {
        color: colors[level],
        margin: 0
      };
    },
    level: {
      "1": {}, // Only to be used by logo
      "2": {
        font: {
          weight: headingWeight
        },
        xxlarge: headingFontSizes.xxlarge,
        xlarge: headingFontSizes.xlarge,
        large: headingFontSizes.xxlarge,
        medium: headingFontSizes.xxlarge,
        small: headingFontSizes.xxlarge
      },
      "3": {
        font: {
          weight: headingWeight
        },
        xxlarge: headingFontSizes.xxlarge,
        xlarge: headingFontSizes.xlarge,
        large: headingFontSizes.xlarge,
        medium: headingFontSizes.xlarge,
        small: headingFontSizes.xlarge
      },
      "4": {
        font: {
          weight: 700
        },
        xxlarge: headingFontSizes.small,
        xlarge: headingFontSizes.small,
        large: headingFontSizes.small,
        medium: headingFontSizes.small,
        small: headingFontSizes.small
      }
    },
    responsiveBreakpoint: undefined // Disable responsive down-scaling
  },

  select: {
    control: {
      // mapbox-gl.css wants to overwrite these. Forcing with !important
      extend: `
        border: 1px solid var(--warm-gray-400) !important;
        padding: .4rem .75rem !important;
        height: auto !important;
        box-shadow: none !important;
        > div {
          width: 100%;
        }
      `,
      open: {
        background: "var(--warm-gray-200) !important"
      }
    },
    options: {
      container: {
        pad: {
          horizontal: "small",
          vertical: "xsmall"
        }
      },
      text: {
        size: "small",
        pad: "small"
      }
    },
    icons: {
      margin: undefined
    }
  },

  table: {
    header: {
      extend: "border-color: var(--warm-gray-300);"
    }
  },

  checkBox: {
    size: "1.75rem",
    gap: "small",
    // unchecked style
    border: {
      color: "warm-gray-400",
      width: "1px"
    },
    check: {
      radius: borderRadius,
      extend: ({ theme, checked }: any) => `
        ${checked && `background-color: var(--teal-700); border:none;`}
        `
    },
    hover: {
      background: undefined,
      border: undefined
    },
    icon: {
      extend: "stroke: white; stroke-width: 2px;"
    },
    toggle: {
      background: "warm-gray-200",
      color: "text-700",
      extend: ({ checked }: any) => `
        ${
          checked &&
          `
          background-color: #109a63 !important; 
          border-color: #109a63;
        `
        }
      `,
      knob: {
        extend: ({ checked }: any) => `
          width: calc(1.75rem - 4px);
          height: calc(1.75rem - 4px);
          margin: 2px;
          ${checked && "margin-left: 1px;"}
          background-color: ${checked && "white"} !important;
        `
      },
      size: "3.25rem"
      // + additional toggle styles in App.css
    }
  }
});

export default CustomGrommetTheme;
