import React from "react";
import store from "../store";
import { Button, ResponsiveContext } from "grommet";
import { toggleGlossary } from "../actions/glossaryControl";
import styled from "styled-components";
import QuestionIcon from "../icons/QuestionIcon";

// When this is set as a style={} prop directly on the component
// it overwrites the (desirable) hover state. This way it doesn't.
const GlossaryButton = styled(Button)<{ unstyled?: boolean }>`
  background: none;
  margin-right: -1rem;
  margin-left: -1rem;
  font-size: ${props => (props.unstyled ? "inherit !important" : "1.5rem !important")};
  font-weight: ${props => (props.unstyled ? "inherit" : 700)};
  color: ${props => (props.unstyled ? "inherit" : "var(--text-800)")};

  &:hover {
    color: var(--text-900) !important;
  }
`;

interface Props {
  readonly unstyled?: boolean;
}

const OpenGlossaryButton = ({ unstyled }: Props) => {
  const openGlossary = () => {
    store.dispatch(toggleGlossary(true));
  };
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <GlossaryButton
          unstyled={unstyled}
          onClick={openGlossary}
          icon={
            !unstyled && size !== "small" ? (
              <QuestionIcon color="var(--warm-gray-500)" size="small" aria-hidden />
            ) : undefined
          }
          label="Glossary"
          a11yTitle="Open glossary"
          secondary
          size="small"
          gap="xsmall"
        />
      )}
    </ResponsiveContext.Consumer>
  );
};

export default OpenGlossaryButton;
