import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const ArrowDownIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon viewBox="0 0 10 11" a11yTitle="Arrow down" fill="none" stroke="none" {...props}>
      <path
        d="M5.07812 10.5859L1.14062 6.46094C0.929681 6.22656 0.929681 5.875 1.16406 5.66406C1.39843 5.45312 1.74999 5.45312 1.96093 5.6875L4.93749 8.80469V0.8125C4.93749 0.507812 5.17187 0.25 5.49999 0.25C5.80468 0.25 6.06249 0.507812 6.06249 0.8125V8.80469L9.01562 5.6875C9.22656 5.45312 9.60156 5.45312 9.81249 5.66406C10.0469 5.875 10.0469 6.22656 9.83593 6.46094L5.89843 10.5859C5.78124 10.7031 5.64062 10.75 5.49999 10.75C5.33593 10.75 5.19531 10.7031 5.07812 10.5859Z"
        fill="var(--text-700)"
        stroke="none"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default ArrowDownIcon;
